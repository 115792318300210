.oopImageSection {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 30%;

  img {
    object-fit: cover;
    width: 100%;
    // height: auto;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
