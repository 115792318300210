.__whole__createTrade__wrapper {
  margin-bottom: 16px;
  @media screen and (min-width: 900px) {
    margin-top: -40px;
  }
  @media screen and (max-width: 700px) {
    margin-bottom: 4px;
  }

  .__mobile__chat__c {
    width: 100%;
    // display: flex;
    // justify-content: flex-start;
    // align-items: center;
    margin-bottom: 20px;
    .__theSwitch {
      // width: 150px;
      display: flex;
      align-items: center;
      justify-content: space-around;
      // background-color: #f0f2f5;
      border-radius: 10px;
      padding: 3px 0;
      overflow: hidden;

      @media screen and (max-width: 700px) {
        border-radius: none;
        border: none;
        justify-content: space-around;
        overflow: auto;
        background-color: none;
      }

      .__marketBuy {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d0d5dd;
        height: 35px;
        font-size: 14px;
        border-radius: 10px;
        color: #000;
        cursor: pointer;

        &.trade {
          color: white;
          background-color: #34d07f;
          border: none;
        }
        @media screen and (max-width: 700px) {
          border-radius: 10px;
        }
      }

      .__marketSell {
        width: 45%;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #d0d5dd;
        height: 35px;
        font-size: 14px;
        border-radius: 10px;
        color: #000;
        cursor: pointer;

        &.chat {
          color: #fff;
          background-color: #34d07f;
          border: none;
        }

        @media screen and (max-width: 700px) {
          border-radius: 10px;
          width: 70;
        }
      }
    }
  }
  .__createTrade__topWrapper {
    padding: 20px;
    background-color: #fafafa;
    border-radius: 5px;
    height: 132px;
    @media screen and (max-width: 730px) {
      background-color: transparent;
      box-shadow: none;
      border-bottom: 1px solid #dbdbdb4a;
      padding: 10px;
    }

    display: flex;
    justify-content: space-between;

    box-shadow: 0px 4px 4px #dbdbdb4a;
    @media screen and (min-width: 730px) {
      align-items: flex-start;
    }

    .__button__order__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .__order__details__wrapper {
        .__order__type {
          font-size: 22px;
          font-weight: bolder;
          margin-bottom: 10px;
        }
        .__order__details {
          font-size: 14px;
          line-height: 22px;

          @media screen and (min-width: 730px) {
            margin-bottom: 5px;
          }
          .__txn__coin {
            font-weight: bold;
          }
        }
        .__order__time__wrapper {
          display: flex;
          align-items: flex-start;
          .__order__time__text {
            font-size: 14px;
            padding: 10px 10px 0 0;
            @media screen and (max-width: 730px) {
              display: none;
            }
          }
          .__ck_wrap {
            display: flex;
            @media screen and (max-width: 730px) {
              display: flex;
              flex-direction: column;
              align-items: flex-start;
            }
            .__waiting {
              display: none;
              @media screen and (max-width: 720px) {
                display: block;
                font-size: 12px;
              }
            }
            .__clock__wrapper {
              display: flex;
              @media screen and (max-width: 730px) {
                margin-top: 8px;
              }

              .__clock__wrapper__inner {
                display: flex;
                flex-direction: column;
                align-items: center;

                span.__time {
                  font-size: 13px;
                  margin-top: 10px;
                }
              }
              .__clock__colon {
                font-size: 18px;
              }
              .__clock__time {
                width: 35px;
                height: 35px;
                border-radius: 50%;
                margin: 0 6px;
                font-size: 15px;
                display: flex;
                justify-content: center;
                align-items: center;
                background-color: #53389e;
                color: white;

                &.__expire {
                  background-color: #667085;
                }
              }
            }
          }
        }
      }
      .__time {
        .__order__time__text {
          font-weight: 500;
          color: #667085;
          font-size: 15px;
          margin-right: 5px;
        }
        .__order__time {
          font-size: 15px;
          font-weight: 500;
        }
      }
    }

    .__order__id__Wrapper {
      margin-top: 13px;
      display: flex;
      //flex-direction: column;
      align-items: center;

      @media screen and (max-width: 730px) {
        display: none;
      }
      @media screen and (min-width: 730px) {
        margin-top: -8px;
      }

      .__id {
        padding: 10px 5px;
        @media screen and (min-width: 730px) {
          padding: 0;
        }

        .__order__id__text {
          font-weight: 500;
          color: #667085;
          font-size: 12px;
          margin-right: 3px;
        }
        .__order__id {
          font-size: 13px;
          font-weight: 500;
        }
      }
      .__time {
        .__order__time__text {
          font-weight: 500;
          color: #667085;
          font-size: 12px;
          margin-right: 3px;
        }
        .__order__time {
          font-size: 13px;
          font-weight: 500;
        }
      }
    }
  }

  .__chat__detail__area {
    display: flex;
    justify-content: space-between;
    padding: 30px 0px;
    @media screen and (max-width: 730px) {
      padding: 10px;
    }
  }
}
