.overView__extensionFirstLayout {
  width: 100%;

  .overView__extensionFirstLayoutAsHeader {
    display: flex;
    justify-content: space-between;

    .overView__extensionFirstLayoutAsHeaderOne {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 20px;
      color: #1a1f36;
    }

    .overView__extensionFirstLayoutAsHeaderTwo {
      background: #f5f5f5;
      border: 1px solid #d6dae1;
      border-radius: 6px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1.3rem;
      line-height: 20px;
      color: #667085;
      outline: none;
      padding: 0px 12px;
      height: 35px;
      cursor: pointer;
    }
  }

  .overView__extensionFirstLayoutListWrapper {
    margin: 15px 0px;

    .overView__extensionFirstLayoutListEmpty {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 20px 0px;
      flex-direction: column;

      img {
        width: 30%;
      }

      p {
        padding: 5px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 13px;
        color: #999999;
      }
    }

    .overView__extensionFirstLayoutList {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 20px 0px;

      .overView__extensionFirstLayoutListSectionOne {
        display: flex;
        align-items: center;

        .overView__extensionFirstLayoutListSectionOneIconBody {
          margin-right: 10px;
        }

        .overView__extensionFirstLayoutListSectionOneDetails {
          h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            line-height: 20px;
            color: #101828;
            text-transform: capitalize;
          }

          .overView__extensionFirstLayoutListSectionOneDetailsDateTime {
            display: flex;
            align-items: center;

            .overView__extensionFirstLayoutListSectionOneDetailsDate {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 20px;
              color: #667085;
              margin-right: 5px;
            }

            .overView__extensionFirstLayoutListSectionOneDetailsTime {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 1.2rem;
              line-height: 20px;
              color: #667085;
            }
          }
        }
      }

      .overView__extensionFirstLayoutListSectionTwo {
        .overView__extensionFirstLayoutListSectionTwoDetials {
          display: flex;
          align-items: center;

          .overView__extensionFirstLayoutListSectionTwoDetailsAmount {
            margin-right: 10px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 20px;
            color: #667085;
          }

          .overView__extensionFirstLayoutListSectionTwoDetialsToken {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 1.2rem;
            line-height: 20px;
            color: #667085;
          }
        }

        .overView__extensionFirstLayoutListSectionTwoStories {
          display: flex;
          align-items: center;
          justify-content: flex-end;

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 20px;
            color: #0ecb81;
          }
        }
      }
    }
  }
}

//   @media screen and (max-width: 970px) {
//     .overView__extensionFirstLayout {
//       width: 48%;
//     }
//   }

//   @media screen and (max-width: 610px) {
//     .overView__extensionFirstLayout {
//       width: 100%;
//     }
//   }
