.convertBank {
  // display: flex;
  // flex-direction: column;
  // gap: 28px;
  // width: 65%;
  // padding: 10px 0px;

  margin-top: 40px;
  min-height: 478px;
  // width: 478px;
  // width: 90%;
  border-radius: 16px;
  border: 1px solid #efefef;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.06);

  .ConvertComponentCardHeader {
    border-radius: 16px 0px 0px 0px;
    background: #f7f7f7;
    padding: 20px 0;

    .ConvertComponentCardHeaderTitleStyle {
      color: #101828;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: 32.64px; /* 141.913% */
      margin-left: 50px;
      // margin-left: 20px;
    }
  }
  .convertBank_title {
    width: 100%;
    .convertBank_title_state {
      color: #344054;
      font-size: 18px;
      line-height: 32px;
      font-family: "inter";
      font-weight: 600;
    }
  }

  .convertBank_content {
    margin-top: 10px;
    margin-bottom: 15px;
    width: 100%;
    text-align: center;
    padding: 0px 50px 0px 50px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    //   padding-left: 30px;
    // padding-right: 30px;

    .convertBank_content_explain {
      width: 100%;
      // margin: 0px 0;
      background-color: #f9f5ff;
      border-radius: 8px;
      padding: 16px;
      text-align: left;
      color: #7f56d9;
      font-size: 14px;
      font-family: "inter";
      font-weight: 500;
      line-height: 20px;
    }

    .convertBank_content_form {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 31px;

      .convertBank_content_form_title {
        display: flex;
        align-items: center;
        gap: 9px;
        margin-top: 25px;

        .convertBank_content_form_title_icon {
          font-size: 18px;
          color: #7f56d9;
        }

        .convertBank_content_form_title_item {
          color: #667085;
          font-size: 16px;
          font-family: Inter;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .convertBank_content_form_bankname {
        margin-top: 10px;
        text-align: left;
        position: relative;

        .convertBank_content_form_bankname_name {
          color: #667085;
          font-size: 14px;
          font-family: Inter;
          font-weight: 400;
          line-height: 20px;
        }

        .convertBank_content_form_bankname_bank {
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          padding: 10px 14px;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .convertBank_content_form_bankname_bank_title {
            color: #394247;
            font-size: 16px;
            font-family: Inter;
            font-weight: 400;
            line-height: 24px;
          }

          .convertBank_content_form_bankname_bank_iconbody {
            display: flex;
            align-items: center;
            justify-content: center;

            .convertBank_content_form_bankname_bank_icon {
              font-size: 16px;
              color: #5e5862;
            }
          }
        }

        .convert_form_details_body_wrapper_container_icon_dropdown {
          margin-top: 10px;
          width: 60%;
          position: absolute;
          background-color: #fff;
          box-shadow: 0px 2px 8px 4px #00000008;
          display: flex;
          flex-direction: column;
          padding: 15px 10px;
          z-index: 10;
          left: 40%;

          border-radius: 16px;
          border: 1px solid #efefef;
          // background: rgba(255, 255, 255, 0);
          box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.06);

          .convert_form_details_body_wrapper_container_icon_dropdown_contatiner {
            padding: 0 10px;
            margin-bottom: 5px;
            display: flex;
            align-items: center;
            gap: 5px;
            background-color: #ebebeb;

            border-radius: 8px;

            .convertSearchIconStyle {
              width: 18px;
              height: 18px;
            }

            .convert_form_details_body_wrapper_container_icon_dropdown_title {
              color: #667085;
              font-size: 14px;
              font-family: "Inter";
              font-weight: 400;
              line-height: 20px;
              letter-spacing: 0.16px;
              width: 100%;
              text-align: left;
            }

            .convert_form_details_body_wrapper_container_icon_dropdown_title_input {
              width: 100%;
              border: 1px solid #ebecf0;
              outline: none;
              font-size: 16px;
              color: #344054;
              font-weight: 400;
              font-family: "inter";
              padding: 13px 0px;
              border-radius: 8px;
              background-color: transparent;

              &::placeholder {
                color: #667085;
              }
            }
          }

          .convert_form_details_body_wrapper_container_icon_dropdown_content_container {
            display: flex;
            flex-direction: column;
            gap: 10px;
            width: 100%;
            height: 250px;
            overflow-y: scroll;
            // border: 1px solid #ebecf077;
            border-radius: 8px;
            padding: 4px 10px;

            &::-webkit-scrollbar-track {
              /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
              border-radius: 0px;
              /* background-color: #f5f5f5; */
            }

            &::-webkit-scrollbar {
              width: 0px;
              /* background-color: #f5f5f5; */
            }

            &::-webkit-scrollbar-thumb {
              border-radius: 0px;
              /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
              background-color: #98a2b3;
            }

            .convert_form_details_body_wrapper_container_icon_dropdown_content {
              display: flex;
              align-items: center;
              gap: 25px;
              margin-top: 15px;
              cursor: pointer;

              .convert_form_details_body_wrapper_container_icon_dropdown_content_space {
                display: flex;
                align-items: center;
                justify-content: space-between;
                gap: 7px;

                .convert_form_details_body_wrapper_container_icon_dropdown_content_space_title {
                  color: #344054;
                  font-size: 16px;
                  font-family: "Inter";
                  font-weight: 500;
                  line-height: 22.4px;
                }
              }

              .convert_form_details_body_wrapper_container_icon_dropdown_content_token {
                color: #667085;
                font-size: 14px;
                font-family: "Inter";
                font-weight: 400;
                line-height: 16.8px;
                letter-spacing: 0.2px;
                text-transform: capitalize;
              }
            }
          }
        }
      }

      .convertBank_content_form_accnumber {
        // display: flex;
        // flex-direction: column;
        // gap: 31px;

        .convertBank_content_form_accnumber_title {
          color: #667085;
          font-size: 14px;
          font-family: "Inter";
          font-weight: 400;
          line-height: 20px;
          letter-spacing: 0.16px;
          width: 100%;
          text-align: left;
        }

        .convertBank_content_form_accnumber_content_form {
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: 12px;

          .convertBank_content_form_accnumber_content {
            width: 100%;
            input {
              border-radius: 8px;
              border: 1px solid #d0d5dd;
              padding: 10px 14px;
              color: #394247;
              font-size: 16px;
              font-family: "Inter";
              font-weight: 400;
              line-height: 24px;
              width: 100%;
              outline: none;
            }
          }
        }

        .convertBank_content_form_accnumber_loader {
          text-align: left;
        }

        .convertBank_content_form_accnumber_texted {
          text-align: left;
          color: #7f56d9;
          font-size: 16px;
          font-family: "inter";
          font-weight: 600;
          line-height: 20px;
          text-transform: capitalize;
        }

        .convertBank_content_form_accnumber_texted_error {
          margin-top: 10px;
          text-align: left;
          color: #cf304a;
          font-family: "Inter";
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }
      }

      .convertBank_content_form_button {
        display: flex;
        align-items: center;
        justify-content: space-between;

        margin-top: 20px;
        margin-bottom: 40px;
        .convertBank_content_form_button_one {
          font-size: 16px;
          font-family: "inter";
          font-weight: 600;
          line-height: 24px;
          width: 47%;
          border-radius: 8px;
          padding: 10px 0px;
          border: 1px solid #d0d5dd;
          outline: none;

          &.button_two {
            color: #fff;
            background-color: #10d078;

            &:disabled {
              color: #bcbdc6;
              background-color: #eaecef;
              cursor: not-allowed;
            }
          }

          &.button_one {
            color: #344054;
            background-color: #fff;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1350px) {
    width: 80%;
  }

  @media screen and (max-width: 870px) {
    width: 100%;
  }
}
