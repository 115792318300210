.__filter__Modal__Mobile {
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: auto;
  width: 95vw;
  margin: 0 auto;
  margin-top: 60px;
  //background-color: white;
  .__filterTitle {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    // margin-bottom: 30px;
    padding: 20px 0 30px 0;
    border-bottom: 1px solid #eaecf0;
  }
  .___ggd {
    padding: 5px 5px;
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-track {
      border-radius: 5px;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 10px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: #98a2b3;
    }

    border-radius: 8px;
    .__the__options {
      margin: 10px 0 35px 0;
      &:last-of-type {
        margin: 30px 0 35px 0;
      }
      p.__filter__optionLabel {
        font-size: 14px;
        font-weight: 500;
        margin-top: 10px;
        margin-bottom: 7px;
      }
      .___datee {
        display: flex;
        align-items: center;
        font-size: 1.4rem;
        background-color: white;
        position: relative;
        border: 1px solid #eff0f6;
        border-radius: 20px;
        margin-bottom: 15px;
        margin-top: 10px;
        margin-right: 0;
      }

      .__the__amountType {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px 10px;
        margin-top: 10px;
        width: 100%;

        &.__gg {
          font-size: 17px;
          border: 1.11736px solid #d0d5dd;
          box-shadow: 0px 1.5px 2px rgba(16, 24, 40, 0.05);
          border-radius: 5px;
        }
        .__amount__input {
          outline: none;
          border: none;
          padding: 0px 3px;
          font-size: 15px;
          width: 70%;

          &::placeholder {
            font-size: 15px;
          }
        }

        .__currency {
        }
      }
    }
  }
  .__button__wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
    width: 100%;
    // margin-top: 20vh;
    .__buy__buttonn {
      outline: none;
      border: none;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42%;
      font-size: 16px;
      padding: 13px 0;
      background-color: #7f56d9;
      border-radius: 9px;
      color: white;
      cursor: pointer;

      &:disabled {
        cursor: not-allowed;
        background-color: #7f56d996;
      }
    }
    .__cancel__button {
      outline: none;
      border: none;
      display: flex;
      background-color: white;
      width: 42%;
      font-size: 16px;
      padding: 13px 0;
      border-radius: 9px;
      justify-content: center;
      align-items: center;
      border: 1px solid #d0d5dd;
    }
  }
}
