.deleteCardWrapper {
  width: 400px;
  text-align: center;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px 25px;

  .delete__firstLayout {
    margin: 20px;
    display: flex;
    align-items: center;
    justify-content: center;

    .delete__firstLayoutIconBodyContainer {
      background-color: #fef3f2;
      border-radius: 50%;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 8px;

      .delete__firstLayoutIconBody {
        background-color: #fee4e2;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        padding: 8px;

        .delete__firstLayoutIcon {
          color: rgba(217, 45, 32, 1);
          font-size: 40px;
        }
      }
    }
  }

  .delete__secondLayout {
    margin: 5px 0px;
    padding: 10px 5px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1.6rem;
      line-height: 17px;
      color: #101828;
      padding: 5px 0px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 12px;
      color: #667085;
      margin-bottom: 10px;
    }
  }

  .delete__thirdLayout {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 0px;

    .delete__thirdLayoutCancel {
      border: 0.609722px solid #d0d5dd;
      background: #ffffff;
      box-shadow: 0px 0.609722px 1.21944px rgba(16, 24, 40, 0.05);
      border-radius: 4.87778px;
      // padding: 6.09722px 10.975px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #344054;
      width: 48%;
      height: 40px;
      cursor: pointer;
    }

    .delete__thirdLayoutDelete {
      background: #d92d20;
      border: 0.609722px solid #d92d20;
      box-shadow: 0px 0.609722px 1.21944px rgba(16, 24, 40, 0.05);
      border-radius: 4.87778px;
      // padding: 6.09722px 10.975px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 15px;
      color: #ffffff;
      width: 48%;
      height: 40px;
      cursor: pointer;
    }
  }
}
