.form-contents {
  width: 450px;
  height: 350px;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  background-color: #fff;
  padding: 25px 25px;
  border-radius: 8px;

  &.__padding {
    padding: 20px;
  }

  .__warninig {
    width: 70px;
  }
  .form-contentsHeader {
    width: 100%;
    // border: 1px solid red;
    display: flex;
    align-items: flex-start;
    flex-direction: column;

    &.__center {
      align-items: center;
    }
    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 14px;
      color: #101828;
      padding: 10px 0px;
      margin-bottom: 10px;
    }

    .form-contentsHeader__Content {
      padding: 12px 0px;
      text-align: left;

      .tag {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 14px;
        color: #101828;
        padding: 10px 0px;
      }

      .tagTell {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 9px;
        padding: 5px 0px;
      }
    }
  }

  .form-input {
    width: 100%;
    margin-top: 30px;

    .label {
      font-size: 14px;
      font-weight: 500;
      color: #344054;
    }
  }
  .__theerror {
    font-size: 12px;
    color: red;
    text-align: center;
    margin-top: 7px;
  }

  .form-contentsResendCode {
    width: 100%;
    display: flex;
    align-items: center;
    // justify-content: center;
    padding: 15px 0px;

    margin-top: 10px;
    &.__textcenter {
      justify-content: center;
    }

    .form-contentsResendCodeContent {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 6px;
      color: #667085;
      margin-right: 5px;
    }

    .form-contentsResendCodeLink {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 6px;
      color: #667085;
      text-decoration: underline;
      cursor: pointer;
    }
  }

  .form-contentsButtonBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .signUp {
      width: 48%;
      background: #5d3cb2;
      color: #fff;
      font-size: 1.4rem;
      border-radius: 7.31px;
      box-shadow: none;
      text-transform: capitalize;
      height: 40px;
      cursor: pointer;
      border: none;
      outline: none;

      &:hover {
        background-color: #5d3cb2;
        box-shadow: none;
      }

      &:disabled {
        border: 1px solid rgba(93, 60, 178, 0.4);
        background-color: rgba(93, 60, 178, 0.4);
        color: white;
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
  }
}

@media (max-width: 1000px) {
  .form-contents {
    .title {
      font-size: 20px;
    }

    .tag {
      font-size: 18px;
      font-weight: 400;
      color: "#667085";
    }
  }
}

@media (max-width: 600px) {
  .form-contents {
    .title {
      font-size: 18px;
    }
  }
}

@media (max-width: 400px) {
  .form-contents {
    width: 94%;
  }
}
