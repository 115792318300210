.history__Container {
  width: 600px;
  padding: 15px 20px;
  background-color: white;
  border-radius: 8px;

  .__modal__content__top {
    display: flex;
    justify-content: space-between;
    .__title__text {
      font-size: 16px;
      font-weight: 500;
    }

    .__modal__content__topIconBody {
      display: flex;
      align-items: center;
      justify-content: center;

      .__modal__content__topIcon {
        cursor: pointer;
        width: 30px;
        font-size: 200px;
      }
    }
  }

  .history__bottomContent {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 25px 0;
    .__bottonContent__key {
      font-size: 15px;
      color: #667085;
    }
    .__bottonContent__value {
      font-size: 15px;
      font-weight: 400;

      &.__status__complete {
        color: #027a48;
        background-color: #ecfdf3;
        padding: 3px 8px;
        border-radius: 4px;
      }
      &.__status__pending {
        color: #344054;
        background-color: #f2f4f7;
        padding: 3px 8px;
        border-radius: 4px;
      }
      &.__status__rejected {
        color: #b42318;
        background-color: #fef3f2;
        padding: 3px 8px;
        border-radius: 4px;
      }
    }
  }
}

@media screen and (max-width: 690px) {
  .history__Container {
    width: 90%;
  }
}
