.postTrade__FirstLayout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d8d8d880;
  padding: 20px 30px;

  .postTrade__firstLayoutHeader {
    padding: 15px 15px 15px 0px;

    h4 {
      font-weight: 600;
      color: #292323;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 16px;
    }
  }

  .postTrade__firstLayoutChecker {
    margin: 0px 104px 0px 40px;
    width: 30%;

    .postTrade__firstLayoutCheckerBuy {
      display: flex;

      .postTrade__firstLayoutCheckerBuyChecker {
        margin-right: 10px;
      }

      .postTrade__firstLayoutCheckerBuyBody {
        h5 {
          font-weight: 500;
          color: #344054;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 17px;
        }

        p {
          color: #667085;
          padding: 5px 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 17px;
        }
      }
    }

    .postTrade__firstLayoutCheckerSell {
      display: flex;
      padding: 10px 0px 0px 0px;
      margin: 5px 0px 0px 0px;
      width: 100%;

      .postTrade__firstLayoutCheckerSellChecker {
        margin-right: 10px;
      }

      .postTrade__firstLayoutCheckerSellBody {
        h5 {
          font-weight: 500;
          color: #344054;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 17px;
        }

        p {
          color: #667085;
          padding: 5px 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 17px;
        }
      }
    }
  }

  .postTrade__firstLayoutSelectCrypto {
    width: 30%;

    .postTrade__firstLayoutSelectCryptoBody {
      label {
        color: #344054;
        padding: 5px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 17px;
        margin-bottom: 5px;
      }

      .postTrade__firstLayoutSelectCryptoBodyContent {
        margin-top: 5px;
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .postTrade__FirstLayout {
    padding: 20px 10px;
    justify-content: space-between;

    .postTrade__firstLayoutHeader {
      width: 100%;
    }

    .postTrade__firstLayoutChecker {
      width: 45%;
      margin: 0px 0px;
    }

    .postTrade__firstLayoutSelectCrypto {
      width: 45%;
    }
  }
}

@media screen and (max-width: 720px) {
  .postTrade__FirstLayout {
    .postTrade__firstLayoutSelectCrypto {
      .postTrade__firstLayoutSelectCryptoBody {
        label {
          line-height: 5px;
          padding: 0px;
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .postTrade__FirstLayout {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 5px;

    .postTrade__firstLayoutChecker {
      width: 100%;
      margin: 0px 0px;
    }

    .postTrade__firstLayoutSelectCrypto {
      margin-top: 10px;
      width: 100%;
    }
  }
}
