.postTrade__fourthLayout {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #d8d8d880;

  .postTrade__fourthLayoutContainer {
    width: 44%;

    .postTrade__fourthLayoutOne {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0px;

      .postTrade__fourthLayoutOneTitle {
        p {
          /* font-size: 15px;
            font-weight: 300; */
          color: #292323;
          font-style: normal;
          font-weight: 600;
          font-size: 1.2rem;
          line-height: 16px;
        }
      }

      .postTrade__fourthLayoutOneInput {
        width: 65%;
        label {
          /* font-size: 13px;
            font-weight: 500; */
          color: #344054;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 15px;
        }

        .postTrade__fourthLayoutOneInputContainer {
          display: flex;
          align-items: center;
          width: 100%;
          border: 1px solid #d0d5dd;
          border-radius: 8px;
          margin-top: 5px;

          input {
            padding: 10px 8px;
            border-radius: 8px;
            outline: none;
            border: none;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 21px;
            width: 80%;

            &::placeholder {
              color: #34405449;
            }
          }

          .postTrade__fourthLayoutOneInputContainerLabel {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;

            p {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 1.4rem;
              line-height: 21px;
              color: #667085;
            }

            .postTrade__fourthLayoutOneInputContainerLabelContent {
              padding: 5px;
              p {
                font-size: 1.4rem;
                font-weight: 500;
              }
            }

            .postTrade__fourthLayoutOneInputContainerLabelIconBody {
              display: flex;
              align-items: center;
              justify-content: center;
            }
          }
        }

        .postTrade__fourthLayoutOneInputContainertwo {
          display: flex;
          align-items: center;
          width: 100%;
          border: 1px solid #d0d5dd;
          border-radius: 8px;
          margin-top: 5px;

          input {
            padding: 6px 8px;
            border-radius: 8px;
            outline: none;
            border: none;
            padding: 10px 8px;
            border-radius: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 21px;
            width: 80%;
            color: #344054;

            &::placeholder {
              color: #34405449;
            }
          }

          .postTrade__fourthLayoutOneInputContainerLabeltwo {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 20%;
            color: #344054;

            .postTrade__fourthLayoutOneInputContainerLabelContenttwo {
              padding: 5px;
              p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 1.4rem;
                line-height: 21px;
                color: #667085;
              }
            }

            .postTrade__fourthLayoutOneInputContainerLabelIconBodytwo {
              display: flex;
              align-items: center;
              justify-content: center;

              .postTrade__fourthLayoutOneInputContainerLabelIcontwo {
                font-size: 15px;
                color: #344054;
              }

              .colorIcon {
                color: #fda29b;
              }
            }
          }
        }

        .color {
          border: 1px solid #fda29b;
        }

        .postTrade__fourthLayoutOneInputContainerthreeCharacter {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.2rem;
          line-height: 20px;
          color: #667085;
          display: flex;
          align-items: center;
          justify-content: flex-end;
        }

        .error {
          color: #f04438;
        }

        .postTrade__fourthLayoutOneInputContainerthree {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 100%;
          border: 1px solid #d0d5dd;
          border-radius: 8px;
          margin-top: 5px;

          input {
            width: 100%;
            outline: none;
            border: none;
            padding: 10px 8px;
            border-radius: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 21px;
            color: #344054;
          }
        }

        .postTrade__fourthLayoutOneTotal {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .postTrade__fourthLayoutOneTotalMax {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            color: rgba(52, 208, 127, 1);
            padding: 0px 8px;
            cursor: pointer;
          }

          .postTrade__fourthLayoutOneTotalMaxSellPrice {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 13px;
            line-height: 24px;
            color: #667085;
            padding: 0px 8px;
            cursor: pointer;
          }

          span {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.2rem;
            line-height: 21px;
            color: #667085;
            padding: 0px 4px;
          }

          .error {
            color: #f04438;
          }
        }
      }
    }
  }
}

.postTrade__fivethLayout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 15px 30px;
  border-bottom: 1px solid #d8d8d880;

  .postTrade__fivethLayoutOne {
    width: 40%;
    .postTrade__fivethLayoutOneInput {
      display: flex;
      flex-direction: column;

      label {
        color: #344054;
        padding: 5px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.2rem;
        line-height: 20px;
      }

      textarea {
        resize: none;
        width: 100%;
        height: 120px;
        border-radius: 8px;
        padding: 10px;
        color: #667085;
        border: 1px solid #d0d5dd;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.6rem;
        line-height: 24px;
        outline: none;
      }

      p {
        color: #667085;
        padding: 5px 0px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 10px;
      }

      .error {
        color: #f04438;
      }
    }
  }

  .postTrade__fivethLayoutTwo {
    width: 46%;
    padding: 10px 0px;

    .postTrade__fivethLayoutTwoBody {
      .postTrade__fivethLayoutTwoBodyOne {
        padding: 10px 0px;
        .postTrade__fivethLayoutTwoBodyOneCancel {
          border: 1px solid #d0d5dd;
          padding: 0px 12px;
          border-radius: 7.31px;
          /* margin: 0px 10px; */
          outline: none;
          color: #344054;
          background-color: #fff;
          font-family: "Inter";
          font-style: normal;
          font-size: 1.4rem;
          line-height: 17px;
          height: 40px;
          text-transform: capitalize;
        }

        .postTrade__fivethLayoutTwoBodyOneConfirm {
          border: none;
          padding: 0px 12px;
          border-radius: 7.31px;
          margin: 0px 10px;
          outline: none;
          color: #fff;
          background-color: #53389e;
          font-family: "Inter";
          font-style: normal;
          font-size: 1.4rem;
          line-height: 17px;
          height: 40px;
          text-transform: capitalize;

          &:hover {
            background-color: hsla(259, 63%, 59%, 0.8);
            border: 1px solid hsla(259, 63%, 59%, 0.8);
          }

          &:disabled {
            background-color: hsla(259, 63%, 59%, 0.4);
            border: 1px solid hsla(259, 63%, 59%, 0.4);
          }
        }
      }

      .postTrade__fivethLayoutTwoBodyTwo {
        padding: 10px 0px;

        button {
          border: none;
          padding: 10px 15px;
          border-radius: 8px;
          outline: none;
          color: #fff;
          background-color: #7f56d9;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.3rem;
          line-height: 17px;
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .postTrade__fourthLayout {
    .postTrade__fourthLayoutContainer {
      .postTrade__fourthLayoutOne {
        flex-direction: column;
        align-items: flex-start;

        .postTrade__fourthLayoutOneInput {
          width: 100%;

          label {
            display: none;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 720px) {
  .postTrade__fivethLayout {
    .postTrade__fivethLayoutOne {
      width: 48%;
    }
  }
}

@media screen and (max-width: 713px) {
  .postTrade__fivethLayout {
    .postTrade__fivethLayoutTwo {
      .postTrade__fivethLayoutTwoBody {
        .postTrade__fivethLayoutTwoBodyOne {
          .postTrade__fivethLayoutTwoBodyOneConfirm {
            margin: 5px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 691px) {
  .postTrade__fivethLayout {
    .postTrade__fivethLayoutTwo {
      .postTrade__fivethLayoutTwoBody {
        .postTrade__fivethLayoutTwoBodyOne {
          .postTrade__fivethLayoutTwoBodyOneConfirm {
            margin: 2px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .postTrade__fourthLayout {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5px;

    .postTrade__fourthLayoutContainer {
      width: 100%;
    }
  }

  .postTrade__fivethLayout {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5px;

    .postTrade__fivethLayoutOne {
      width: 100%;
    }

    .postTrade__fivethLayoutTwo {
      width: 100%;
    }
  }
}
