.settings-container {
  margin: 0px auto;
  // background-color: #f9fafb;

  .settingsTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 38px;
    color: #101828;
    margin-bottom: 5px;

    @media (max-width: 760px) {
      // display: none;
      font-size: 1.8rem;
      margin-left: 2rem;
    }
  }
  .first-row {
    margin-top: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;

    padding-bottom: 3.5rem;
    border-bottom: 1px solid #eaecf0;

    .left-container {
      display: flex;
      gap: 50px;
      align-items: center;
      flex-wrap: wrap;

      @media (max-width: 760px) {
        display: flex;
        gap: 0;
        margin: auto 2rem;
        .profile-container {
          width: 250px;
        }
        .upload-container {
          width: 250px;
          @media (max-width: 563px) {
            margin: 3rem 0 1rem;
          }
        }
      }
    }

    .right-container_Main {
      width: 300px;

      @media (max-width: 760px) {
        width: 100%;
        margin: 2rem 2rem 0;
      }

      .searchable-container {
        width: 100%;
      }
    }
  }

  .personal-information-container {
    padding-bottom: 3rem;
    border-bottom: 1px solid #eaecf0;

    .top-section {
      margin: 4rem 0;

      .title {
        color: #344054;
        font-size: 1.8rem;
      }

      .tag {
        color: #667085;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // main container above
      // background-color: red;

      @media (max-width: 817px) {
        margin: auto 2rem;
      }

      .left-container {
        flex-wrap: wrap;
        // width: 800px;
        width: 100%;
        justify-content: space-between;

        @media (max-width: 817px) {
          // background-color: peru;
          width: 100%;
        }

        .listStyles {
          display: flex;
          align-items: center;
          gap: 1rem;
          // background-color: red;
        }
        .inner-container {
          width: 100%;
          display: flex;
          margin-bottom: 2rem;
          justify-content: space-between;
          flex-wrap: wrap;

          // @media (max-width: 850px) {
          // }
          @media (max-width: 500px) {
            justify-content: space-between;
            gap: 1rem;
            width: 100%;
          }
        }
        .button-container {
          margin-top: 5rem;
        }
      }
      // end left container

      .right-container_Phone {
        width: 400px;
        display: flex;
        justify-content: flex-end;

        @media (max-width: 817px) {
          width: 300px;
        }

        @media (max-width: 814px) {
          width: 100%;
        }

        .inner-container {
          width: 90.5%;

          @media (max-width: 817px) {
            margin-top: 2rem;
            width: 100%;
          }
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            margin-bottom: 5px;
          }
          .verify-phone-number-container {
            width: 100%;
          }
        }
      }
    }
  }

  .referral-center-container {
    padding-bottom: 3rem;
    border-bottom: 1px solid #eaecf0;

    .top-section {
      margin: 4rem 0;
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;

      @media (max-width: 814px) {
        align-items: flex-start;
        flex-direction: column;
      }

      .referral_stage {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 600px;

        @media (max-width: 817px) {
          width: 300px;
        }

        @media (max-width: 814px) {
          padding: 0rem 2rem;
          width: 100%;
        }

        .referral_stage_Icon_body {
          display: flex;
          align-items: center;

          .referral_stage_Icon_body_wrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 10px;

            .referral_stage_Icon_body_icon {
              font-size: 18px;
              color: #344054;
            }
          }

          .referral_stage_Icon_body_text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #17bf68;
          }
        }

        .referral_stage_indicator {
          background-color: #344054;
          padding: 9px 25px;
          border-radius: 25px;

          p {
            color: #fff;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 20px;
          }
        }
      }

      .title {
        color: #344054;
        font-size: 1.8rem;
      }

      .tag {
        color: #667085;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // main container above

      @media (max-width: 817px) {
        margin: auto 2rem;
      }

      .left-container {
        width: 330px;

        @media (max-width: 817px) {
          width: 100%;
        }

        .inner-container {
          width: 100%;
          display: flex;
          margin-bottom: 2rem;
          justify-content: space-between;

          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 700;
            font-size: 3.7rem;
            line-height: 40px;
            color: #344054;
            margin-bottom: 5px;
          }

          .detail {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }
      }
      // end left container

      .right-container {
        background-color: pink;
        width: 600px;
        display: flex;
        justify-content: flex-end;
        padding: 15px 5px;
        background: #ffffff;
        box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
        border-radius: 8px;

        @media (max-width: 817px) {
          width: 300px;
        }

        @media (max-width: 814px) {
          width: 100%;
        }

        .inner-container {
          width: 100%;
          margin: auto 2rem;

          .inner-container__wrapperTextDetails {
            margin: 10px 0px 5px 0px;
            .inner-container__wrapperText {
              margin: 5px 0px;
              p {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #344054;
              }
            }

            .addMargin {
              margin-bottom: 10px;
            }

            .inner-container__wrapper {
              border: 1px solid #d0d5dd;
              width: 100%;
              height: 44px;
              background: #fff;
              font-size: 14px;
              border-radius: 8px;
              display: flex;
              align-items: center;
              justify-content: space-between;
              padding: 0px 15px;
              box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);

              input {
                width: 70%;
                padding: 6.72311px 10.757px;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                color: #344054;
                border: none;
                outline: none;
              }

              .addCap {
                text-transform: uppercase;
              }

              .inp {
                @media (max-width: 414px) {
                  width: 40%;
                }
              }

              button {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 20px;
                cursor: pointer;
                color: #ffffff;
                background: #53389e;
                border: 0.672311px solid #53389e;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
                border-radius: 5.37849px;
                padding: 6.72311px 10.757px;

                &:hover {
                  background: #7c55e6;
                  border: 0.672311px solid #7c55e6;
                }

                &:disabled {
                  background: #f5f4f6;
                  border: 0.672311px solid #f5f4f6;
                  color: #344054;
                  opacity: 0.5;
                  cursor: not-allowed;
                }
              }

              .inner-container__wrapperIconBody {
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;
              }
            }

            span {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              color: #f04438;
            }
          }

          @media (max-width: 817px) {
            // margin-top: 2rem;
            width: 100%;
          }
          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
            margin-bottom: 5px;
          }
          .verify-phone-number-container {
            border: 1px solid #d0d5dd;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            padding: 10px;
            margin-bottom: 1rem;
          }
        }
      }
    }
  }

  .security-container {
    padding-bottom: 3rem;
    border-bottom: 1px solid #eaecf0;
    .top-section {
      margin: 4rem 0;

      .title {
        color: #344054;
        font-size: 1.8rem;
      }

      .tag {
        color: #667085;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 1.8rem;
      }
    }

    .container {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;

      @media (max-width: 817px) {
        margin: auto 2rem;
      }

      .left-container {
        flex: 1;
        display: flex;
        justify-content: space-between;
        margin-bottom: 2rem;

        @media (max-width: 600px) {
          flex-direction: column;
        }

        .not-verified {
          display: flex;
          align-items: center;
          width: 140px;
          @media (max-width: 600px) {
            margin-top: 20px;
          }

          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }
          .tag {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }

        .first-inner-container {
          display: flex;
          align-items: flex-start;

          .title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }
          .tag {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            color: #667085;
          }
        }
        @media (max-width: 817px) {
          width: 100%;
        }
      }
      // end left container
    }
  }

  .list-block-container {
    padding-bottom: 3rem;
    border-bottom: 1px solid #eaecf0;
    margin-bottom: 5rem;

    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin: 4rem 0;

    .left-container {
      width: 300px;

      @media (max-width: 817px) {
        width: 100%;
      }

      .inner-container {
        width: 100%;
        display: flex;
        margin-bottom: 2rem;
        justify-content: space-between;

        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 3.4rem;
          line-height: 40px;
          color: #344054;
          margin-bottom: 5px;
        }

        .tag {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 38px;
          color: #667085;
        }

        @media (max-width: 817px) {
          margin: auto 2rem;
          // background-color: red;
        }
      }
    }
    // end left container

    .right-container {
      width: 600px;
      display: flex;
      justify-content: flex-end;
      // padding: 20px 5px;
      background: #ffffff;
      box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
      border-radius: 8px;

      align-items: center;
      position: relative;
      padding: 2rem 1rem 1rem;

      @media (max-width: 817px) {
        width: 300px;
        margin: 2rem 2rem auto;
      }

      @media (max-width: 814px) {
        width: 100%;
      }

      .inner-container {
        width: 100%;
        margin: auto 1em;
        display: flex;
        justify-content: space-between;

        .icons-container {
          display: flex;
        }

        .icon-title-container {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          margin-left: 1.7rem;
        }

        @media (max-width: 817px) {
          // margin-top: 2rem;
          width: 100%;
        }
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #344054;
          margin-bottom: 5px;
        }
      }
    }
  }

  .modal-container {
    width: 480px;
    height: 563px;
    // height: auto;
    // height: 100%;
    background: #ffffff;
    // box-shadow: 0px 20px 24px -4px rgba(16, 24, 40, 0.08), 0px 8px 8px -4px rgba(16, 24, 40, 0.03);
    border-radius: 12px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    @media (max-width: 800px) {
      width: 400px;
      margin: auto 5rem;
    }

    .basic-verification-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 2rem 0 0rem;

      .title {
        margin-top: 1rem;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.8rem;
        line-height: 28px;
        text-align: center;
        color: #101828;
        margin-bottom: 0.5rem;
      }
      .tag {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 20px;
        text-align: center;
        color: #039855;
        margin-bottom: 2rem;
        text-align: center;
      }

      .text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 1.2rem;
        line-height: 20px;
        color: #344054;
        text-align: center;
        margin-bottom: 0rem;
        margin: auto 5rem;
      }
    }

    .body {
      padding: 1rem;

      .top-section-modal-body {
        padding: 0;
        margin: 0 1rem;
        .cancel-container {
          display: flex;
          justify-content: space-between;
          margin: 2rem 0rem 1.5rem;
        }
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 1.8rem;
          line-height: 28px;
          color: #101828;
          margin-bottom: 0.6rem;
        }
        .tag {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.281rem;
          line-height: 20px;
          color: #667085;
          @media (max-width: 800px) {
            font-size: 1.15rem;
            white-space: nowrap;
          }
        }
        .tag-detail {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 1.4rem;
          line-height: 20px;
          color: #667085;
          margin-top: 2rem;
        }
      }

      .first-container {
        margin: 0.5rem 1rem 0rem;
        display: flex;
        gap: 2rem;

        .input-style {
          width: 210px;

          @media (max-width: 800px) {
            width: 170px;
          }
        }
      }

      .second-container {
        margin: auto 1rem;
        margin-top: 0.5rem;
        display: flex;
        justify-content: space-between;

        .TT {
          // flex: 1;

          width: 100%;

          .mini-list-block {
            // flex: 1;
            .third-column {
              display: flex;
              justify-content: space-between;
              align-items: center;
              margin-bottom: 3rem;

              .one-third {
                display: flex;
                width: 100%;
                align-items: center;
                gap: 10px;

                .img {
                  width: 50px;
                  height: 50px;
                  border-radius: 50%;
                }

                .title {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  // line-height: 20px;
                  color: #101828;
                }
                .tag {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 0.9rem;
                  // line-height: 10px;
                  color: #00a478;
                }
              }

              .button {
                font-family: "Inter";
                font-style: normal;
                font-weight: 600;
                font-size: 14px;
                line-height: 20px;
                color: #6941c6;
                border: none;
                background-color: transparent;
                width: 100px;

                @media (max-width: 500px) {
                  font-size: 1.2rem;
                }
              }
            }
          }

          .opt-container {
            margin: 0.5rem 0;
          }

          .opt-style-title {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: #344054;
          }
          .opt-style-tag {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 20px;
            color: #101828;
            margin: 0.2rem 0 1rem;
          }
        }
      }
    }

    .footer-modal {
      display: flex;
      justify-content: center;
      flex-direction: column;
      align-items: center;
      gap: 2rem;

      @media (max-width: 800px) {
        margin: auto 2rem auto;
      }

      .button-container {
        display: flex;
        justify-content: center;
        width: 100%;
        gap: 2rem;
      }

      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1rem;
        line-height: 20px;
        color: #fbcb5c;
        margin: auto auto 19px;

        @media (max-width: 800px) {
          line-height: 1.5rem;
          text-align: center;
        }
      }
    }
  }
}

.verificationBtn {
  width: 200px;
  background: #7f56d9;
  color: #fff;
  font-size: 1.6rem;
  height: 50px;
  border-radius: 8px;
  box-shadow: none;
  text-transform: capitalize;
  margin-top: 0rem;
  border: none;
  font-size: 1.4rem;
  padding: 0.5rem 2.5rem;

  // @media (max-width: 520px) {
  //   width: 100%;
  // }

  &:hover {
    background-color: #5d3cb289;
    box-shadow: none;
  }
}
