.navDropContainer {
  position: absolute;
  top: 90%;
  left: 12%;
  width: 76%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  padding: 0;
  margin-top: 1.2rem;
  height: 300px;

  @media (max-width: 1000px) {
    top: 99%;
    left: 12%;
    width: 76%;
    z-index: 500;
  }

  @media (max-width: 600px) {
    top: 98%;
    left: 0%;
    width: 100%;
  }

  .listMenu {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: #101828;
    margin-top: 1rem;
  }

  .listMenuIcon {
    padding: 1rem 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: #101828;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
    border-bottom: 0.2px solid #6670856e;

    .tag {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 20px;
      color: #667085;
      margin: 0.6rem 0 0 3.2rem;
    }

    &:hover {
      background-color: hsla(221, 13%, 46%, 0.04);
      transform: scale(0.99);
      transition: slace 2s ease-in-out;
    }
  }
}
