.OverviewMainLowerSection {
  width: 100%;

  .OverviewMainLowerSectionFirstSection {
    width: 100%;
    margin: 30px 0px;

    @media screen and (max-width: 570px) {
      margin: 0px;
    }
  }

  .OverviewMainLowerSectionSecondSection {
    width: 100%;
    margin: 30px 0px;
  }
}
