.postTrade__thirdLayout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: space-between;
  border-bottom: 1px solid #d8d8d880;
  padding: 15px 30px;

  .postTrade__thirdLayoutOne {
    width: 40%;

    .postTrade__thirdLayoutOneCurrency {
      .postTrade__thirdLayoutOneCurrencyCountry {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
          color: #292323;
          font-style: normal;
          font-weight: 600;
          font-size: 12px;
          line-height: 16px;
        }

        input {
          outline: none;
          padding: 10px 12px;
          border-radius: 8px;
          border: 1px solid #d0d5dd;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          color: #667085;
          line-height: 21px;
          width: 73%;
        }
      }

      .postTrade__thirdLayoutOneCurrencyMargin {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 50px 0px;

        .postTrade__thirdLayoutOneCurrencyMarginLabel {
          p {
            color: #292323;
            font-style: normal;
            font-weight: 600;
            font-size: 12px;
            line-height: 16px;
          }
        }

        .postTrade__thirdLayoutOneCurrencyMarginInput {
          width: 73%;
          label {
            color: #344054;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 15px;
          }

          .postTrade__thirdLayoutOneCurrencyMarginInputField {
            display: flex;
            align-items: center;
            width: 100%;
            border: 1px solid #d0d5dd;
            border-radius: 8px;
            margin-top: 5px;

            .postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInput {
              display: flex;
              align-items: center;
              border-radius: 8px 0px 0px 8px;
              justify-content: center;
              width: 90%;
              /* padding: 0px 10px; */
              border-right: 0.855949px solid #d0d5dd;

              .postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIconBody {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 20%;
                cursor: pointer;

                .postTrade__thirdLayoutOneCurrencyMarginInputFieldCalInputIcon {
                  color: #344054;
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 200;
                  font-size: 13.6952px;
                  text-align: center;
                  line-height: 21px;
                }
              }

              input {
                padding: 10px 9px;
                /* border-radius: 8px; */
                outline: none;
                border: none;
                color: #667085;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 14px;
                line-height: 21px;
                width: 70%;
                text-align: center;
                /* margin: 0px 10px; */
                border-left: 0.855949px solid #d0d5dd;
                border-right: 0.855949px solid #d0d5dd;
              }
            }

            .postTrade__thirdLayoutOneCurrencyMarginInputFieldText {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 10%;

              p {
                font-size: 16px;
                font-weight: 700;
                color: #667085;
                font-family: "Inter";
                font-style: normal;
                font-weight: 400;
                font-size: 13.6952px;
                line-height: 21px;
              }
            }
          }
        }
      }
    }
  }

  .postTrade__thirdLayoutTwo {
    background-color: #fff;
    box-shadow: 0px 4px 10px 4px rgba(0, 0, 0, 0.047);
    text-align: center;
    border-radius: 8px;
    width: 30%;

    .postTrade__thirdLayoutTwoFirstLayout {
      background-color: #fff;
      border-radius: 8px 8px 0px 0px;

      .postTrade__thirdLayoutTwoFirstLayoutPrice {
        border-bottom: 1px solid #dadada66;
        padding: 10px 0px;

        p {
          color: #344054;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;

          span {
            color: #f7931a;
          }
        }
      }

      .postTrade__thirdLayoutTwoFirstLayoutPriceAmount {
        height: 120px;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-bottom: 1px solid #dadada66;
        color: #7f56d9;
        padding: 10px 12px;

        p {
          /* font-weight: 700;
            font-size: 26px; */
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #7f56d9;
        }
      }

      .postTrade__thirdLayoutTwoFirstLayoutPriceDetails {
        padding: 10px 12px;

        p {
          color: #344054;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 12px;
          line-height: 12px;
        }
      }
    }

    .postTrade__thirdLayoutTwoSecondLayout {
      background-color: #53389e;
      padding: 20px 12px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      border-radius: 0px 0px 8px 8px;

      .postTrade__thirdLayoutTwoSecondLayoutPrice {
        color: #fff;
        padding: 10px 0px 15px 0px;
        p {
          /* font-weight: 700;
            font-size: 26px; */
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 20px;
          line-height: 24px;
          color: #fff;
        }
      }

      .postTrade__thirdLayoutTwoSecondLayoutPriceDetails {
        padding: 10px 0px;

        .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMargin {
          padding: 6px 0px;
          color: rgba(255, 255, 255, 0.7);
          p {
            /* font-size: 15px;
              font-weight: 500; */
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12px;
            line-height: 17px;
            color: rgba(255, 255, 255, 0.7);
          }
        }

        .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmount {
          background-color: #fff;
          border-radius: 8px;
          padding: 8px;
          margin: 8px 0px;

          .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmountBody {
            background-color: #fff;
            box-shadow: -2px 8px 10px 10px rgba(0, 0, 0, 0.047);
            padding: 10px;
            border-radius: 8px;

            p {
              color: "#fda29b";
              /* font-size: 16px;
                font-weight: 500; */
              font-family: "Inter";
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 16px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 820px) {
  .postTrade__thirdLayout {
    .postTrade__thirdLayoutOne {
      width: 46%;

      .postTrade__thirdLayoutOneCurrency {
        .postTrade__thirdLayoutOneCurrencyCountry {
          flex-direction: column;
          align-items: flex-start;

          input {
            width: 100%;
            margin-top: 5px;
          }
        }

        .postTrade__thirdLayoutOneCurrencyMargin {
          flex-direction: column;
          align-items: flex-start;

          // .postTrade__thirdLayoutOneCurrencyMarginLabel {
          // }

          .postTrade__thirdLayoutOneCurrencyMarginInput {
            width: 100%;
            margin-top: 5px;

            label {
              display: none;
            }
          }
        }
      }
    }

    .postTrade__thirdLayoutTwo {
      width: 43%;
    }
  }
}

@media screen and (max-width: 500px) {
  .postTrade__thirdLayout {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 5px;

    .postTrade__thirdLayoutOne {
      width: 100%;
    }

    .postTrade__thirdLayoutTwo {
      width: 100%;
      background-color: unset;
      box-shadow: unset;
      text-align: left;
      border-radius: 0px;

      .postTrade__thirdLayoutTwoFirstLayout {
        background-color: unset;
        border-radius: 0px;
        display: flex;
        align-items: center;

        .postTrade__thirdLayoutTwoFirstLayoutPrice {
          border-bottom: unset;
          padding: 10px 3px 10px 0px;

          p {
            // color: #344054;
            // font-family: "Inter";
            // font-style: normal;
            font-weight: 500;
            font-size: 1.4rem;
            color: #344054;
            line-height: 24px;
          }
        }

        .postTrade__thirdLayoutTwoFirstLayoutPriceAmount {
          height: unset;
          width: unset;
          // display: flex;
          // align-items: center;
          // justify-content: center;
          border-bottom: unset;
          color: #344054;
          padding: 0px;

          p {
            color: #344054;
            /* font-weight: 700;
              font-size: 26px; */
            // font-family: "Inter";
            // font-style: normal;
            font-weight: 900;
            font-size: 1.6rem;
            // line-height: 24px;
          }
        }

        .postTrade__thirdLayoutTwoFirstLayoutPriceDetails {
          display: none;
        }
      }

      .postTrade__thirdLayoutTwoSecondLayout {
        background-color: unset;
        padding: 0px;
        display: flex;
        align-items: center;
        justify-content: unset;
        flex-direction: unset;
        border-radius: 0px;

        .postTrade__thirdLayoutTwoSecondLayout__MobileForm {
          display: block;
          padding: 0px 3px 0px 0px;
        }

        .postTrade__thirdLayoutTwoSecondLayoutPrice {
          display: none;
        }

        .postTrade__thirdLayoutTwoSecondLayoutPriceDetails {
          .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMargin {
            display: none;
          }

          .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmount {
            background-color: unset;
            border-radius: unset;
            padding: unset;
            margin: unset;

            .postTrade__thirdLayoutTwoSecondLayoutPriceDetialsMarginAmountBody {
              background-color: unset;
              box-shadow: unset;
              padding: unset;
              border-radius: unset;

              p {
                color: rgba(0, 164, 120, 1);
                font-weight: 500;
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 1.4rem;
                line-height: 16px;
              }
            }
          }
        }
      }
    }
  }
}
