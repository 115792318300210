.pieWrapper {
  width: 100%;
  display: flex;
  // align-items: flex-start;
  position: relative;
  // border: 1px solid red;

  canvas {
    display: flex;
    align-items: flex-start;
    padding: 0;
  }

  .__innerDougnut {
    width: 100%;
    height: 100%;
    position: absolute;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding-bottom: 15px;

    .__spread__text {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 0.97031rem;
      line-height: 13px;
      font-size: 14px;
      color: #000000;
      opacity: 0.6;
    }

    .__spread__value {
      font-family: "Inter";
      font-style: normal;
      font-weight: 700;
      font-size: 1.66339rem;
      line-height: 23px;
      color: #000000;
      padding: 5px 0px;
    }

    @media screen and (max-width: 500px) {
      width: unset;
      height: unset;
      top: 50%;
      left: 34%;
    }

    @media screen and (max-width: 400px) {
      width: unset;
      height: unset;
      top: 50%;
      left: 31%;
    }

    @media screen and (max-width: 300px) {
      width: unset;
      height: unset;
      top: 50%;
      left: 25%;
    }
  }
}
