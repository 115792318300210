@import "colors";

.homepageContainer {
  display: flex;

  // margin-top: 4rem;
  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    // padding: 8rem 0;

    @media (max-width: 500px) {
      width: 95%;
    }

    .oyolaWalletStyles {
      width: 100%;
      background-color: transparent;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;
      // background-color: orange;
      .containerLeft {
        max-width: 100%;
        margin: 0rem 0 0rem auto;
        @media (max-width: 500px) {
          font-size: 3.5rem;
          width: 100%;
          // margin-bottom: 1rem;
          margin: 2rem 0 0;
        }
        .imageContainer {
          width: 450.54px;
          height: 100%;

          
        }
      }

      .containerRight {
        width: 550px;
        margin: 0rem auto 0rem 0;

        // @media (max-width: 1169px) {
        //   margin: 2rem auto 0rem;
        // }

        .title {
          font-weight: 700;
          font-size: 4rem;
          line-height: 49px;
          text-align: left;
          letter-spacing: -0.08rem;
          color: #101828;
          margin: 2rem auto 2rem;
          @media (max-width: 500px) {
            font-size: 3rem;
            width: 100%;
            // margin-bottom: 1rem;
            margin: 2rem 0 0;
          }
        }

        .tag {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 35px;
          text-align: left;
          color: #667085;
          width: 100%;
          margin: auto;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            line-height: 25px;
          }
        }
      }
    }
  }
}
