.footerContainer {
  width: 100%;
  margin: 0rem auto 0rem;
  // background-color: #1d2939;
  background-color: #101828;

  .innerFooterContainer {
    width: 80%;
    color: #eaecf0;
    margin: auto;
    margin: 0rem auto;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .firstRow {
      padding-top: 4rem;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      gap: 1rem;

      @media (max-width: 1100px) {
        flex-direction: column;
      }

      .logoContainer {
        @media (max-width: 1000px) {
          margin: auto 2rem;
        }

        @media (max-width: 500px) {
          margin: auto 3rem;
        }

        .logo {
          width: 142px;
          height: auto;
          margin-bottom: 2rem;
        }
        .text {
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 24px;
          color: #eaecf0;
        }
      }

      .rightContainerContent {
        display: flex;
        flex: 0.9;
        justify-content: space-between;
        flex-wrap: wrap;

        @media (max-width: 1100px) {
          margin: 2rem 0;
        }

        @media (max-width: 1000px) {
          margin: 4rem 2rem;
          .title {
            font-size: 14px;
            line-height: 20px;
          }
          .text {
            font-size: 14px;
            line-height: 24px;
            color: #eaecf0;
          }
        }

        @media (max-width: 500px) {
          margin: 4rem 3rem;
        }

        .title {
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #98a2b3;
          margin-bottom: 1rem;
        }

        .text {
          font-weight: 500;
          font-size: 16px;
          line-height: 34px;
          color: #eaecf0;
          cursor: pointer;

          @media (max-width: 500px) {
            font-size: 13px;
          }

          &:hover {
            color: #eaecf091;
          }
        }
      }
    }
  }

  .innerSecondFooterContainer {
    background-color: #101828;
    height: 80px;
    display: flex;
    align-items: center;

    .secondRow {
      display: flex;
      justify-content: space-between;
      width: 80%;
      margin: auto;

      .leftContainer {
        .text {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          color: #98a2b3;
        }
      }

      @media (max-width: 900px) {
        width: 100%;
        margin: auto 2rem;
        // flex-direction: column;
      }

      .rightInnerContainer {
        display: flex;
        gap: 1rem;

        .imageStyle {
          color: #98a2b3;
          cursor: pointer;
          &:hover {
            color: #eaecf091;
          }
        }
      }
    }
  }
  padding-bottom: 1rem;
}

.additionalStyle {
  .stitle {
    color: #eaecf0 !important;
    font-size: 16px !important;

    &:hover {
      color: #eaecf091 !important;
    }
  }
  @media (max-width: 500px) {
    width: 50%;
    margin-bottom: 4rem;
  }
}
