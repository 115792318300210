.navDropContainer {
  position: absolute;
  top: 90%;
  left: 63.6%;
  width: 20%;
  background: #ffffff;
  border: 1px solid #eaecf0;
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;

  .listMenu {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: #101828;
    margin-top: 1rem;
  }

  .listMenuIcon {
    padding: 1rem 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 24px;
    color: #101828;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    .tag {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.2rem;
      line-height: 20px;
      color: #667085;
      margin: 0.6rem 0 0 3.2rem;
    }

    &:hover {
      background-color: hsla(221, 13%, 46%, 0.04);
      transform: scale(0.99);
      transition: slace 2s ease-in-out;
    }
  }
}
