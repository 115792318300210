.outterSelectedContainer {
  display: flex;
  flex-direction: column;

  @media (max-width: 500px) {
    flex-direction: row;
  }
  .selectedContainer {
    width: 500px;
    height: 163px;
    color: white;
    padding: 2rem;
    background: white;
    border-radius: 8px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    margin-bottom: 2.6rem;
    align-items: center;

    @media (max-width: 1260px) {
      width: 70%;
      height: auto;
      margin-bottom: 3.5rem;
    }
    @media (max-width: 1000px) {
      width: 80%;
      height: auto;
      margin-bottom: 3.5rem;
    }

    @media (max-width: 500px) {
      width: 80%;
      height: 80px;
      margin-bottom: 8.5rem;
      margin-right: 1rem;
      padding: 1rem;
    }
    @media (max-width: 400px) {
      width: 90px;
      height: 40px;
      margin-bottom: 2.5rem;
      padding: 1rem;
      margin-right: 1rem;
    }

    .rowFourButtonContainerLeft {
      width: 20%;
      text-align: left;
      .SelecticonStyle {
        margin-bottom: 4rem;
        @media (max-width: 500px) {
          width: 30px;
          margin-bottom: 0rem;
        }
        @media (max-width: 400px) {
          width: 20px;
          margin: 0 1rem auto auto;
        }
      }
      @media (max-width: 400px) {
        // width: 5%;
      }
    }
    @media (max-width: 400px) {
      padding: 0;
    }
    .rowFourButtonContainerRight {
      width: 110%;
      .rowFourTitleButtonContainerRight {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 23px;
        display: flex;
        align-items: center;
        color: #ffffff;
        margin-bottom: 1rem;
        @media (max-width: 500px) {
          margin-bottom: 0rem;
        }

        @media (max-width: 400px) {
          font-size: 14px;
          margin: auto;
          line-height: normal;
        }
      }
      .rowFourTagButtonContainerRight {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: left;

        @media (max-width: 500px) {
          font-size: 8px;
          position: absolute;
          top: 10%;
          margin: 3rem 0;
          display: none;
        }
        @media (max-width: 500px) {
          font-size: 8px;
          position: absolute;
          top: 10%;
          margin: 3rem 0;
          display: none;
        }
      }
      .rowFourTagButtonContainerRightMobile {
        font-family: "Inter";
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        text-align: left;

        @media (max-width: 500px) {
          font-size: 8px;
          position: absolute;
          top: 10%;
          margin: 3rem 0;
        }
      }
    }
  }
}
