@import "colors";

.featuresContainer {
  display: flex;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;

    .titleContainer {
      margin: 8rem auto 4rem;
      text-align: left;
      width: 100%;

      .title {
        font-weight: 500;
        font-size: 3.35208rem;
        line-height: 42px;
        color: #101828;
        // color: $fontPrimary;
      }

      .subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 2rem;
        line-height: 32px;
        color: $fontSecondary;
        margin: 0rem auto 0;
      }
    }

    .card {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2rem;
      width: 100%;
      @media (max-width: 1000px) {
        justify-content: center;
      }
    }
  }
}
