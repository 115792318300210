.wallet__firstLayoutWrapper {
  width: 100%;

  .wallet__firstLayout {
    width: 100%;

    .wallet__firstLayoutHeader {
      .wallet__firstLayoutHeaderParagraph {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 20px;
        line-height: 42px;
        color: #101828;
        padding: 10px 0px;

        @media screen and (max-width: 720px) {
          font-size: 16px;
        }
      }

      .wallet__firstLayoutHeaderDetails {
        .wallet__firstLayoutHeaderDetailsOne {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 17.9718px;
          line-height: 27px;
          color: #4d5869;
        }

        .wallet__firstLayoutHeaderDetailsTwo {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 35.9436px;
          line-height: 22px;
          color: #101828;
          padding: 8px 0px;

          @media screen and (max-width: 720px) {
            font-size: 20px;
          }
        }
      }

      .wallet__firstLayoutContent {
        width: 50%;
        .wallet__firstLayoutContentDetails {
          background: #ffffff;
          border: 0.898589px solid rgba(208, 213, 221, 0.5);
          box-shadow: -15.276px -16.1746px 9.88448px #ffffff, 0px 0.898589px 1.79718px rgba(16, 24, 40, 0.05);
          border-radius: 7.18871px;
          display: flex;
          align-items: center;
          justify-content: space-between;
          /* width: 55%; */
          padding: 10px 10px;
          margin: 15px 0px;

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14.3774px;
            line-height: 22px;
            color: #7f56d9;
          }

          .wallet__firstLayoutContentDetailsCopyBodyWrapper {
            display: flex;
            align-items: center;
            /* justify-content: center; */

            .wallet__firstLayoutContentDetailsCopyBody {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0px 5px;
              cursor: pointer;

              .wallet__firstLayoutContentDetailsCopy {
                color: #7f56d9;
                width: 15.47px;
                height: 15.48px;
              }
            }

            .wallet__firstLayoutContentDetailsShareBody {
              display: flex;
              align-items: center;
              justify-content: center;
              margin: 0px 5px;
              cursor: pointer;

              .wallet__firstLayoutContentDetailsShare {
                color: #7f56d9;
                width: 15.28px;
                height: 17.07px;
              }
            }
          }
        }

        .wallet__firstLayoutContentButton {
          /* width: 55%; */
          margin: 10px 0px;

          button {
            width: 100%;
            border: none;
            outline: none;
            background-color: #7f56d9;
            padding: 9px 12.5802px;
            box-shadow: 0px 0.898589px 1.79718px rgba(16, 24, 40, 0.05);
            border-radius: 7.18871px;
            color: #fff;
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 12.5802px;
            line-height: 18px;

            &:hover {
              background-color: hsla(259, 63%, 59%, 0.8);
              border: 1px solid hsla(259, 63%, 59%, 0.8);
            }

            &:disabled {
              background-color: hsla(259, 63%, 59%, 0.4);
              border: 1px solid hsla(259, 63%, 59%, 0.4);
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 615px) {
//   .wallet__firstLayoutWrapper {
//     .wallet__firstLayout {
//       width: 40%;
//     }
//   }
// }

// @media screen and (max-width: 500px) {
//   .wallet__firstLayoutWrapper {
//     .wallet__firstLayout {
//       width: 100%;
//       .wallet__firstLayoutHeader {
//         h3 {
//           display: none;
//         }
//       }
//     }
//   }
// }
