.__trade__order__topWrapper {
  .tradeorder__titleWrapper {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin-bottom: 20px;
    width: 100%;
    .tradeorder__titleTitle {
      width: fit-content;
      .tradeorder__title {
        font-size: 24px;
        font-weight: 500;
        margin: 10px 0;
      }
      .tradeorder__titleDesc {
        font-size: 16px;
        font-weight: 400;
        color: #667085;
      }
    }

    .__postTrade__button {
      border-radius: 7px;
      height: 40px;
      outline: none;
      border: none;
      color: #53389e;
      background-color: transparent;
      border: 1px solid #53389e;
      padding: 0 15px;
      display: flex;
      justify-content: center;
      align-items: center;
      transition: all ease-in-out 0.2s;
      &:hover {
        font-weight: 600;
      }
    }
  }
  .__tradeorder__button__wrapper {
    display: flex;
    border-radius: 12px;
    border: 1px solid #d0d5dd;
    width: fit-content;
    margin: 40px 0 30px 0;
    @media screen and (max-width: 700px) {
      border: none;
      border-radius: none;
      padding: 10px 0;
      margin-top: 20px;
    }

    .__tradeorder__button {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 40px;
      // padding: 10px 0px;
      min-width: 96px;
      font-size: 14px;
      cursor: pointer;
      @media screen and (max-width: 700px) {
        margin-right: 15px;
        border: 1px solid #d0d5dd;
        border-radius: 10px;
        padding: 9px 0;
        font-size: 12px;
      }
      .__tradeorder__count {
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 50%;
        width: 17px;
        height: 17px;
        font-size: 11px;
        color: white;
        margin-right: 0px;
        margin-left: 5px;
        background-color: #53389e;
      }
      &.__ls {
        border-right: 1px solid #d0d5dd;
        &.__active {
          border-top-left-radius: 12px;
          border-bottom-left-radius: 12px;
          background-color: #53389e;
          color: white;
          .__tradeorder__count {
            background-color: white;
            color: #53389e;
          }
        }
      }
      &.__rs {
        border-left: 1px solid #d0d5dd;
        &.__active {
          border-top-right-radius: 12px;
          border-bottom-right-radius: 12px;
          background-color: #53389e;
          color: white;
          .__tradeorder__count {
            background-color: white;
            color: #53389e;
          }
        }
      }
      &.__md {
        border-left: 1px solid #d0d5dd;
        &.__active {
          background-color: #53389e;
          color: white;
          .__tradeorder__count {
            background-color: white;
            color: #53389e;
          }
        }
      }
    }
  }
  .__tradeorder__dropdown {
    max-width: 150px;
    margin: 40px 0;
    @media screen and (max-width: 700px) {
      margin: 20px 0;
    }
  }
}

.__thefil {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  @media screen and (max-width: 700px) {
    margin-bottom: 18px;
  }
  .__ggs {
    position: relative;
    width: 220px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border: 1px solid #eff0f6;
    border-radius: 20px;
    font-size: 15px;
    margin: 15px 0px;
    margin-right: 15px;
    //height: 40px;

    @media screen and (max-width: 490px) {
      width: 180px;
    }

    @media screen and (max-width: 406px) {
      width: 150px;
    }
    .newTable__th {
      width: 100%;
    }
  }
}
