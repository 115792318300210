.verificationWrapper {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  //   height: 20px;
  padding: 9px 5px;
  border: 1px solid #d0d5dd;
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  //   border: 1px solid red;

  .verificationWrapper__codeDetails {
    width: 23%;
  }

  .verificationWrapper__input {
    width: 76%;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    input {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #667085;
      width: 65%;
      outline: none;
      border: none;
    }

    // button {
    //   background: #7f56d9;
    //   border: 0.672311px solid #7f56d9;
    //   height: 31px;
    //   width: 71px;
    //   box-shadow: 0px 0.672311px 1.34462px rgba(16, 24, 40, 0.05);
    //   border-radius: 5.37849px;
    //   font-family: "Inter";
    //   font-style: normal;
    //   font-weight: 500;
    //   font-size: 9.41235px;
    //   line-height: 13px;
    //   color: #ffffff;
    //   outline: none;
    //   cursor: pointer;
    // }
  }
}
