.ulListContainer {
  width: 352px;
  background-color: #806cfb;
  height: 340.92px;
  padding: 2rem 0;
  border-radius: 2rem;
  list-style-type: none;

  @media (max-width: 1000px) {
    margin-top: 2rem;
  }

  .item {
    padding-left: 2rem;
    color: #fff;

    .icon {
      margin: 2rem 0;
    }
    .title {
      font-weight: 700;
      font-size: 1.8rem;
      line-height: 25px;
    }
    .text {
      margin-top: 1.3rem;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 24px;
      width: 80%;
    }
  }
}
