.button__styles {
  width: 50%;
  height: 40px;
  border: 1px solid #7f56d9;
  font-size: 14px;
  border-radius: 8px;
  // background-color: transparent;
  background: #7f56d9;
  color: white;

  &:hover {
    border: 1px solid #7f56d9db;
    background: #7f56d9db;
    color: white;
  }
}
.button__styles_cancel {
  width: 50%;
  height: 40px;
  border: 1px solid #d0d5dd;
  font-size: 14px;
  border-radius: 8px;
  background-color: transparent;
}
