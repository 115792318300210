.postTradeWrapper {
  width: 100%;
  position: relative;
  z-index: 1;
  padding-bottom: 40px;
  // margin: 0 auto;
  box-shadow: 0.868707px 14.768px 38.2231px rgba(3, 2, 41, 0.07);

  .ParentButton {
    padding: 2px 10px;

    display: flex;
    align-items: center;
    gap: 40px;

    .verify_indicator {
      display: flex;
      align-items: center;

      .verify_indicator_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        .verify_indicator_icon {
          font-size: 16px;
          color: #f3ba2f;
        }
      }

      .verify_indicator_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #344054;
      }
    }
  }

  .postTrade__Container {
    background-color: #fff;
    margin: 15px 0px;
    box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.047);
    border-radius: 8px;

    /*first layout styles*/

    /*second layout styles*/

    .postTrade__Increment {
      padding: 15px 10px;
      width: 50%;
      position: absolute;
    }

    /*third layout styles*/

    /*fourth layout styles*/
  }
}

@media screen and (max-width: 820px) {
  .postTradeWrapper {
    .postTrade__Container {
      .postTrade__Increment {
        width: 100%;
        position: unset;
      }

      /*first layout styles*/

      /*second layout styles*/

      /*third layout styles*/

      /*fourth layout styles*/
    }
  }
}

// @media screen and (max-width: 720px) {
//   .postTradeWrapper {
//     .postTrade__Container {
//       /*first layout styles*/

/*fiveth layout styles*/
//     }
//   }
// }

// @media screen and (max-width: 713px) {
//   .postTradeWrapper {
//     .postTrade__Container {
/*fiveth layout styles*/
//     }
//   }
// }

// @media screen and (max-width: 691px) {
//   .postTradeWrapper {
//     .postTrade__Container {
//       /*fiveth layout styles*/
//     }
//   }
// }

@media screen and (max-width: 500px) {
  .postTradeWrapper {
    box-shadow: unset;
    .postTrade__Container {
      box-shadow: unset;

      /*first layout styles*/

      /*second layout styles*/

      /*third layout styles*/

      /*fourth layout styles*/

      /*fiveth layout styles*/
    }
  }
}
