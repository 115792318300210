.OverviewMainTopSection {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px;

  .OverviewMainTopSectionFirstLayout {
    width: 48%;

    .OverviewMainTopSectionFirstLayoutDetails {
      .OverviewMainTopSectionFirstLayoutDetailsContent {
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 28px;
          color: #101828;
        }
      }

      .OverviewMainTopSectionFirstLayoutDetailsTransactions {
        margin-top: 15px;

        .OverviewMainTopSectionFirstLayoutDetailsTransactionsOne {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 1.79718rem;
          line-height: 27px;
          color: #4d5869;
        }

        .OverviewMainTopSectionFirstLayoutDetailsTransactionsTwo {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 3.59436rem;
          line-height: 22px;
          color: #101828;
          margin-top: 12px;
        }
      }
    }
  }

  .OverviewMainTopSectionSecondLayout {
    width: 50%;
    display: flex;
    align-items: center;
  }
}

@media screen and (max-width: 570px) {
  .OverviewMainTopSection {
    flex-direction: column;

    .OverviewMainTopSectionFirstLayout {
      width: 100%;
    }

    .OverviewMainTopSectionSecondLayout {
      width: 100%;
      align-items: flex-start;
      margin-top: 35px;
    }
  }
}
