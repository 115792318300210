.__createTrade__detail__wraper {
  width: 59%;
  @media screen and (max-width: 990px) {
    width: 52%;
  }

  @media screen and (max-width: 730px) {
    width: 100%;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    border-radius: 8px;
    padding: 20px;
  }
  .__transaction__state {
    border-radius: 20px;
    padding: 5px;
    display: inline-block;
    // @media screen and (max-width: 730px) {

    // }

    &.__pending {
      background-color: #f3ba2f1a;
    }
    &.__paid {
      background-color: #9481ff1c;
    }
    &.__complete {
      background-color: #34d07f1e;
    }
    &.__cancelled {
      background-color: #6670851a;
    }
    &.__dispute {
      background-color: #24343f13;
    }

    .__state__inner {
      height: 80%;
      padding: 7px 16px;
      border-radius: 20px;
      font-size: 12px;
      color: white;
      display: flex;
      justify-content: center;
      align-items: center;
      @media screen and (max-width: 730px) {
        font-size: 12px;
      }
      &.__pending {
        background-color: #f3ba2f;
      }
      &.__paid {
        background-color: #9381ff;
      }
      &.__complete {
        background-color: #34d07f;
      }
      &.__cancelled {
        background-color: #667085;
      }

      &.__dispute {
        background-color: #24343f;
      }
    }
  }

  .__order__info {
    padding: 30px 0px;
    .__order__info {
      font-weight: 500;
      font-size: 16px;
    }
    .__order__info__wrapper {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      margin-top: 14px;
      .__order__info__amount {
        display: flex;
        align-items: flex-end;
        padding: 14px 0;
        margin-right: 15px;
        @media screen and (max-width: 730px) {
          justify-content: space-between;
          width: 100%;
          margin-right: 0px;
          padding: 17px 0;
        }

        .__order__info__title {
          color: #667085;
          font-size: 13px;
          margin-right: 4px;
          @media screen and (max-width: 730px) {
            font-size: 12px;
          }
        }
        .__order__info__value {
          color: black;
          font-size: 15px;
          font-weight: 500;
          line-height: normal;
          &.blue {
            color: #53389e;
          }

          @media screen and (max-width: 730px) {
            font-size: 14px;
          }
        }
      }
    }
    .__otherpayment {
      background-color: #fafafa;
      margin-top: 15px;
      border-radius: 5px;
      border: 1.78px solid rgba(219, 219, 219, 0.25);
      padding: 20px 25px;
      flex-grow: 1;

      @media screen and (max-width: 730px) {
        display: none;
      }

      .__References {
        font-size: 14px;
        color: #202020;

        &.__country {
          margin-right: 10px;
        }
      }
      .__txId {
        font-size: 15px;
        color: #667085;
        line-height: 24px;
        display: flex;
        margin-top: 10px;
        align-items: center;
        cursor: pointer;

        &.__country {
          opacity: 0.6;
        }
      }
    }
  }

  .__create__trade__paymentDetails {
    background-color: #fafafa;
    margin-top: 30px;
    border-radius: 5px;
    border: 1.78px solid rgba(219, 219, 219, 0.25);
    padding: 20px 25px;
    width: 47.5vw;

    @media screen and (max-width: 1290px) {
      width: 100%;
      height: 250px;
      background-color: transparent;
      margin-bottom: 15px;
    }

    @media screen and (max-width: 730px) {
      width: 100%;
      height: 250px;
      background-color: transparent;
      margin-bottom: 15px;
    }

    .__create__trade__paymentDetails__Header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 12px;
      cursor: pointer;

      .__payment__tandc {
        // width: 100%;
        text-align: left;
        font-size: 14px;
        color: #667085;
      }

      .searchAbleIconDecorator {
        .searchIconBody {
          display: flex;
          align-items: center;
          justify-content: center;

          .searchIcon {
            font-size: 18px;
            color: #667085;
          }
        }
      }
    }

    .__the {
      height: 80%;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 0px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 0px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 1px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #eaecf0;
      }

      .__tandc {
        font-size: 13px;
        color: #667085;
        line-height: 27px;
        word-wrap: break-word;
      }
    }
  }

  .__review__wallet {
    display: flex;
    align-items: flex-start;
    flex-flow: row;
    height: auto;
    justify-content: space-between;
    @media screen and (max-width: 800px) {
      flex-direction: column;
      align-items: flex-start;
    }
    .__review__lhs {
      width: 50%;
      @media screen and (max-width: 800px) {
        width: 80%;
      }

      .__goto__wallet {
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #667085;
        margin-bottom: 10px;
      }
      .__view__wallet {
        outline: none;
        color: white;
        display: flex;
        height: 40px;
        justify-content: center;
        align-items: center;
        padding: 0 15px;
        background-color: #7f56d9;
        border: none;
        border-radius: 5px;

        font-size: 13px;
      }
    }
    .__review__rhs {
      width: 50%;
      @media screen and (max-width: 800px) {
        width: 100%;
      }

      .__review__r {
        padding: 0px 0;
        .__experience {
          font-weight: 400;
          font-size: 14px;
          color: #667085;
          display: block;
          line-height: 24px;
          margin-bottom: 10px;

          text-align: center;
        }
        .__reviewRes {
          font-size: 12px;
          text-align: center;
          display: block;
          margin-bottom: 7px;
          &.__err {
            color: red;
          }
          &.__cor {
            color: green;
          }
        }
        .__review__wrapper {
          display: flex;
          justify-content: space-around;
          .__review {
            display: flex;
            align-items: center;
            justify-content: center;
            outline: none;
            border: 1px solid #d0d5dd;
            padding: 10px 18px;
            width: 40%;
            color: #24343f;
            border-radius: 15px;
            background-color: white;
            svg {
              color: rgb(168, 163, 163);
            }

            &.__pos {
              border-color: #34d07f;
              color: #34d07f;

              svg {
                color: #34d07f;
              }
            }
            &.__neg {
              border-color: red;
              color: red;
              svg {
                color: red;
              }
            }
          }
        }
      }
    }
  }
}
