.UniqueWrapper {
  width: 100%;

  .Header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: all 2s ease;
    padding-bottom: 15px;
    border-bottom: 1px solid #dee0e4;

    .HeaderText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 2rem;
      line-height: 30px;
      color: #101828;

      @media screen and (max-width: 500px) {
        font-size: 1.8rem;
      }
    }

    .iconBody {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 20px;
      height: 20px;
      border-radius: 50%;
      border: 1px solid #7f56d9;
      transition: all 2s ease;
      cursor: pointer;

      .icon {
        font-size: 18px;
        color: #7f56d9;
      }
    }
  }

  .Body {
    margin-top: 8px;
    transition: all 2s ease;

    .title {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 24px;
      color: #667085;
      margin: 0px 0px 8px 0px;
      white-space: pre-wrap;
      transition: all 2s ease;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    .paragraph {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 24px;
      color: #667085;
      margin: 0px 0px 15px 0px;
      transition: all 2s ease;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    .BodyText {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 24px;
      color: #667085;
      white-space: pre-wrap;
      transition: all 2s ease;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }

    .BodyTextState {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.6rem;
      line-height: 24px;
      color: #667085;
      margin: 0px 0px 15px 0px;

      @media screen and (max-width: 500px) {
        font-size: 1.4rem;
      }
    }
  }
}
