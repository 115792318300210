.amountScallingWrapper {
  .amountScallingContent {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 42px;
      color: #101828;
      padding: 10px 0px;

      @media screen and (max-width: 720px) {
        font-size: 16px;
      }
    }

    .amountScallingContentAmount {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 22px;
      line-height: 22px;
      color: #101828;

      @media screen and (max-width: 720px) {
        font-size: 18px;
      }
    }

    .amountScallingContentIncreament {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 42px;
      color: #03a66d;

      @media screen and (max-width: 720px) {
        font-size: 14px;
      }
    }
  }
}
