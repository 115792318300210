.__createTrade__bottom__Area {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 14px;
  @media screen and (max-width: 730px) {
    padding: 20px 10px 30px 10px;
    flex-direction: column;
    align-items: flex-start;
    width: 100%;
  }

  .__accept__transaction__area {
    width: 50%;
    @media screen and (max-width: 730px) {
      width: 100%;
    }
    .__make__Sure {
      font-size: 14px;
      .__recieve__payment {
        color: #f3ba2f;
      }
    }
    .__have__received {
      border: none;
      outline: none;
      padding: 10px 15px;
      border-radius: 6px;
      border: 1px solid #d0d5dd;
      color: white;
      cursor: pointer;
      background-color: #7f56d9;
      margin: 25px 25px 25px 0;
      &.__sell {
        background-color: #34d07f;
      }
      &:disabled {
        cursor: not-allowed;
        background-color: white;
        color: #9a9a9b;
        cursor: not-allowed;
      }

      &.__cancel__tx {
        background-color: white;
        color: #9a9a9b;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }

    .__the__warningmsg {
      .__the__title {
        display: flex;
        align-items: center;
        margin-bottom: 2px;
        svg {
          color: #c91212;
          margin-right: 5px;
          &.__paid {
            color: #34d07f;
          }
        }
        .geg {
          color: #c91212;
          font-weight: 500;
          font-size: 16px;

          &.__paid {
            color: #34d07f;
          }
        }
      }

      p {
        font-size: 14px;
        width: 70%;
        font-weight: 400;
        color: #3e3e3f;
        line-height: 22px;
        @media screen and (max-width: 700px) {
          width: 100%;
        }
      }
    }
  }
  .__dispute__area {
    @media screen and (max-width: 730px) {
      width: 100%;
      margin-right: 0px;
    }

    display: flex;
    align-items: center;
    margin-right: 20px;
    .__do__you__want__to {
      color: #f3ba2f;
      font-size: 14px;
      margin-right: 25px;
      @media screen and (max-width: 730px) {
        font-size: 12px;
        margin-right: 10px;
      }
    }
    .__create__dispute {
      outline: none;
      padding: 10px 15px;
      border-radius: 6px;
      border: 1px solid #d0d5dd;
      color: #9a9a9b;
      background-color: white;
      margin: 25px 0;
      cursor: pointer;
      @media screen and (max-width: 730px) {
        padding: 8px 12px;
        border: 1px solid #d0d5dd;
        background-color: transparent;
        margin: 0;
      }

      &.__active {
        color: black;
      }

      &:disabled {
        cursor: not-allowed;
      }
    }
  }
}
