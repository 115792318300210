.textContainerStyle {
  margin: 6rem auto 0rem;

  @media (max-width: 800px) {
    margin: 2rem auto 0rem;
  }

  @media (max-width: 500px) {
    margin: 1rem auto 0rem;
  }

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 44px;
    line-height: 52px;
    text-align: center;
    letter-spacing: -0.8px;
    color: #101828;
    width: 50%;
    margin: auto;
    margin-bottom: 2rem;
    @media (max-width: 800px) {
      font-size: 30px;
      line-height: 35px;
      width: 100%;
    }
  }
  .tag {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    text-align: center;
    color: #111111;
    @media (max-width: 800px) {
      // font-size: 30px;
      width: 80%;
      margin: auto;
    }
  }
}
