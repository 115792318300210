.crossCheckWrapper {
  text-align: center;
  width: 480px;
  background-color: #fff;
  //   padding: 20px;
  border-radius: 8px;

  .crossCheckWrapper__header {
    padding: 20px 20px 0px 20px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
    }
  }

  .crossCheckWrapper__amountDetails {
    margin: 23px 0px;
    .crossCheckWrapper__amountDetailsHeader {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
    }

    .crossCheckWrapper__amountDetailsDetails {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #344054;
      padding: 7px 0px;
    }
  }

  .crossCheckWrapper__addressDetails {
    padding: 21px 20px;
    border-bottom: 1px solid #eaecf0;

    .crossCheckWrapper__addressDetailsfirstLayout {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .crossCheckWrapper__addressDetailsfirstLayoutHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }

      .crossCheckWrapper__addressDetailsfirstLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }

    .crossCheckWrapper__addressDetailsSecondLayout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 14px 0px 0px 0px;

      .crossCheckWrapper__addressDetailsSecondLayoutHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }

      .crossCheckWrapper__addressDetailsSecondLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }

  .crossCheckWrapper__coinDetails {
    padding: 23px 20px 20px 20px;

    .crossCheckWrapper__coinDetailsFirstLayout {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .crossCheckWrapper__coinDetailsFirstLayoutHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }

      .crossCheckWrapper__coinDetailsFirstLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }

    .crossCheckWrapper__coinDetailsSecondLayout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 14px 0px 0px 0px;

      .crossCheckWrapper__coinDetailsSecondLayoutHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }

      .crossCheckWrapper__coinDetailsSecondLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }

    .crossCheckWrapper__coinDetailsThirdLayout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 14px 0px 0px 0px;

      .crossCheckWrapper__coinDetailsThirdLayoutHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }

      .crossCheckWrapper__coinDetailsThirdLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }
    }
  }

  .crossCheckWrapper__withdrawalDetials {
    padding: 20px 20px 20px 20px;

    p {
      background: rgba(251, 203, 92, 0.1);
      border-radius: 8px;
      padding: 16px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 12px;
      line-height: 20px;
      color: #667085;
    }
  }

  .crossCheckWrapper__buttons {
    width: 100%;
    padding: 20px 20px 20px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .crossCheckWrapper__cancelButtons {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 48%;
      height: 40px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #344054;
      cursor: pointer;

      &:hover {
        background: #e9e9e9;
      }
    }

    .crossCheckWrapper__confirmButtons {
      background: #7f56d9;
      border: 1px solid #7f56d9;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 48%;
      height: 40px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #ffffff;
      cursor: pointer;

      &:hover {
        background: #8b5af5;
        border: 1px solid #8b5af5;
      }
    }
  }
}
