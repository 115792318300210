.modalWrapper {
  z-index: 1000;
  // padding: 50px 0px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(52, 64, 84, 0.25);
  backdrop-filter: blur(0px);

  .modalContainer {
    // padding: 50px 0px;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow-y: auto;

    .modalContent {
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      padding: 0px;
      outline: 0;
      width: 100%;
      height: 100%;
      // width: fit-content;
    }
  }
}
