.career {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 50px 0px;
}

.careerFeature__firstLayoutBody {
  width: 30%;
}

.careerFeature__firstLayoutBody h4 {
  font-size: 18px;
  font-weight: 600;
  color: #101828;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 30px;
}

.careerFeature__firstLayoutBody p {
  font-size: 12px;
  color: #667085;
  padding: 8px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

.careerFeature__secondLayoutBody {
  width: 65%;
}

.careerFeature__secondLayout {
  border-radius: 12px;
  padding: 24px 18px;
  margin-bottom: 20px;
  border: 1px solid #eff1fc;
}

.careerFeature__secondLayoutUpLayout {
  display: flex;
  justify-content: space-between;
  margin-bottom: 30px;
}

/* .careerFeature__secondLayoutUpLayoutHeader {

} */

.careerFeature__secondLayoutUpLayoutHeader h5 {
  color: #101828;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.careerFeature__secondLayoutUpLayoutHeader p {
  color: #667085;
  padding: 6px 0px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
}

/* .careerFeature__secondLayoutUpLayoutButton{

} */

.careerFeature__secondLayoutUpLayoutButton button {
  border: none;
  outline: none;
  background-color: #2240d4;
  color: #fff;
  /* padding: 8px 12px; */
  border-radius: 20px;
  font-size: 13px;
  font-weight: 600;
  font-family: "Inter";
  font-style: normal;
  line-height: 17px;
  background: #7f56d9;
  height: 33px;
  width: 100px;
  border: 0.721608px solid #d0d5dd;
  box-shadow: 0px 0.721608px 1.44322px rgba(16, 24, 40, 0.05);
  border-radius: 5.77286px;
  cursor: pointer;
}

.careerFeature__secondLayoutUpLayoutButton button:hover {
  background-color: hsla(259, 63%, 59%, 0.79);
}

.careerFeature__secondLayoutLowerLayout {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.careerFeature__secondLayoutUpLayoutFeatures {
  display: flex;
  align-items: center;
  width: 260px;
  justify-content: space-between;
  color: #667085;
}

.careerFeature__secondLayoutUpLayoutFeaturesTime {
  display: flex;
  align-items: center;
}

.careerFeature__secondLayoutUpLayoutFeaturesTimeIconbody {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.careerFeature__secondLayoutUpLayoutFeaturesTimeIcon {
  font-size: 16px;
}

.careerFeature__secondLayoutUpLayoutFeaturesTimeText p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #667085;
}

.careerFeature__secondLayoutUpLayoutFeaturesMoney {
  display: flex;
  align-items: center;
}

.careerFeature__secondLayoutUpLayoutFeaturesMoneyIconbody {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 10px;
}

.careerFeature__secondLayoutUpLayoutFeaturesMoneyIcon {
  font-size: 16px;
}

.careerFeature__secondLayoutUpLayoutFeaturesMoneyText p {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 24px;
  color: #667085;
}

.careerFeature__secondLayoutLowerLayoutResidency {
  padding: 4px 8px;
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: #344054;
  background: #f2f4f7;
  border-radius: 16px;
}

@media screen and (max-width: 920px) {
  .careerFeature__secondLayoutUpLayoutHeader {
    width: 80%;
  }

  .careerFeature__secondLayoutUpLayoutFeatures {
    width: 65%;
  }
}

@media screen and (max-width: 770px) {
  .careerFeature__firstLayoutBody {
    width: 25%;
  }

  .careerFeature__secondLayoutBody {
    width: 70%;
  }

  .careerFeature__secondLayoutUpLayoutFeatures {
    flex-direction: column;
    align-items: flex-start;
  }

  .careerFeature__secondLayoutUpLayoutFeaturesTime {
    padding: 5px 0px;
  }

  .careerFeature__secondLayoutUplayoutFeaturesMoney {
    padding: 5px 0px;
  }
}

@media screen and (max-width: 570px) {
  .career {
    flex-direction: column;
  }

  .careerFeature__firstLayoutBody {
    width: 100%;
    padding: 20px 0px;
  }

  .careerFeature__secondLayoutBody {
    width: 100%;
  }
}

@media screen and (max-width: 500px) {
  .career {
    padding: 5px 0px;
  }

  .careerFeature__firstLayoutBody h4 {
    font-size: 18px;
  }

  .careerFeature__firstLayoutBody p {
    font-size: 14px;
  }

  .careerFeature__secondLayoutUpLayoutHeader h5 {
    font-size: 16px;
  }

  .careerFeature__secondLayoutUpLayoutHeader p {
    font-size: 14px;
  }

  .careerFeature__secondLayoutUpLayoutButton button {
    font-size: 12px;
  }

  .careerFeature__secondLayoutUpLayoutFeaturesTimeIcon {
    font-size: 14px;
  }

  .careerFeature__secondLayoutUpLayoutFeaturesTimeText p {
    font-size: 14px;
  }

  .careerFeature__secondLayoutUpLayoutFeaturesMoneyIcon {
    font-size: 14px;
  }

  .careerFeature__secondLayoutUpLayoutFeaturesMoneyText p {
    font-size: 14px;
  }

  .careerFeature__secondLayoutLowerLayoutResidency {
    font-size: 13px;
  }
}
