.ConvertMainDropDownContainer {
  width: 30%;
  z-index: 10000000;
  position: absolute;
  display: flex;
  justify-content: flex-end;
  top: 40%;
  cursor: pointer;

  .ConvertDropDownContainer {
    width: 60%;
    background-color: white;
    border-radius: 16px;
    border: 1px solid #efefef;
    box-shadow: 0px 4px 64px 0px rgba(0, 0, 0, 0.06);
    margin-right: 35px;
  }

  .ConvertDropDownTextContainerStyle {
    padding: 20px;
    border-top: 1px solid #f2f4f7;
    border-bottom: 2px solid #f2f4f7;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    .ConvertDropDownTextStyle {
      color: #344054;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 600;
      line-height: 20px; /* 142.857% */
    }
  }

  .ConvertDropButtonBody {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    padding: 20px;
    .ConvertDropButtonBodyIconAndText {
      display: flex;
      gap: 10px;
      align-items: center;
      .ConvertDropButtonBodyIconAndTextOne {
        font-family: Inter;
        font-size: 16px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        color: #344054;
      }
      .ConvertDropButtonBodyIconAndTextTwo {
        color: #344054;
        font-family: Inter;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 20px;
      }
      .ConvertDropButtonBodyIcon {
        width: 20px;
        height: 20px;
      }
    }
    .ConvertDropButtonBodyTickIcon {
      width: 20px;
      height: 20px;
    }
  }
}
