.comment-container {
  border: 1.25px solid #d0d5dd;
  box-shadow: 0px 1.25px 2.5px rgba(16, 24, 40, 0.05);
  border-radius: 10.038px;
  padding: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  margin-bottom: 20px;
  position: relative;

  @media (max-width: 750px) {
    flex-direction: column;
  }

  .inner-right-container {
    width: 140px;
    display: flex;
    align-items: center;

    @media (max-width: 750px) {
      width: 130px;
      position: absolute;
      top: 4%;
      right: 0;
      margin-left: 2rem;
      align-items: right;
    }
  }

  .inner-middle-container {
    width: 50%;
    max-width: 100%;

    .more-detail-container {
      padding: 0.3rem 0;
      width: 60%;

      @media (max-width: 750px) {
        width: 90%;
      }
    }

    @media (max-width: 750px) {
      margin-top: 2rem;
      margin-left: 1.1rem;
      width: 100%;
    }
  }
}

.userFeedback__userBio {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.userFeedback__verified {
  display: flex;
  align-items: center;
  gap: 0.2rem;
  font-size: 16px;
}

.userFeedback__positive {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #00a378cc;
}

.userFeedback__negative {
  font-weight: 400;
  font-size: 12px;
  line-height: 25px;
  color: #e03844cc;
}

.userFeedback__title {
  color: #7f56d9;
  font-size: 14px;
}

.userFeedback__text {
  font-size: 14px;
  font-weight: 400;
  color: #667085;
}

.userFeedback__btn {
  font-size: 14px;
  font-weight: 500;
  color: white;
}

/* button */
.o-profileButton {
  box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
  border-radius: 8px;
  padding: 10px;
}

.o-withIcon {
  display: flex;
  justify-items: center;
  gap: 0.5rem;
}

.o-primary-button {
  background: #7f56d9;
  border: 1px solid #7f56d9;
  color: white;
}
