.feedbackTab {
  display: flex;
  flex-direction: column;
  position: relative;
  word-break: break-all;
}

.feedbackTab__blocTabs {
  display: flex;
  justify-content: space-between;
}

.feedbackTab__Tabs {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: none;
  padding: 15px;
  position: relative;
  text-align: center;
}

.feedbackTab__activeTabs {
  background: white;
  border-bottom: 1px solid transparent;
  border: 0;
  color: #7f56d9;
}

.feedbackTab__contentTabs {
  flex-grow: 1;
}

.feedbackTab__content {
  background: white;
  display: none;
  height: 100%;
  padding: 20px;
  width: 100%;

  @media (max-width: 400px) {
    padding: 5px 0px;
  }
}

.feedbackTab__activeContent {
  display: block;
}

.feedbackTab__btn {
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
}

/* button */
.o-withIcon {
  display: flex;
  justify-items: center;
  gap: 0.5rem;
}
