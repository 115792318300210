@import "colors";

.homepageContainerDownload {
  display: flex;
  // background-color: #202c3d;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    padding: 6rem 0;

    @media (max-width: 500px) {
      width: 95%;
    }

    .beginStyle {
      width: 100%;
      margin: auto;
      display: flex;

      justify-content: space-between;
      align-items: center;
      color: #fff;

      @media (max-width: 1000px) {
        justify-content: center;
        flex-wrap: wrap;
        width: 100%;
      }

      .containerLeft {
        max-width: 100%;

        @media (max-width: 1000px) {
          width: 80%;
        }

        @media (max-width: 400px) {
          margin: auto;
          text-align: center; // Center the content
        }

        .imageContainer {
          width: 445.54px;
          height: 562.99px;

          @media (max-width: 1000px) {
            margin-top: 4rem;
            height: 562.99px;
          }
          @media (max-width: 600px) {
            padding: 0;
            margin: 0;
            justify-content: center;
            width: 100%;
          }
        }

        // @media (max-width: 1000px) {
        //   width: 80%;
        // }
      }

      .containerRight {
        width: 491px;

        .title {
          font-weight: 700;
          font-size: 3rem;
          line-height: 49px;
          text-align: left;
          letter-spacing: -0.08rem;
          margin: 0 auto 0;
          color: #101828;

          @media (max-width: 500px) {
            font-size: 3.5rem;
            width: 100%;
            // margin-bottom: 1rem;
            padding-left: 2rem;
            line-height: 40px;
          }
        }

        .tag {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 35px;
          text-align: left;
          width: 100%;
          margin: auto;
          color: #101828b2;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            padding-left: 2rem;
            line-height: 25px;
          }
        }

        .scanContainer {
          margin: 2rem auto 2rem;
          display: flex;
          gap: 30px;
          align-items: center;

          .scanImageContainer {
            border-radius: 10px;
            border: 1px solid #eaecef;
            padding: 10px 20px;
          }

          .imgStyle {
            height: 150px;
            width: 150px;
          }

          .titleSty {
            font-weight: 400;
            font-size: 1.4rem;
            line-height: 35px;
            text-align: left;
            width: 100%;
            margin: auto;
            color: #101828b2;
          }
          .descSty {
            font-weight: 700;
            font-size: 1.8rem;
            line-height: 49px;
            text-align: left;
            letter-spacing: -0.08rem;
            margin: 0 auto 0;
            color: #101828;
          }
        }

        .signUpContainer {
          flex-direction: row;
          display: flex;
          margin: 2rem auto 4rem;
          text-align: left;
          // gap: 20px;

          .imageDownloadStyle {
            // margin-left: 4rem;
            margin-right: 2rem;
          }

          @media (max-width: 1000px) {
            margin: auto;
            .imageDownloadStyle {
              margin-left: 1rem;
            }
          }
          @media (max-width: 800px) {
            margin: 2rem auto 4rem;
            .imageDownloadStyle {
              margin-left: 1rem;
            }
          }
          @media (max-width: 500px) {
            padding-left: 2rem;
            .imageDownloadStyle {
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }
}
