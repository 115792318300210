.__review__comment__wrapper {
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-top: 20px;
  width: 100%;
  @media screen and (max-width: 730px) {
    width: 100%;
    align-items: center;
  }

  .__reviewCommentWrapper {
    width: 90%;
    margin: 0 auto;
    @media screen and (max-width: 730px) {
      width: 100%;
    }
    .__reviewComment__inputWrapper {
      background-color: #fffefe;

      width: 100%;

      .__reviewComment__input {
        width: 100%;
        border-radius: 7px;
        border: none;
        padding: 15px;
        outline: none;
        border: 1px solid rgba(219, 219, 219, 0.758);

        &:focus {
          border: 1px solid rgba(158, 156, 156, 0.788);
        }
      }
    }
  }
  .__cmt__wrapper {
    width: 50%;
    display: flex;
    justify-content: center;
    margin-top: 20px;
    @media screen and (max-width: 730px) {
      width: 100%;
    }

    .__comment__button {
      //width: 80%;
      outline: none;
      border: none;
      height: 40px;
      display: flex;
      padding: 0 10px;
      justify-content: center;
      align-items: center;
      border-radius: 7px;
      background-color: #7f56d9;
      font-size: 12px;
      color: white;

      &.__small__button {
        width: 110px;
      }

      &:disabled {
        background-color: rgb(245, 245, 245);
        color: #344054;
      }
    }
  }
}
