.overview-MainTopSection {
  width: 100%;

  .overview-MainTopSectionTopLayout {
    width: 100%;
  }

  .overview-MainTopSectionLowerLayout {
    width: 100%;
  }
}
