.table__wrapper {
  min-height: 500px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  margin-top: 35px;
  overflow-x: scroll;
  &.new_wrapper_table {
    @media screen and (min-width: 1000px) {
      overflow-x: hidden;
    }
  }
  &::-webkit-scrollbar {
    height: 0px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 1px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #7a879c;
  }

  .not_found_item {
    // width: 100%;
    // height: 100%;
    // border: 1px solid red;
  }
  .__notFound__wrapperr {
    width: 100%;
    height: 500px;
    display: flex;
    justify-content: center;
    align-items: center;
    .__theNotFound {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 150px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  table.tablew {
    border-collapse: collapse;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    margin-bottom: 30px;

    .__notFound__wrapperr {
      transform: translate(120%, 50%);
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;

      .__theNotFound {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        img {
          width: 150px;
        }
        span {
          font-size: 14px;
          font-weight: 500;
        }
      }
    }

    .newTable__thead {
      background-color: #f6f9fa;
      .newTable__tr {
        height: 44px;
        margin-bottom: 20px;
        .newTable__thh {
          position: relative;
          // padding: 12px 0;
          padding-left: 10px;
          min-width: 50px;
          text-align: left !important;
          font-size: 13px;
          font-weight: 500;
          color: #667085;

          &.__min__100 {
            min-width: 100px;
          }
        }
      }
    }
    tbody.newTable__tbody {
      tr {
        border-bottom: 1px solid #eaecf0;
        td.tableItemm {
          padding: 22px 0;
          .transactionTableArea__coinWrapper {
            display: flex;
            align-items: center;
            padding-left: 15px;
            .transactionTableArea__coinImage {
              width: 30px;
              margin-right: 10px;
            }
            .transactionTableArea__coin {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .transactionTableArea__typeWrapper {
            display: flex;
            align-items: center;
            padding-left: 10px;
            .transactionTableArea__type {
              font-size: 14px;
              color: gray;
            }
          }

          .transactionTableArea__walletWrappers {
            display: flex;
            align-items: center;
            padding-left: 10px;
            .transactionTableArea__wallet {
              font-size: 14px;
              font-weight: 500;
              &.__countParty {
                color: #9381ff;
                text-decoration: underline;
                font-size: 13px;
                cursor: pointer;
              }
            }
          }

          .transactionTableArea__currencyWrappers {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            .transactionTableArea__wallet {
              font-size: 14px;
              font-weight: 500;
            }
          }

          .transactionTableArea__amountWrappers {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            .transactionTableArea__amount {
              font-size: 14px;
              font-weight: 500;
            }
          }
          .transactionTableArea__statusWrappers {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;

            .transactionTableArea__status {
              padding: 5px 10px;
              border-radius: 4px;
              font-weight: 500;
              font-size: 14px;
              &.__complete {
                color: #027a48;
              }
              &.__pending {
                color: #193970;
              }
              &.__cancelled {
                color: #b42318;
              }
            }
          }
          .transactionTableArea__viewMoreWrappers {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            .transactionTableArea__viewMore {
              background-color: #7f56d9;
              width: 125px;
              height: 32px;
              display: flex;
              justify-content: center;
              align-items: center;
              border-radius: 5px;
              padding: 10px 12px;
              font-size: 14px;
              color: white;
              cursor: pointer;
            }
          }
          .__tradeorder__status__wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            padding-left: 10px;
            .__theStatusText {
              color: #6941c6;
              font-weight: 500;
              font-size: 14px;

              cursor: pointer;
              &.__inactive {
                color: #b42318;
                margin-right: 10px;
              }
              &.__active {
                color: #00a478;
                margin-right: 10px;
              }
            }
          }

          .__trade__order_pending {
            margin-left: 10px;
            .__pending__id {
              display: flex;
              font-size: 12px;
              font-weight: 500;
            }
            .__pending__datetime {
              margin-top: 5px;
              font-size: 12px;
              color: #344054;
            }
          }

          .__trade__status {
            width: 90px;
            border-radius: 20px;
            height: 30px;
            background-color: #f3ba2f1a;
            display: flex;
            margin-left: 10px;
            align-items: center;
            justify-content: center;

            @media screen and (max-width: 730px) {
              width: 100px;
              height: 30px;
            }

            .__status__inner {
              width: 90%;
              height: 80%;
              background-color: #f3ba2f;
              border-radius: 20px;
              font-size: 13px;
              color: white;
              display: flex;
              justify-content: center;
              align-items: center;
              @media screen and (max-width: 730px) {
                font-size: 12px;
              }
            }
          }

          .__seller__wrapper {
            display: flex;
            align-items: center;
            min-width: 180px;
            padding-left: 10px;

            .__seller__image {
              position: relative;

              width: 40px;
              height: 40px;
              border-radius: 50%;
              background-repeat: no-repeat;
              background-size: cover;
              margin-right: 8px;

              .__seller__notifier {
                position: absolute;
                background-color: #dbdbdb;
                width: 10px;
                height: 10px;
                border-radius: 50%;
                border: 1px solid whitesmoke;
                bottom: 0;
                right: 0;

                &.online {
                  background-color: #59c14c;
                }
                &.away {
                  background-color: #f8e3b2;
                }
              }
            }

            .__seller__Initials {
              border-radius: 50%;
              height: 35px;
              width: 35px;
              background-color: #f9f5ff;
              color: #7f56d9;
              justify-content: center;
              align-items: center;
              display: flex;
              font-size: 14px;
              font-weight: bold;
              text-transform: uppercase;
              margin-right: 10px;
              cursor: pointer;

              .walletModal__downloadBarcodeDetailsCopy {
              }
            }

            .__seller__details {
              margin-right: 20px;

              .__sellerName {
                font-size: 12px;
                font-weight: 500;
              }

              .__sellerDetails {
                font-size: 12px;
                margin-top: 3px;
                color: #667085;
              }
            }
          }

          .__market__paymentMethod {
            font-size: 12px;
            color: #667085;
            padding-left: 10px;
          }

          .__market__coin {
            display: flex;
            // justify-content: center;
            align-items: center;
            padding-left: 10px;

            .__tokenSymbolImage {
              display: flex;
              align-items: center;
              justify-content: center;
              margin-right: 7px;
            }
            .__tokenSymbol {
              font-size: 12px;
              font-weight: 500;
            }
            .__tokenSymbolName {
              font-size: 12px;
              color: #667085;
              font-weight: 400;
            }
          }

          .__market__ratings {
            display: flex;
            align-items: center;
            padding-left: 10px;

            .__ratings__like {
              background-color: #ecfdf3;
              display: flex;
              align-items: center;
              padding: 8px 10px;
              border-radius: 10px;
              margin-right: 10px;
              .__rating {
                font-size: 13px;
                color: #027a48;
                margin-right: 4px;
              }
              .__icon {
                color: #027a48;
              }
            }

            .__ratings__dislike {
              background-color: #fef3f2;
              display: flex;
              align-items: center;
              padding: 8px 10px;
              border-radius: 10px;
              .__rating {
                font-size: 13px;
                color: #b42318;
                margin-right: 4px;
              }
              .__icon {
                color: #b42318;
              }
            }
          }

          .__market__limit {
            font-size: 12px;
            color: #667085;
            padding-left: 10px;
          }

          .__market__unitprice {
            display: flex;
            justify-content: flex-start;
            padding-left: 10px;
            align-items: center;
            .__theUnitPrice {
              color: #667085;
              font-size: 13px;
            }
          }
        }
      }
    }
  }
}
.loader__wrapper {
  min-height: 400px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.newTable__tbody > tr > td {
  padding: 25px 0;
}
.newTable__tbody > .myTable > td > span {
  /* padding: 15px 10px; */
}
.newTable__tbody > .myTable {
  border-bottom: 1px solid #eaecf0;
}

.ddp {
  height: 250px;
}

.newTable__thead > .newTable__tr > .newTable__th {
  position: relative;
  padding: 12px 0;
  padding-left: 10px;
  min-width: 158px;
  text-align: left !important;
}

.newTable__thead > .newTable__tr > .newTable__th__coinLook {
  position: relative;
  padding: 12px 0;
  padding-left: 10px;
  min-width: 98px;
  text-align: left !important;
  color: #667085;
}

.newTable__thead > .newTable__tr > .newTable__th__countryLook {
  position: relative;
  padding: 12px 0;
  padding-left: 10px;
  min-width: 120px;
  text-align: left !important;
  color: #667085;
}

.newTabledetails {
  width: 10px;
  // border: 1px solid red;
  margin: 0 auto;
  // display: flex;
  // align-items: center;
  // justify-content: center;
}

.notfound__wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 400px;
}
.notfound__wrapper__inner {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.__transaction__mobile__wrapper {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  flex-wrap: wrap;

  .__mobile__content__wrapper {
    background: white;
    border-radius: 3px;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1), 0px 1px 2px rgba(16, 24, 40, 0.06);
    padding: 12px;

    .__trade__title {
      font-size: 14px;
      font-weight: 500;
      margin: 10px 0;
      margin-bottom: 15px;
    }
    .__details {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 0;
      .__title {
        font-size: 13px;
        color: #667085;
      }
      .__value {
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;

        &.__counte {
          text-decoration: underline;
          color: #9381ff;
        }
        &.__complete {
          color: #027a48;
        }
        &.__pending {
          color: #193970;
        }
        &.__cancelled {
          color: #b42318;
        }

        &.__view {
          color: #7f56d9;
          padding: 6px 8px;
          border: 1px solid #7f56d9;
          border-radius: 5px;
          font-size: 12px;
        }
      }
    }
  }

  .__notFound__wrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .__theNotFound {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
}

.__mobile__market__table {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  flex-wrap: wrap;
  /* justify-content: space-around; */
  min-height: 500px;
  align-content: space-between;
  .__notFound__wrapper {
    width: 100%;
    height: 70vh;
    display: flex;
    justify-content: center;
    align-items: center;
    .__theNotFound {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 150px;
      }
      span {
        font-size: 14px;
        font-weight: 500;
      }
    }
  }

  .__mobile__market__content {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    padding: 10px;
    margin: 2px;

    .__mobile__market__top {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .__userUser {
        display: flex;
        align-items: center;
        .__userImage {
          width: 40px;
          height: 40px;
          border-radius: 50%;
          background-size: contain;
          background-repeat: no-repeat;
          position: relative;

          .__presence {
            border-radius: 50%;
            width: 9px;
            height: 9px;
            position: absolute;
            bottom: 0;
            right: -3px;
            // border: 1px solid red;
            &.__online {
              background-color: #59c14c;
            }
            &.__offline {
              background-color: #dbdbdb;
            }
            &.__yellow {
              background-color: #f8e3b2;
            }
          }
        }
        .__userName {
          font-size: 16px;
          font-weight: bold;
          margin: 0 10px;
        }
      }

      .__userOrderDetails {
        .__orderdetails {
          font-size: 13px;
          color: #667085;
        }
      }
    }
    .__unit__limit {
      padding: 20px 0 20px 30px;
      .__unitTitle {
        display: flex;
        margin: 12px 0;
        .__unitText {
          font-size: 14px;
          color: #667085;
        }
        .__unitValue {
          font-weight: bold;
          font-size: 13px;
          margin: 0 10px;
        }
      }
    }

    .__action__wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 4px 15px;
      .__paymentMethod {
        background-color: #fafafa;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        span {
          color: #f3ba2f;
          font-size: 12px;
        }
      }

      .__buySellAction {
        outline: none;
        border: none;
        border-radius: 5px;
        font-size: 14px;
        box-shadow: 0px 0.954236px 1.90847px rgba(16, 24, 40, 0.05);
        padding: 8px 15px;
        color: white;
        background-color: #7f56d9;
      }
    }
  }
}

.__alltradeorder__mobile__table {
  display: grid;
  column-gap: 10px;
  row-gap: 10px;
  grid-template-columns: repeat(auto-fit, minmax(290px, 1fr));
  flex-wrap: wrap;
  /* justify-content: space-around; */
  min-height: 500px;
  align-content: space-between;

  .__alltradeorder__content {
    background: #ffffff;
    box-shadow: 0px 1px 3px rgba(16, 24, 40, 0.1);
    border-radius: 8px;
    padding: 10px 20px;
    margin: 10px;

    .__alltradeorder__top {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .__thecoinWrapper {
        display: flex;
        align-items: center;
        .__thecoinImage {
          width: 30px;
          height: 30px;
          border-radius: 50%;
          background-size: cover;
          background-repeat: no-repeat;
        }
        .__coinNames {
          margin-left: 5px;
          p {
            font-size: 13px;
            font-weight: 500;
          }
          span {
            font-size: 11px;
            color: #667085;
          }
        }
      }
      .__trade__status {
        width: 50px;
        border-radius: 14px;
        height: 22px;
        background-color: #f3ba2f1a;
        display: flex;
        margin-left: 10px;
        align-items: center;
        justify-content: center;

        @media screen and (max-width: 730px) {
          width: 100px;
          height: 30px;
        }

        .__status__inner {
          width: 85%;
          height: 80%;
          background-color: #f3ba2f;
          border-radius: 14px;
          font-size: 12px;
          color: white;
          display: flex;
          justify-content: center;
          align-items: center;
          @media screen and (max-width: 730px) {
            font-size: 12px;
          }
        }
      }

      .__allTradeStatus {
        font-size: 12px;
        font-weight: 500;
        padding: 5px 8px;
        height: fit-content;
        border-radius: 10px;

        &.__active {
          color: #027a48;
        }
        &.__inactive {
          color: #b42318;
        }
      }
    }

    .__tradeOrder__details {
      padding: 20px 0 20px 20px;
      .__unitTitle {
        display: flex;
        margin: 12px 0;
        .__unitText {
          font-size: 14px;
          color: #667085;
        }
        .__unitValue {
          font-weight: bold;
          font-size: 14px;
          margin: 0 10px;
          color: #667085;
        }
      }
    }

    .__alltradeOrder__bottom {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .__tradePayment__country {
        display: flex;
        align-items: center;
        .__thePayment__method {
          font-size: 12px;
          font-weight: 500;
          padding: 2px 10px;
          border-right: 2px solid #344054;
          margin-right: 10px;

          &.__pending {
            border: none;
          }
        }

        .__theLoaction {
          display: flex;
          align-items: center;
          .__location__icon {
            // width: 14px;
            // height: 14px;
            border-radius: 50%;
            background-size: cover;
            background-repeat: no-repeat;
            margin-right: 10px;
          }
          .__location__name {
            font-size: 12px;
          }
        }
      }

      .__theAction {
        font-size: 14px;
        font-weight: 500;
        color: #6941c6;

        &.__thePending {
          color: white;
          background-color: #6941c6;
          padding: 8px 10px;
          border-radius: 5px;
          font-size: 12px;
        }
      }
    }
  }
}
