.searchDropDownWrapper__countrySystem {
  position: relative;
  width: 100%;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    margin-bottom: 5px;
  }

  .countryDropDownWrapperSectionDecorator {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: 1px solid #d0d5dd;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    padding: 10px;

    .countryDropDownWrapperSectionDecoratorDetails {
      display: flex;
      align-items: center;
      justify-content: space-between;

      .countryMetoInputLogoDetails {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }
    }

    .searchAbleIconDecorator {
      .searchIconBody {
        display: flex;
        align-items: center;
        justify-content: center;

        .searchIcon {
          color: #98a2b3;
          font-size: 18px;
        }
      }
    }
  }

  .searchDropDownWrapperSectionDecorator {
    display: flex;
    align-items: center;
    width: 100%;

    .searchDropDownWrapperSectionDecoratorDetails {
      margin-right: 5px;
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }
    }

    .searchAbleIconDecorator {
      .searchIconBody {
        display: flex;
        align-items: center;
        justify-content: center;

        .searchIcon {
          color: #98a2b3;
          font-size: 18px;
        }
      }
    }
  }

  .search-containerWrapper {
    width: 100%;
    margin-top: 10px;
    position: absolute;
    background: #fff;
    border-radius: 8px;
    padding: 5px 4px;
    z-index: 3;
    box-shadow: 0px 10.5414px 14.0552px -3.51381px rgba(16, 24, 40, 0.08),
      0px 3.51381px 5.27072px -1.75691px rgba(16, 24, 40, 0.03);

    .search-container {
      // width: 100%;
      background: #ffffff;
      border: 0.855949px solid #d0d5dd;
      box-shadow: 0px 0.855949px 1.7119px rgba(16, 24, 40, 0.05);
      border-radius: 6.84759px;
      display: flex;
      align-items: center;
      margin-bottom: 10px;

      .searchMetoInputBody {
        width: 100%;
        display: flex;
        align-items: center;
        border-radius: 6.84759px;
        padding: 0px 5px;

        .searchMetoInputLogo {
          padding-left: 4px;
          width: 9%;
          display: flex;
          align-items: center;
          justify-content: center;

          .searchMetoInputLogoIcons {
            font-size: 24px;
            color: #667085;
          }
        }

        .searchMetoInput {
          width: 90%;
          height: 40px;
          position: relative;
          padding: 0px 10px;
          border-radius: 8px;
          outline: none;
          border: none;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 21px;
          color: #667085;
          box-sizing: border-box;
          background-color: transparent;
        }
      }
    }

    // .textContainer {
    //   display: flex;
    //   align-items: center;

    //   .iconWrapper {
    //     display: flex;
    //     align-items: center;
    //     justify-content: center;
    //     margin-right: 5px;
    //   }

    //   .text {
    //     font-family: "Inter";
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 14.0552px;
    //     line-height: 21px;
    //     padding: 0px 5px;
    //   }
    // }

    // .dropDownCodeBody {
    //   padding: 0px 10px;

    //   .dropDownCode {
    //     font-family: "Inter";
    //     font-style: normal;
    //     font-weight: 400;
    //     font-size: 14px;
    //     line-height: 20px;
    //     color: #344054;
    //   }
    // }

    .dropdownItem {
      background-color: white;
      border: 1px solid hsl(0, 3%, 87%);
      height: 200px;
      z-index: 3;
      // position: absolute;
      width: 100%;
      padding: 10px 0px;
      border-radius: 5px;
      overflow-y: scroll;
      font-size: 1.6rem;
      cursor: pointer;
      // box-shadow: 0px 10.5414px 14.0552px -3.51381px rgba(16, 24, 40, 0.08),
      //   0px 3.51381px 5.27072px -1.75691px rgba(16, 24, 40, 0.03);

      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 5px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #98a2b3;
      }

      .ItemAlign {
        padding: 10px 11px;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: space-between;
        /* gap: 1rem; */
        border-bottom: 1px solid #f2f4f7;

        &:hover {
          transition: all 0.2s ease-in-out;
          opacity: 0.6;
          transform: scaleY(0.97) scaleX(0.99);
          background-color: #f8f9fa;
        }

        .dropDownIconBody {
          display: flex;
          align-items: center;
          justify-content: center;

          .dropDownIcon {
            color: #7f56d9;
            font-size: 15px;
            width: 25px;
          }
        }

        .textContainer {
          display: flex;
          align-items: center;

          .iconWrapper {
            display: flex;
            align-items: center;
            justify-content: center;
            margin-right: 5px;
          }

          .text {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 14.0552px;
            line-height: 21px;
            // padding: 0px 5px;
          }
        }
      }
    }
  }
}
