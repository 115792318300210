.tabs {
  list-style-type: none;
  display: flex;
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 11.2939px;
  line-height: 16px;
  color: #1d2939;
  background-color: #f0f2f5;
  padding: 5px 5px;
  border-radius: 1rem;
  /* gap: 1rem; */
}

.tabs > li {
  padding: 1.5rem 1.5rem;
  cursor: pointer;
  border-radius: 1rem;
  // border: 1px solid #cac9c9;
  font-size: 1.5rem;
}

.current {
  color: white;
  background-color: #53389e;
}

.content {
  margin-top: 2.3rem;
}

@media (max-width: 500px) {
  .tabs {
    margin: auto 2rem;
  }
}
@media (max-width: 400px) {
  .tabs {
    margin: auto 1rem;
  }
}

@media (max-width: 320px) {
  .tabs {
    margin: auto 0rem;
  }
  .tabs > li {
    padding: 1.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    font-size: 1.5rem;
  }
}
