.marketplaceLoader {
  width: 55vw;
  background-color: #7f56d9;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: 1300px) {
    width: 65vw;
  }

  @media screen and (max-width: 960px) {
    width: 80vw;
  }

  @media screen and (max-width: 820px) {
    width: 85vw;
  }

  @media screen and (max-width: 760px) {
    width: 90vw;
  }
  @media screen and (max-width: 690px) {
    width: 95vw;
  }
}
