.wallet__wrapper {
  width: 430px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;

  .withdrawPage__firstLayoutImageSection {
    padding: 10px 0px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .withdrawPage__firstLayoutImageSectionIconWrapper {
      width: 45px;
    }

    .withdrawPage__firstLayoutImageSectionIconBody {
      cursor: pointer;
      .withdrawPage__firstLayoutImageSectionIcon {
        color: rgba(16, 24, 40, 1);
        font-size: 24px;
      }
    }
  }

  .withdrawPage__SecondLayout {
    margin: 0px 0px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 28px;
      color: #101828;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      padding: 5px 0px;
    }

    .withdrawPage__firstLayoutCoinSectionSelector {
      margin: 0px;

      &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
        border-radius: 8px;
      }
    }
  }

  .withdrawPage__thirdLayout {
    margin: 2px 0px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #344054;
      padding: 5px 0px;
    }

    .withdrawPage__firstLayoutNetworkSectionSelector {
      /* padding: 0px 0px; */
      &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
        border-radius: 8px;
      }
    }

    .withdrawPage__firstLayoutNetworkSectionSelectorDetails {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      padding: 0px 0px;
      line-height: 3px;
      color: #667085;
    }
  }

  .withdrawPage__fouthLayout {
    margin: 10px 0px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      padding: 5px 0px;
    }

    .withdrawPage__fouthLayoutInputBody {
      width: 100%;
      display: flex;
      align-items: center;
      border: 1px solid #d0d5dd;
      justify-content: space-between;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      padding: 0px 5px;

      &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
        border-radius: 8px;
      }

      input {
        outline: none;
        background: #ffffff;
        border: none;
        border-radius: 8px;
        padding: 10px 14px;
        width: 80%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: rgba(102, 112, 133, 0.7);

        /* @media screen and (max-width: 415px) {
            width: 60%;
            border: 1px solid red;
          } */
      }

      .withdrawPage__fouthLayoutInputBodyFiller {
        /* width: 20%; */
        display: flex;
        align-items: center;
        justify-content: space-between;

        .withdrawPage__fouthLayoutInputBodyFillerMax {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: rgba(52, 208, 127, 1);
          padding: 0px 8px;
          border-right: 1px solid rgba(102, 112, 133, 0.7);
          cursor: pointer;
        }

        .withdrawPage__fouthLayoutInputBodyFillerCoin {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: rgba(102, 112, 133, 0.7);
          padding: 0px 8px;
        }
      }
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #667085;
    }

    .error {
      color: #f04438;
    }
  }

  .withdrawPage__fivethLayout {
    margin: 10px 0px;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #344054;
      padding: 5px 0px;
    }

    .withdrawPage__fivethLayoutInputBody {
      width: 100%;
      &:hover {
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #f4ebff;
        border-radius: 8px;
      }

      input {
        outline: none;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 9px 14px;
        width: 100%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 24px;
        color: rgba(102, 112, 133, 0.7);
      }
    }

    span {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 20px;
      color: #667085;
    }

    .error {
      color: #f04438;
    }
  }

  .withdrawPage__sixthLayout {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: space-between;

    .withdrawPage__sixthLayoutFirstLayout {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #344054;
      }

      .withdrawPage__sixthLayoutSecondLayoutAmount {
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 20px;
          color: #344054;
        }
      }
    }

    .withdrawPage__sixthLayoutSecondLayout {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 13px;
        line-height: 20px;
        color: #344054;
      }

      .withdrawPage__sixthLayoutSecondLayoutTotalAmount {
        padding: 5px 0px;
        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: #667085;
        }
      }
    }

    .withdrawPage__sixthLayoutSecondLayoutTotalAmountBody {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
      }

      .withdrawPage__sixthLayoutSecondLayoutTotalAmountTotalAmount {
        padding: 5px 0px;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }
    }
  }

  .withdrawPage__eighthLayout {
    padding: 10px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .withdrawPage__eighthLayoutFirstLayout {
      display: flex;
      align-items: center;

      .withdrawPage__eighthLayoutFirstLayoutIconBody {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 10px;

        .withdrawPage__eighthLayoutFirstLayoutIcon {
          color: #fcdf99;
          font-size: 14px;
        }
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        color: #667085;
      }
    }

    .withdrawPage__eighthLayoutSecondLayout {
      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 11px;
        line-height: 20px;
        color: #667085;
      }
    }
  }

  .withdrawPage__seventhLayout {
    width: 100%;
    margin: 40px 0px 10px 0px;
    button {
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      width: 100%;
      padding: 0px 18px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 8px;
      height: 40px;
      color: #fff;
      background-color: #53389e;
      cursor: pointer;

      &:disabled {
        background-color: rgb(245, 245, 245);
        color: #344054;
      }

      // &:hover {
      //   background-color: #6049a1;
      //   color: #fff;
      //   border: 1px solid #53389e;
      // }
    }
  }
}

@media screen and (max-width: 500px) {
  .wallet__wrapper {
    width: 90%;
  }
}
