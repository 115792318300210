.depositPage__Wrapper {
  width: 430px;
  padding: 20px;
  border-radius: 8px;
  background-color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .depositePage__firstLayout {
    width: 100%;
    // padding-right: 25px;
    //   border-right: ${({completeCode}) =>
    //     !completeCode ? "none" : "1px solid rgba(52, 64, 84, 0.7)"};

    .depositePage__firstLayoutImageSection {
      padding: 10px 0px;
      width: 45px;
    }

    .depositePage__SecondLayout {
      margin: 0px 0px;

      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 28px;
        color: #101828;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
        padding: 5px 0px;
      }

      .depositePage__firstLayoutCoinSectionSelector {
        margin: 0px;
      }
    }

    .depositePage__thirdLayout {
      margin: 2px 0px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        padding: 5px 0px;
      }

      // .depositePage__firstLayoutNetworkSectionSelector {
      //   /* padding: 0px 0px; */
      // }

      .depositePage__firstLayoutNetworkSectionSelectorDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        padding: 5px 0px;
        color: #667085;
      }
    }

    .depositePage__fourthLayout {
      padding: 10px 0px;
      .depositePage__fourthLayoutParagraph {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;
        color: #344054;
        padding: 5px 0px;
      }

      .walletModal__downloadBarcodeDetails {
        display: flex;
        align-items: center;
        justify-content: space-between;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        padding: 9px 5px;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #667085;
        }

        .walletModal__downloadBarcodeDetailsCopyBody {
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0px 5px;
          cursor: pointer;

          .walletModal__downloadBarcodeDetailsCopy {
            color: #000000;
            width: 15.5px;
            height: 17px;
          }
        }
      }

      .depositePage__fourthLayoutDetails {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 25px;
        color: #667085;
      }
    }

    .depositePage__fivethLayout {
      margin: 20px 0px 30px 0px;
      width: 100%;

      button {
        background: #53389e;
        border: 1px solid #53389e;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 0px;
        padding: 0px 14px;
        color: #ffffff;
        width: 100%;
        cursor: pointer;
        height: 40px;

        &:hover {
          background-color: #6445b8;
          border: 1px solid #6445b8;
        }

        &:disabled {
          background-color: hsla(259, 63%, 59%, 0.4);
          border: 1px solid hsla(259, 63%, 59%, 0.4);
        }
      }
    }

    .depositePage__sixthLayout {
      width: 100%;
      margin: 15px 0px 10px 0px;
      button {
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        width: 100%;
        padding: 0px 18px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 0px;
        color: #344054;
        cursor: pointer;
        height: 40px;

        &:hover {
          background-color: rgb(245, 245, 245);
        }
      }
    }
  }

  .depositePage__qRCodeWrapper {
    width: 50%;

    img {
      width: 100%;
    }
  }
}

@media screen and (max-width: 760px) {
  .depositPage__Wrapper {
    flex-direction: column;

    .depositePage__qRCodeWrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 500px) {
  .depositPage__Wrapper {
    width: 80%;
    flex-direction: column;

    .depositePage__qRCodeWrapper {
      width: 100%;
    }
  }
}

@media screen and (max-width: 400px) {
  .depositPage__Wrapper {
    width: 70%;
    flex-direction: column;

    .depositePage__qRCodeWrapper {
      width: 100%;
    }
  }
}
