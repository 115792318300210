.homepageContainer {
  display: flex;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    @media (max-width: 500px) {
      width: 95%;
    }

    .moreFeatures {
      margin: 2rem auto 1rem;
      .titleContainer {
        margin: 2rem auto 6rem;

        @media (max-width: 800px) {
          margin: 2rem auto 4rem;
        }
        @media (max-width: 500px) {
          margin: 0rem auto 1rem;
        }
        .tag {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #6941c6;
          margin: 0rem auto 1rem;
          text-transform: capitalize;
        }

        .title {
          font-weight: 600;
          font-size: 4.4rem;
          line-height: 52px;
          text-align: center;
          letter-spacing: -0.08rem;
          color: #101828;
          margin: 0 auto 1rem;
          @media (max-width: 500px) {
            font-size: 3.5rem;
            width: 100%;
            // margin-bottom: 1rem;
            margin: 0;
            // line-height: 45px;
            // color: red;
          }
        }

        .description {
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 35px;
          text-align: center;
          color: #667085;

          width: 100%;
          margin: auto;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            margin-top: 1rem;
          }
        }
      }

      .signUpContainer {
        margin: 6rem auto 8rem;
        text-align: center;

        @media (max-width: 800px) {
          margin: 2rem auto 4rem;
        }

        .buttonAdditionalStyle {
          width: 213px;
          border: none;
          border-radius: 8px;
          padding: 10px;
          height: 51px;
        }
      }
    }
  }

  .innerContainerFullWidth {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    background-color: #9381ff;
    padding: 2rem 0 8rem;

    .moreFeatures {
      margin: 2rem auto 1rem;

      .titleContainer {
        margin: 2rem auto 6rem;
        color: #fff;

        @media (max-width: 500px) {
          margin: 0rem auto;
        }

        .title {
          font-weight: 600;
          font-size: 4.4rem;
          line-height: 52px;
          text-align: center;
          letter-spacing: -0.08rem;
          margin: 0 auto 1rem;
          @media (max-width: 500px) {
            font-size: 3.5rem;
            width: 100%;
            margin-bottom: 1rem;
            line-height: 45px;
          }
        }

        .description {
          font-weight: 400;
          font-size: 1.8rem;
          line-height: 35px;
          text-align: center;

          // width: 55%;
          margin: auto;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            line-height: 30px;
          }
        }
      }

      .horizontalContainer {
        margin-top: 4rem;
        display: flex;
        gap: 3.3rem;
        justify-content: center;
        flex-wrap: wrap;
      }
    }
  }
}
