.claimnTitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px;
  color: #24224b;
  margin: 5px;
}
.claimSubtitle {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 18px;
  color: #24224b;
  margin: 0px 5px;
}

.claimContainer {
  background: #fefaeb;
  color: #6b3193 !important;
  border-radius: 10px;
  padding: 10px;
}
