.mobileSideMenuContainer {
  position: absolute;
  top: 0%;
  right: 0%;
  width: 100%;
  height: 100vh;
  background: hsl(0, 0%, 0%);
  background-color: #fff;
  border: 1px solid #eaecf0;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  transition: width 30s;
  transition: height 30s;

  .cancelContainer {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    margin-bottom: 5rem;
  }

  .listMenuIcon {
    padding: 1rem 1rem;
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 24px;
    color: #101828;
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;

    .tag {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #667085;
      margin-left: 3rem;
    }

    &:hover {
      background-color: hsla(221, 13%, 46%, 0.04);
      transform: scale(0.99);
      transition: slace 2s ease-in-out;
    }
  }

  @media (min-width: 1000px) {
    display: none;
  }
}
