.CustomeCheckBox {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 45px;
  height: 25px;
  border-radius: 18px;
}

.checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  width: 40px;
  height: 100%;
  z-index: 33;
}

.checker {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  background-color: #ede1fe;
  cursor: pointer;
  border-radius: 20px;
  overflow: hidden;
  transition: ease-in 0.5s;
}

.CustomeCheckBox .checkbox:checked ~ .checker {
  background-color: #53389e;
}

.checker:before {
  content: "";
  position: absolute;
  top: 2px;
  left: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
}

.CustomeCheckBox .checkbox:checked ~ .checker:before {
  transform: translateX(-50px);
}

.checker:after {
  content: "";
  position: absolute;
  top: 2px;
  right: 2px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #fff;
  transition: 0.5s;
  transform: translateX(50px);
}

.CustomeCheckBox .checkbox:checked ~ .checker:after {
  transform: translateX(0px);
}
