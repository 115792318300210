.notificationIconWrapper {
  .notificationIconWrapperIconBody {
    background-color: white;
    height: 100%;
    padding: 0px 15px;
    border-radius: 20px;
    position: relative;

    .notificationIconWrapperIconBody__notifier {
      position: absolute;
      top: 0;
      right: 0;
      width: 19px;
      height: 19px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #f6465d;
      border-radius: 50%;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 10px;
        // line-height: 20px;
        color: #fff;
      }
    }
  }
}
