.modal-container-profile-block {
  width: 350px;
  // height: 153px;
  background: #ffffff;
  border-radius: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 800px) {
    width: 400px;
    margin: auto 5rem;
  }

  .body {
    .top-section-modal-body {
      .img-error {
        display: flex;
        justify-content: center;
        margin: 1rem 0 2rem;
        height: 50px;
      }
      .title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 17px;
        text-align: center;
        color: #101828;
      }
      .tag {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.291rem;
        line-height: 20px;
        color: #667085;
        margin-top: 0.5rem;
        @media (max-width: 800px) {
          font-size: 1.15rem;
          white-space: nowrap;
        }
      }
    }

    .footer {
      display: flex;
      justify-content: center;
      gap: 2rem;
      margin: 2rem 0 1rem;

      .button-footer {
        width: 50%;
        height: 33px;
        background-color: transparent;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 15px;
      }

      .cancel-button {
        background: #ffffff;
        border: 0.609722px solid #d0d5dd;
        box-shadow: 0px 0.609722px 1.21944px rgba(16, 24, 40, 0.05);
        border-radius: 4.87778px;
        color: #344054;

        &:hover {
          border: 0.609722px solid hsla(217, 16%, 84%, 0.79);
          background-color: hsla(217, 16%, 84%, 0.79);
        }
      }

      .confirm-button {
        background: #d92d20;
        border: 0.609722px solid #d92d20;
        box-shadow: 0px 0.609722px 1.21944px rgba(16, 24, 40, 0.05);
        border-radius: 4.87778px;
        color: #ffffff;
        &:hover {
          border: 0.609722px solid hsla(4, 74%, 49%, 0.786);
          background: hsla(4, 74%, 49%, 0.786);
        }
      }
      .confirm-secondary {
        background: #7f56d9;
        border: 0.609722px solid #7f56d9;
        box-shadow: 0px 0.609722px 1.21944px rgba(16, 24, 40, 0.05);
        border-radius: 4.87778px;
        color: #ffffff;
        &:hover {
          border: 0.609722px solid hsla(259, 63%, 59%, 0.79);
          background: hsla(259, 63%, 59%, 0.79);
        }
      }
    }
  }
}
