.OverviewCards {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 15px 0px;
  padding: 10px 0px;

  .OverviewCardsFirstSection {
    display: flex;
    align-items: center;

    .OverviewCardsFirstSectionImageBody {
      width: 45px;
      margin-right: 10px;

      .OverviewCardsFirstSectionImage {
        width: 100%;
        object-fit: cover;
      }
    }

    .OverviewCardsFirstSectionDetails {
      h4 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.6rem;
        line-height: 20px;
        padding: 1px 0px;
        color: #101828;
      }

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 1.4rem;
        line-height: 20px;
        color: #667085;
      }
    }
  }

  .OverviewCardsSecondSection {
    margin: 0px 20px;

    .OverviewCardsSecondSectionDetails {
      button {
        box-sizing: border-box;
        background: #53389e;
        border: 0.776486px solid #53389e;
        box-shadow: 0px 0.776486px 1.55297px rgba(16, 24, 40, 0.05);
        border-radius: 6.21189px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1.4rem;
        line-height: 16px;
        color: #ffffff;
        height: 40px;
        width: 120px;
        // padding: 0px 20px;
        cursor: pointer;

        &:hover {
          background: #6746c3;
          border: 0.776486px solid #6746c3;
        }
      }

      .OverviewCardsSecondSectionDetailslinks {
        padding: 0;

        button {
          text-decoration: none;
          box-sizing: border-box;
          background: #53389e;
          border: 0.776486px solid #53389e;
          box-shadow: 0px 0.776486px 1.55297px rgba(16, 24, 40, 0.05);
          border-radius: 6.21189px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.4rem;
          line-height: 16px;
          color: #ffffff;
          height: 40px;
          width: 120px;
          // padding: 0px 20px;
          cursor: pointer;

          &:hover {
            background: #6746c3;
            border: 0.776486px solid #6746c3;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .OverviewCards {
    .OverviewCardsSecondSection {
      margin: 0px;
    }
  }
}
