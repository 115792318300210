.OverviewFundAccount {
  background: #fffdf9;
  border: 1px solid #fffdf9;
  box-shadow: 0px 2px 4px -2px rgba(249, 251, 255, 0.06);
  border-radius: 8px;
  padding: 25px;

  .OverviewFundAccountHeader {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 28px;
      color: #101828;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 1.4rem;
      line-height: 20px;
      color: #667085;
    }
  }

  .OverviewFundAccountHeaderCards {
    margin-top: 30px;
  }
}

@media screen and (max-width: 500px) {
  .OverviewFundAccount {
    background: unset;
    border: unset;
    padding: 0px;
    box-shadow: unset;
    border-top: 1px solid rgba(249, 251, 255, 0.06);
    border-top: 1px solid rgba(249, 251, 255, 0.06);
  }
}
