.OverViewOtherSection {
  width: 100%;
  margin-top: 35px;

  .OverViewOtherSectionFirstSection {
    width: 100%;

    @media screen and (max-width: 810px) {
      width: 48%;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 0px;
    }
  }

  .OverViewOtherSectionSecondSection {
    width: 100%;
    margin: 60px 0px;

    @media screen and (max-width: 810px) {
      width: 48%;
      margin: 0px;
    }

    @media screen and (max-width: 500px) {
      width: 100%;
      margin: 0px;
    }
  }

  @media screen and (max-width: 810px) {
    display: flex;
    flex-wrap: wrap;
    // align-items: baseline;
    justify-content: space-between;
  }
}
