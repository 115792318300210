.instantSellButton {
  background-color: #0ecb81; /* Green background color */
  color: white; /* White text color */
  padding: 7px 15px; /* Padding around the text */
  // border: 1px solid #ff5733; /* No border */
  border-radius: 30px; /* Rounded corners */
  cursor: pointer; /* Show pointer cursor on hover */
  font-size: 16px; /* Font size */
  transition: background-color 0.3s; /* Smooth background color transition on hover */
  &:hover {
    background-color: #0ecb83cc; /* Darker green background color on hover */
    // color: white;
  }
  font-size: 14px;
}
