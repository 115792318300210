.basic-opt-input-container {
  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: #344054;
  }
  .tag-otp-input {
    font-family: "Inter";
    font-style: normal;
    font-weight: 300;
    font-size: 1.2rem;
    line-height: 20px;
    color: #101828;
    margin: 0.2rem 0 1rem;
  }
  .opt-input-fields {
    width: 50px !important;
    height: 50px !important;
    // background: red;
    font-size: 4.5rem !important;
    color: #5d3cb2;
    border-radius: 8px;
    outline: none;
    border: 1px solid #d0d5dd;
    text-align: center;
    padding: 1rem 1rem !important;
    // padding: 10px;

    &::placeholder {
      // color: #d0d5dd;
      color: #d0d5dd !important;
    }
  }
}

.optInputContainer {
  // background-color: red;
  display: flex;
  width: 100%;
  justify-content: space-between;
  margin: 0.5rem 0 1.5rem;
  // color: #7f56d9 !important;
}

.optInputField {
  background: #fff;
  // color: #7f56d9 !important;

  input {
    width: 60px !important;
    height: 60px;
    font-size: 4rem;
    border: 1px solid #d0d5dd;
    outline: none;
    border-radius: 8px;
    color: #7f56d9 !important;

    &::placeholder {
      color: #d0d5dd !important;
    }
  }

  .error {
    border: 1px solid #f04438;
  }
}

.optInputFieldPhone {
  background: #fff;
  color: #7f56d9;

  input {
    width: 52px !important;
    height: 52px;
    font-size: 4rem;
    border: 1px solid #d0d5dd;
    outline: none;
    border-radius: 8px;
    color: #7f56d9 !important;

    &::placeholder {
      color: #d0d5dd !important;
    }
  }

  .error {
    border: 1px solid #f04438;
  }
}
