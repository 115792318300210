.transaction__wrapper {
  .transaction__titleWrapper {
    display: flex;
    justify-content: flex-start;
    align-items: flex-end;
    margin-bottom: 20px;
    .transaction__titleTitle {
      width: 80%;
    }
    .transaction__title {
      font-size: 23px;
      font-weight: 500;
      margin: 10px 0;
    }
    .transaction__titleDesc {
      font-size: 16px;
      font-weight: 400;
      color: #667085;
    }
  }
  .filterArea__Wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 25px 15px 25px 10px;
    // background-color: #f9fafb;

    @media screen and (max-width: 650px) {
      flex-direction: column;
      align-items: flex-start;
      background-color: transparent;
      padding: 25px 3px 25px 0px;
    }
    .filter__type {
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #f0f2f5;
      padding: 4px;
      border-radius: 7px;
      @media screen and (max-width: 650px) {
        justify-content: flex-start;
      }
      .filterArea__buySellWrapper {
        width: 102px;
        height: 35px;
        align-items: center;
        color: #7b7c7c;
        border-radius: 7px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        font-size: 14px;
        padding: 0 20px;
        cursor: pointer;
        margin-right: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 650px) {
          width: 120px;
          height: 35px;
          font-size: 13px;
        }

        &.filterArea__buySellWrapper__change {
          background-color: #53389e;
          color: white;
        }
      }
      .filterArea__dpWrapper {
        width: 102px;
        height: 36px;
        cursor: pointer;
        align-items: center;
        border-radius: 7px;
        color: #7b7c7c;
        padding: 0 20px;
        display: flex;
        justify-content: flex-start;
        position: relative;
        font-size: 14px;
        display: flex;
        justify-content: center;
        align-items: center;
        @media screen and (max-width: 650px) {
          width: auto;
          height: 35px;
          font-size: 13px;
        }
        svg {
          margin-left: 10px;
        }
        &.filterArea__buySellWrapper__change {
          background-color: #53389e;
          color: white;
        }
      }
    }

    .__date__filter {
      display: flex;
      align-items: center;

      .filterArea__Option {
        align-items: center;
        width: 220px;
        height: 40px;
        align-items: center;
        color: black;
        border-radius: 20px;
        display: flex;
        align-items: center;
        border: 1px solid #eff0f6;
        justify-content: flex-start;
        position: relative;
        font-size: 14px;
        margin-left: 15px;

        .staticDropDown__wrapper {
          display: flex;
          cursor: pointer;
          width: 100%;
          display: flex;
          padding: 10px 0;

          justify-content: space-between;
        }
        .staticDropDown__content__wrapper {
          position: absolute;
          z-index: 3;
          top: 120%;
          width: 100%;
          background-color: white;
          border-radius: 5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.157);
          max-height: 300px;
          overflow-y: scroll;
          &.filterWrapperStyle {
            width: 120%;
            right: 0;
          }
          &::-webkit-scrollbar {
            width: 5px;
          }
          &::-webkit-scrollbar-track {
            border-radius: 5px;
          }
          &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
            background-color: #98a2b3;
          }

          .staticDropDown__select__text {
            font-weight: 500;
            font-size: 14px;
            padding: 10px 20px;
            border-bottom: 1px solid #e7e7e7;
            width: 100%;
          }
          .staticDropDown__contentOptions {
            padding: 10px 12px;
            display: flex;
            width: 100%;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;
            &:hover {
              font-weight: bold;
              background-color: #f9fafb;
            }
            &.staticDropDown__contentOptionsCheckbox {
              display: flex;
              align-items: center;
              justify-content: flex-start;

              input {
                margin-right: 12px;
              }
            }
          }

          .__multiple__button__wrapper {
            width: 100%;
            display: flex;
            justify-content: space-between;
            padding: 1em;

            .filterStaticDropDown__cancel__button {
              background-color: white;
              box-shadow: 0px 4px 4px 0 #0000001e;
              padding: 10px 25px;
              border-radius: 5px;
              text-align: center;
              cursor: pointer;
            }
            .filterStaticDropDown__apply__button {
              background-color: #7f56d9;
              padding: 10px 20px;
              border-radius: 5px;
              text-align: center;
              color: white;
              outline: none;
              border: none;
              cursor: pointer;
            }
          }
        }
      }
    }

    .__mobile__innnerFilter {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-top: 20px;
      width: 100%;
      .__token__Select {
        width: 50%;
      }

      .__more__filters {
        display: flex;
        align-items: center;
        padding-right: 10px;
        .__refresh {
          margin-right: 15px;
        }

        .__filter {
        }
      }
    }
  }

  .__mainfilterWrapper {
    display: flex;
    justify-content: space-between;

    .__mainfilter__content {
      display: flex;
      justify-content: space-between;

      .__thefilter__item {
        position: relative;
        width: 220px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border: 1px solid #eff0f6;
        border-radius: 20px;
        font-size: 15px;
        margin-right: 15px;
        .newTable__th {
          width: 100%;
        }
      }
    }
  }
}
