.profile-header-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #eaecf0;
  // width: 90%;
  // margin: 0px auto;

  .topRightButtonEditStyle {
    width: 125px;
    @media (max-width: 400px) {
      margin-top: 10px;
    }
  }

  @media (max-width: 400px) {
    flex-direction: column;
    margin: 20px auto;
    justify-content: flex-start;
    align-items: flex-start;
  }

  .profile-left-container {
    display: flex;
    align-items: center;
    width: 83%;
    justify-content: space-between;
    flex-wrap: wrap;

    @media (max-width: 400px) {
      margin-top: 5px;
      width: 90%;
    }

    .textStyle {
      font-style: normal;
      font-weight: 500;
      font-size: 20px;
      line-height: 20px;
      color: #101828;
      margin-left: 5px;
      margin-top: 10px;
    }

    .box-one {
      display: flex;
    }

    .box-two {
      background: #d0d5dd26;
      display: flex;
      align-items: center;
      border: 1px solid #d0d5dd26;
      padding: 15px;
      gap: 20px;

      @media (max-width: 778px) {
        margin-top: 20px;
      }

      .box-child {
        display: flex;
        flex-direction: column;

        .positive-child {
          display: flex;
          justify-content: center;
          align-items: center;
          background: green;
          color: white;
          border-radius: 5px;
          margin-left: 2px;
          padding: 0 5px;
          font-weight: 500;
          font-size: 1.2rem;
        }

        .negative-child {
          display: flex;
          justify-content: center;
          align-items: center;
          background: red;
          color: white;
          border-radius: 5px;
          margin-left: 2px;
          padding: 0 5px;
          font-weight: 500;
          font-size: 1.2rem;
        }
      }
    }
  }

  .buttonStyle {
    background-color: #7f56d9;
    border: none;
    color: white;
    font-size: 12px;
    height: 35px;
    padding: 0px 10px;
    border-radius: 7px;

    @media (max-width: 700px) {
      padding: 0 6px;
    }
  }
}

// Second Row
.personal-info-container {
  display: flex;
  // justify-content: space-between;
  // width: 90%;
  margin: 30px auto 0;
  flex-direction: column;

  .textStyle {
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 25px;
    color: #344054;
    margin-bottom: 20px;
  }
  .card-container {
    padding: 30px;
    width: 100%;
    background: #ffffff;
    box-shadow: 0px 1.25475px 3.76426px rgba(16, 24, 40, 0.1), 0px 1.25475px 2.50951px rgba(16, 24, 40, 0.06);
    border-radius: 8px;

    .card-inner-container {
      display: flex;
      flex-direction: column;
      width: 100%;
      justify-content: space-between;

      .text {
        padding-bottom: 10px;
        width: 20%;
        font-size: 14px;
        border-bottom: 1.2478px solid #7f56d9;

        @media (max-width: 700px) {
          width: 100%;
          font-size: 1.3rem;
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .personal-info-container {
    flex-direction: column;
    margin-bottom: 20px;
    .card-container {
      padding: 30px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1.25475px 3.76426px rgba(16, 24, 40, 0.1), 0px 1.25475px 2.50951px rgba(16, 24, 40, 0.06);
      border-radius: 8px;
    }
  }
}

@media (max-width: 600px) {
  .personal-info-container {
    flex-direction: column;
    margin-top: 30px;
    // gap: 20px;

    .textStyle {
      margin-bottom: 20px;
    }
    .card-container {
      padding: 20px;
      width: 100%;
      background: #ffffff;
      box-shadow: 0px 1.25475px 3.76426px rgba(16, 24, 40, 0.1), 0px 1.25475px 2.50951px rgba(16, 24, 40, 0.06);
      border-radius: 8px;

      .card-inner-container {
        .text {
          padding-bottom: 10px;
          border-bottom: 1.2478px solid #7f56d9;
          width: 35%;
        }
      }
    }
  }
}

// working
.personal-info-boxs-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  // margin: 20px auto;
  // width: 90%;
  margin-top: 40px;
  padding-bottom: 20px;
  gap: 1rem;
  @media (max-width: 1320px) {
    // margin-top: 20px;
    justify-content: flex-start;
    // background-color: red;
  }
  @media (max-width: 1000px) {
    margin-top: 20px;
  }
  @media (max-width: 848px) {
    justify-content: space-between;
    gap: 0rem;
  }
  border-bottom: 1px solid #e4e7ec;

  .list-item-container {
    flex-basis: 200px;
    border: 1px solid rgba(16, 24, 40, 0.06);
    box-shadow: 0px 1.25475px 1.76426px rgba(16, 24, 40, 0.01), 0px 1.25475px 1.50951px rgba(16, 24, 40, 0.01);
    padding: 10px;
    height: auto;

    @media (min-width: 1000px) {
      margin-top: 20px;
    }
    @media (max-width: 1000px) {
      margin-top: 20px;
    }

    .titleStyle {
      font-weight: 500;
      font-size: 14px;
      line-height: 2.5rem;
      color: #344054;
      margin-bottom: 5px;
    }

    .leading-icon-trailing-container {
      display: flex;
      align-items: center;
      // justify-content: center;
      .leading-icon-trailing-title {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        color: #000000;
      }
    }
  }
}

.otherProfileReviews {
  .buttonContainer {
    display: flex;
    font-size: 1.4rem;
    justify-content: center;

    .buttonStyle {
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      font-size: 1.4rem;
      width: 17%;
      height: 40px;

      @media (max-width: 1280px) {
        width: 20%;
      }
      @media (max-width: 1190px) {
        width: 20%;
      }
      @media (max-width: 1000px) {
        width: 24%;
      }
      @media (max-width: 800px) {
        width: 30%;
      }
      @media (max-width: 600px) {
        width: 50%;
      }
    }
  }
}
