.postTrade__secondLayout {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid #d8d8d880;
  padding: 20px 30px;

  .postTrade__secondLayoutHeader {
    padding: 15px 15px 15px 0px;

    h4 {
      font-weight: 600;
      color: #292323;
      font-style: normal;
      font-weight: 600;
      font-size: 1.2rem;
      line-height: 16px;
    }
  }

  .postTrade__secondLayoutSearchCountry {
    margin: 0px 110px 0px 50px;
    width: 30%;

    .postTrade__secondLayoutSearchCountryBody {
      display: flex;
      align-items: flex-start;
      justify-content: center;
      flex-direction: column;

      label {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 1, 2rem;
        line-height: 15px;
        color: #344054;
      }

      .postTrade__firstLayoutSelectCryptoBodyContentDresser {
        display: flex;
        align-items: center;
        border: 1px solid #d0d5dd;
        border-radius: 8px;
        padding: 4px 9px;
        cursor: pointer;
        width: 100%;
        margin-bottom: 10px;
        box-shadow: 0px 0.855949px 1.7119px rgba(16, 24, 40, 0.05);
        background-color: #fafafa;
        margin-top: 5px;

        .postTrade__firstLayoutSelectCryptoBodyContentDresserIconBody {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        .postTrade__firstLayoutSelectCryptoBodyContentDresserContent {
          p {
            padding: 7px 5px;
            /* font-size: 16px;
                font-weight: 500; */
            color: #10182841;
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 1.36952rem;
            line-height: 21px;
          }
        }
      }
    }
  }

  .postTrade__secondLayoutTimer {
    width: 40%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;

    .postTrade__secondLayoutTimerFree {
      padding: 15px 0px;
      height: 15px;
      width: 20px;
    }

    .postTrade__secondLayoutTimerBodyMain {
      width: 100%;
      // display: flex;
      // align-items: center;
      // justify-content: space-between;

      .postTrade__secondLayoutTimerBody {
        p {
          color: #344054;
          padding: 5px 0px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 14px;

          // color: #292323;
          // padding: 5px 0px;
          // font-style: normal;
          // font-weight: 600;
          // font-size: 11.9833px;
          // line-height: 14px;
        }
      }

      .postTrade__secondLayoutTimerDropDown {
        width: 75%;

        .postTrade__firstLayoutSelectCryptoBodyContentDresser {
          display: flex;
          align-items: center;
          border: 1px solid #d0d5dd;
          border-radius: 8px;
          padding: 4px 6px;
          cursor: pointer;
          width: 100%;
          margin-bottom: 10px;
          box-shadow: 0px 0.855949px 1.7119px rgba(16, 24, 40, 0.05);
          background-color: #fafafa;

          // .postTrade__firstLayoutSelectCryptoBodyContentDresserIconBody {
          // }

          .postTrade__firstLayoutSelectCryptoBodyContentDresserContent {
            p {
              padding: 7px 5px;
              /* font-size: 16px;
                  font-weight: 500; */
              color: #10182841;
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 1.36952rem;
              line-height: 21px;
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 880px) {
  .postTrade__secondLayout {
    .postTrade__secondLayoutTimer {
      width: 35%;
    }
  }
}

@media screen and (max-width: 820px) {
  .postTrade__secondLayout {
    justify-content: space-between;
    padding: 20px 10px;

    .postTrade__secondLayoutHeader {
      width: 100%;
    }

    .postTrade__secondLayoutSearchCountry {
      margin: 0px;
      width: 45%;
    }

    .postTrade__secondLayoutTimer {
      width: 45%;

      .postTrade__secondLayoutTimerFree {
        height: 2px;
        padding: 4px 0px;
      }

      .postTrade__secondLayoutTimerBodyMain {
        // flex-direction: column;
        // align-items: flex-start;
        width: 100%;

        .postTrade__secondLayoutTimerDropDown {
          width: 100%;
        }
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .postTradeWrapper {
    .postTrade__Container {
      .postTrade__secondLayout {
        .postTrade__secondLayoutTimer {
          .postTrade__secondLayoutTimerFree {
            height: 20px;
            padding: 22px 0px;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .postTrade__secondLayout {
    flex-direction: column;
    align-items: flex-start;
    padding: 20px 5px;

    .postTrade__secondLayoutSearchCountry {
      margin: 0px;
      width: 100%;
    }

    .postTrade__secondLayoutTimer {
      width: 100%;

      .postTrade__secondLayoutTimerBodyMain {
        .postTrade__secondLayoutTimerDropDown {
          width: 100%;
        }
      }
    }
  }
}
