.overView-Wrapper {
  width: 100%;
  // margin: 0 auto;

  .OverviewMainTopSectionFirstLayoutHeader {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 3.23492rem;
      line-height: 40px;
      letter-spacing: -0.02em;
      color: #101828;
    }
  }

  .overView-mainWrapper {
    display: flex;
    justify-content: space-between;

    .overView-mainWrapperFirstLayout {
      width: 70%;
    }

    .overView-mainWrapperSecondLayout {
      width: 25%;
    }
  }
}

@media screen and (max-width: 820px) {
  .overView-Wrapper {
    .overView-mainWrapper {
      flex-direction: column;

      .overView-mainWrapperFirstLayout {
        width: 100%;
      }

      .overView-mainWrapperSecondLayout {
        width: 100%;
        padding: 10px;
      }
    }
  }
}

// @media screen and (max-width: 500px) {
//   .overView-mainWrapper {
//     flex-direction: column;

//     .overView-mainWrapperFirstLayout {
//       width: 100%;
//     }

//     .overView-mainWrapperSecondLayout {
//       width: 100%;
//       padding: 10px;
//     }
//   }
// }
