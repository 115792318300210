.cardList {
  width: 358px;
  height: 267px;
  border: 0px solid #e2e2e2;
  // box-shadow: 0px 6px 10px 5px rgba(0, 0, 0, 0.04);
  background: #f9f9f9;
  border-radius: 20px;
  padding: 2rem;
  margin-top: 2rem;

  .iconContainer {
    display: flex;
    justify-content: space-between;
    margin: 2rem 0rem;

    .icon {
      width: 30px;
    }

    .tag {
      font-weight: 500;
      font-size: 14px;
      line-height: 20px;
      color: #7f56d9;
      padding: 1rem;
      background: rgba(127, 86, 217, 0.1);
      border-radius: 16px;
    }
  }
  .title {
    font-weight: 600;
    font-size: 20px;
    line-height: 29px;
    color: #000000;
    margin-bottom: 1.4rem;
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 22px;
    color: #333333;
  }
}
