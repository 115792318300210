.wallet__firstLayoutWrapperButton {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .keywallet__firstLayoutWrapperButton {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 47%;

    .button {
      outline: none;
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 16px;
      // padding: 8.5px 12.9073px;
      padding: 0px 12.9073px;
      height: 40px;
      color: #344054;
      background-color: #fff;
      cursor: pointer;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      border: 1px solid #d0d5dd;
      width: 100%;

      &:hover {
        color: #ffffff;
        background-color: #53389e;
      }
    }

    // .deposit {
    //   border: 0.806704px solid #d0d5dd;
    //   border-radius: 6.45363px 0px 0px 6.45363px;
    // }

    // .withdraw {
    //   border: none;
    //   border-top: 0.806704px solid #d0d5dd;
    //   border-bottom: 0.806704px solid #d0d5dd;
    // }

    // .transactionHistroy {
    //   border: 0.806704px solid #d0d5dd;
    //   border-radius: 0px 6.45363px 6.45363px 0px;
    // }

    .selected {
      color: #ffffff;
      background-color: #53389e;
      background: #53389e;
      border: 1px solid #53389e;
    }
  }
}

@media screen and (max-width: 770px) {
  .wallet__firstLayoutWrapperButton {
    justify-content: space-between;
  }
}

@media screen and (max-width: 500px) {
  .wallet__firstLayoutWrapperButton {
    // align-items: flex-start;
    justify-content: flex-start;
    width: 100%;

    .keywallet__firstLayoutWrapperButton {
      .button {
        border: 0.806704px solid #d0d5dd;
        border-radius: 6.45363px;
        margin-right: 5px;
      }
    }
  }
}
