.__createTrade__buy__modal__wrapper {
  width: 410px;
  padding: 20px 24px;
  @media screen and (max-width: 730px) {
    width: 90vw;
  }

  .__warning__holder {
    width: 100%;
    display: flex;
    justify-content: center;
    .__warning__image {
      width: 40px;
      height: 40px;
    }
    .__confirm__payment {
      font-size: 17px;
      font-weight: 500;
    }
  }

  .__are__you__sure {
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
    text-align: center;
  }

  .__click_to__accept {
    font-size: 13px;
    margin: 15px 0;
  }
  .check__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    input {
    }

    .__terms {
      font-size: 13px;
      width: 94%;
      color: #344054;
      line-height: 21px;
    }
  }
  .__modal__button__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and (max-width: 510px) {
      flex-direction: column-reverse;
    }

    .__cancel {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      @media screen and (max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }
    }
    .__confirm {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      color: grey;
      cursor: not-allowed;
      @media screen and (max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }

      &:disabled {
      }

      &.accept {
        color: white;
        cursor: pointer;
        background-color: #7f56d9;
      }
    }
  }
}

.__createTrade__sell__modal__wrapper {
  width: 410px;
  padding: 20px 24px;

  @media screen and (max-width: 730px) {
    width: 90vw;
  }
  .__warning__holder {
    width: 100%;
    display: flex;
    justify-content: center;
    .__warning__image {
      width: 40px;
      height: 40px;
    }

    h5 {
      font-size: 17px;
    }
  }

  .__are__you__sure {
    font-size: 15px;
    font-weight: 500;
    margin: 15px 0;
    text-align: center;

    &.__dis {
      background-color: #fff6d9;
      padding: 10px 15px;
      font-size: 12px;
      font-weight: 500;
      border-radius: 5px;
    }
  }

  .__click_to__accept {
    font-size: 13px;
    margin: 15px 0;

    &.__dis {
      font-weight: 600;
    }
  }
  .check__wrapper {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;

    &.__dis {
      flex-direction: column;
    }
    .__textareawrapper {
      padding: 13px 13px 5px 13px;
      width: 100%;
      outline: none;
      border: 1px solid #d0d5dd;
      border-radius: 7px;
      textarea {
        border: none;
        outline: none;
        width: 100%;
      }
      span {
        text-align: right;
        font-size: 11px;
        display: block;
      }
    }

    .__warning__text {
      color: rgb(230, 36, 36);
      font-size: 11px;
      display: block;
      text-align: center;
      text-transform: capitalize;
      margin-top: 4px;
    }
    .__terms {
      font-size: 12px;
      width: 94%;
      color: #4b4f57;

      &.__dis {
        width: 100%;
        margin-bottom: 4px;
      }
    }
  }
  .__modal__button__wrapper {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    @media screen and (max-width: 510px) {
      flex-direction: column-reverse;
    }

    .__cancel {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      @media screen and (max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }
    }
    .__confirm {
      width: 45%;
      padding: 8px 20px;
      text-align: center;
      border-radius: 5px;
      background-color: white;
      border: 1px solid #d0d5dd;
      outline: none;
      color: grey;
      cursor: not-allowed;
      @media screen and (max-width: 510px) {
        width: 100%;
        margin-bottom: 15px;
        padding: 12px 20px;
      }

      &:disabled {
      }

      &.accept {
        color: white;
        cursor: pointer;
        background-color: #7f56d9;
      }
    }
  }
}
