.home-container {
  width: 100%;
  height: 100vh;
  background-color: #fff;
  display: flex;
  flex-direction: row;

  .side-bar {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;
    background-color: #53389e;
    color: white;
    width: 250px;
    transition: all 0.5s;
    position: relative;

    .top-section {
      display: flex;
      align-items: center;
      padding: 20px 0.5rem;
      .logo {
        font-size: 2.5rem;
      }

      .bars {
        display: flex;
        font-size: 25px;
        margin-left: 50px;
      }
    }

    .middle-section {
      flex: 1;

      .link {
        display: flex;
        color: #fff;
        padding: 10px 10px;
        margin: 15px auto;
        gap: 15px;
        align-items: center;
        transition: all 0.1s;
        white-space: nowrap;

        &:hover {
          background: #27125f;
          color: white;
          transition: all 0.2s;
        }

        &:hover > .tooltip {
          display: block;
          background: #27125f;
          color: white;
        }
        .icon,
        .link_text {
          font-size: 1.6rem;
        }

        .tooltip {
          display: none;
          position: absolute;
          left: 100%;
          margin-left: 10px;
          padding: 6px 15px;
          border-radius: 8px;
        }
      }
    }

    .active {
      background-color: #27125f;
      color: white;
    }

    .bottom {
      height: auto;
      width: 98%;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: flex-start;
      margin-bottom: 10px;
    }
  }

  .main-content {
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow-y: scroll;
    overflow-x: hidden;

    .top-bar {
      width: 100%;
      height: 70px;
      background-color: #d0d5dd26;
      display: flex;
      justify-content: space-between;
      align-items: center;

      .child-parent {
        flex-basis: 95%;
        display: flex;
        justify-content: space-between;
        margin: 0 auto;

        .left-container {
          height: 50px;
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          .__buy__crptoMennuWrapper {
            background: #ffffff;
            border-radius: 6px;
            //argin-right: 1rem;
            text-decoration: none;
            position: relative;
            border-radius: 20px;
            height: 40px;
            padding: 0 8px;
            display: flex;
            justify-content: center;
            align-items: center;

            .__buy__cryptoWrapper {
              display: flex;
              cursor: pointer;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              font-size: 1.23rem;
              font-weight: 600;
              color: #303030;
              line-height: 1.7rem;
              margin-right: 0.5rem;
              width: 110px;
              height: 40px;
              justify-content: center;

              @media (max-width: 1280px) {
                width: 80px;
                font-size: 1.1rem;
              }

              .__buy__icon {
                color: #98a2b3;
                width: 1.755rem;
                height: 1.755rem;
              }
            }

            .__buy__crypto__dropdown {
              position: absolute;
              left: -10%;
              top: 120%;
              width: 200%;
              height: auto;
              background: #ffffff;
              border: 1px solid #f2f4f7;
              border-radius: 8px;
              padding: 0.6rem 1.6rem;
              z-index: 99;

              .__the__buycrypto__link {
                display: flex;
                gap: 1rem;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid #f2f4f7;
                padding: 1rem 0;

                &:hover {
                  transition: all 0.2s ease-in-out;
                  opacity: 0.6;
                  transform: scale(0.97);
                  background-color: #f8f9fa;
                }

                &:last-child {
                  border-bottom: none;
                }

                .__dd__title {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 20px;
                  color: #344054;
                  text-decoration: none;
                  transition: all 0.2s ease-in-out;
                }

                .__dd__description {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.1rem;
                  line-height: 20px;
                  color: #667085;
                  text-decoration: none;
                  transition: all 0.2s ease-in-out;
                }
              }
            }
          }
        }

        .right-container {
          display: flex;
          flex-direction: row;
          align-items: center;
          gap: 10px;
          // height: 50px;
          // display: flex;
          // flex-direction: row;
          // align-items: center;
          // justify-content: space-between;
          // gap: 20px;

          .buttonStyleOnRightSide {
            width: 100px;
            height: 40px;
            font-size: 12px;
            background: #fff;
            margin-left: 10px;
            color: #000;
            box-shadow: none;
            border-radius: 15px;
            text-transform: capitalize;
            border: 1px solid #f8f9fa;
            display: flex;
            align-items: center;
            justify-content: center;

            &:hover {
              background: #fff;
              color: #000;
              box-shadow: none;
            }
            @media (max-width: 1280px) {
              width: 80px;
              // background-color: red;
            }
          }

          // background-color: red;
          .__balance__crptoMennuWrapper {
            padding: 8px 10px;
            background: #ffffff;
            border-radius: 6px;
            margin-right: 1rem;
            text-decoration: none;
            position: relative;
            border-radius: 20px;
            height: 40px;
            padding: 0 15px;
            display: flex;
            justify-content: center;
            align-items: center;
            .__balance__cryptoWrapper {
              display: flex;
              cursor: pointer;
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
              font-size: 1.255rem;
              font-weight: 600;
              color: #303030;
              line-height: 1.7rem;
              margin-right: 0.5rem;
              @media (max-width: 1280px) {
                width: 80px;
                // background-color: red;
              }

              .__balance__icon {
                color: #98a2b3;
                width: 1.755rem;
                height: 1.755rem;
              }
            }

            .__profile__image {
              width: 45px;
              height: 45px;
              border-radius: 50%;
              cursor: pointer;
            }

            .__balance__crypto__dropdown {
              position: absolute;
              border: 1px solid red;
              left: -10%;
              top: 120%;
              width: 170%;
              height: auto;
              background: #ffffff;
              border: 1px solid #f2f4f7;
              border-radius: 8px;
              padding: 0.6rem 1.6rem;
              z-index: 99;
              &::-webkit-scrollbar {
                width: 0px;
              }
              &::-webkit-scrollbar-track {
                border-radius: 0px;
              }
              &::-webkit-scrollbar-thumb {
                border-radius: 0px;
                /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
                background-color: #98a2b3;
              }

              .__the__buycrypto__link {
                display: flex;
                gap: 1rem;
                cursor: pointer;
                align-items: center;
                border-bottom: 1px solid #f2f4f7;
                padding: 1rem 0;

                &:hover {
                  transition: all 0.2s ease-in-out;
                  opacity: 0.6;
                  transform: scale(0.97);
                  background-color: #f8f9fa;
                }

                &:last-child {
                  border-bottom: none;
                }

                .__dd__title {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.4rem;
                  line-height: 20px;
                  color: #344054;
                  text-decoration: none;
                  transition: all 0.2s ease-in-out;
                }

                .__dd__description {
                  font-style: normal;
                  font-weight: 400;
                  font-size: 1.1rem;
                  line-height: 20px;
                  color: #667085;
                  text-decoration: none;
                  transition: all 0.2s ease-in-out;
                }
              }

              .__thenoty {
                .top-bar-style {
                  background-color: #f5f5f5;
                  display: flex;
                  justify-content: space-between;
                  align-items: center;
                  height: 50px;
                  padding: 0px 10px;
                  position: absolute;
                  top: 0%;
                  width: 100%;
                  border-top-left-radius: 7px;
                  border-top-right-radius: 7px;

                  .__viewall {
                    display: flex;
                    align-items: center;
                    font-size: 12px;
                    cursor: pointer;
                  }
                }
                .__noty__holder {
                  position: absolute;
                  top: 48px;
                  overflow-y: scroll;
                  max-height: 400px;
                  width: 100%;
                  background-color: white;
                  border-bottom-left-radius: 10px;
                  border-bottom-right-radius: 10px;
                  border: 1px solid #d0d5dd59;
                  &::-webkit-scrollbar {
                    width: 0px;
                  }
                  &::-webkit-scrollbar-track {
                    border-radius: 0px;
                  }
                  &::-webkit-scrollbar-thumb {
                    border-radius: 0px;
                    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
                    background-color: #98a2b3;
                  }

                  .__the__buycrypto__link__noty {
                    display: flex;
                    gap: 1rem;
                    cursor: pointer;
                    align-items: center;
                    border-bottom: 1px solid #f2f4f7;
                    padding: 1rem 0;

                    &:hover {
                      transition: all 0.2s ease-in-out;
                      opacity: 0.6;
                      transform: scale(0.97);
                      background-color: #f8f9fa;
                    }

                    &:last-child {
                      border-bottom: none;
                    }

                    .__dd__title {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.4rem;
                      line-height: 20px;
                      color: #344054;
                      text-decoration: none;
                      transition: all 0.2s ease-in-out;
                    }

                    .__dd__description {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.1rem;
                      line-height: 20px;
                      color: #667085;
                      text-decoration: none;
                      transition: all 0.2s ease-in-out;
                    }
                  }

                  .__no__notyWrapper {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    width: 100%;
                    height: 250px;
                  }
                  .__the__buycrypto__link__noty {
                    display: flex;
                    gap: 1rem;
                    cursor: pointer;
                    align-items: center;
                    border-bottom: 1px solid #f2f4f7;
                    padding: 1rem 0;

                    &:hover {
                      transition: all 0.2s ease-in-out;
                      opacity: 0.6;
                      transform: scale(0.97);
                      background-color: #f8f9fa;
                    }

                    &:last-child {
                      border-bottom: none;
                    }

                    .__dd__title {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.4rem;
                      line-height: 20px;
                      color: #344054;
                      text-decoration: none;
                      transition: all 0.2s ease-in-out;
                    }

                    .__dd__description {
                      font-style: normal;
                      font-weight: 400;
                      font-size: 1.1rem;
                      line-height: 20px;
                      color: #667085;
                      text-decoration: none;
                      transition: all 0.2s ease-in-out;
                    }
                  }
                }
              }
            }
          }
        }
      }
    }

    .pages {
      width: 100%;
      height: auto;
      display: flex;
      justify-content: center;

      .contents {
        // width: 98%;
        width: 95%;
        height: auto;
        margin-top: 40px;
        padding-bottom: 30px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .home-container {
    .side-bar {
      display: none;
    }

    .main-content {
      .top-bar {
        display: none;
      }

      .pages {
        .contents {
          width: 98%;
          margin-top: 80px;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home-container {
    .side-bar {
      display: none;
    }

    .main-content {
      .top-bar {
        display: none;
      }

      .pages {
        .contents {
          width: 96%;
          margin-top: 70px;
        }
      }
    }
  }
}

// old side bar menu
// .large-bar {
//   width: 250px;
//   height: 100%;
//   background-color: #53389e;
//   display: flex;
//   flex-direction: column;
//   justify-content: space-between;
//   align-items: center;
//   left: 0;
//   // transition: 350ms;
//   // transition: width all 0.5s;
//   transition: width 1s ease-in-out;
//   .top {
//     width: 94%;
//     margin: 0 10px;
//     height: 70%;
//     .icon {
//       width: 100%;
//       height: 40px;
//       display: flex;
//       justify-content: flex-start;
//       align-items: center;
//       border-radius: 5.12px;
//       margin: 20px 5px 0;

//       .title {
//         margin-left: 10px;
//         color: #ffffff;
//         font-size: 14px;
//       }
//     }
//     .icon:hover {
//       background-color: #eee3ff;
//     }
//   }

//   .bottom {
//     height: auto;
//     width: 98%;
//     display: flex;
//     flex-direction: row;
//     justify-content: space-between;
//     align-items: flex-start;
//     margin-bottom: 10px;
//   }
// }

.buttonStyleOnRightSideOn {
  width: max-content;
  // min-width: 150px;
  display: flex;
  align-items: center;
  gap: 10px;
  height: 40px;
  font-size: 12px;
  background: #fff;
  margin-left: 10px;
  color: #000;
  box-shadow: none;
  border-radius: 15px;
  text-transform: capitalize;
  border: 1px solid #f8f9fa;
  display: flex;
  align-items: center;
  justify-content: center;

  .buttonStyleOnRightSideOnTitleStyle {
    margin: auto 10px;
  }
  &:hover {
    background: #fff;
    color: #000;
    box-shadow: none;
  }

  @media (max-width: 1280) {
    width: 80px;
  }
}
