.wallet__secondLayout {
  background: #ffffff;
  // box-shadow: 0px 0px 10px 1px #efeeee;
  // border-radius: 9.00542px;
  // padding: 30px 20px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  flex-wrap: wrap;

  .wallet__secondLayoutArrayBody {
    width: 100%;
    padding: 16px 0px;
    // margin: 15px 10px;
    border-bottom: 1px solid #eaecf0;
    background: #f9fafb;
    // border: 1px solid red;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wallet__secondLayoutArrayBodyHeader {
      display: flex;
      // flex-direction: column;
      align-items: center;
      width: 22%;
      padding: 0px 24px;

      .wallet__secondLayoutArrayBodyHeaderCoinBody {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .wallet__secondLayoutArrayBodyHeaderDetails {
        padding: 0px 10px;
        // padding-top: 15px;

        h3 {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 16px;
          line-height: 31px;
          color: #101828;
        }

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 20px;
          color: #667085;
        }
      }

      @media screen and (max-width: 920px) {
        width: 16%;
      }

      @media screen and (max-width: 760px) {
        width: 18%;
      }
    }

    .wallet__secondLayoutArrayBodyContent {
      width: 15%;
      padding: 0px 24px;

      p {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 23px;
        color: #101828;
      }

      @media screen and (max-width: 920px) {
        width: 16%;
      }

      @media screen and (max-width: 760px) {
        width: 20%;
      }
    }

    .wallet__secondLayoutArrayBodyContent_Fiat {
      width: 18%;
      padding: 0px 24px;

      .top {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }

      .bottom {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #101828;
      }

      @media screen and (max-width: 920px) {
        width: 20%;
      }

      @media screen and (max-width: 760px) {
        width: 26%;
      }

      @media screen and (max-width: 620px) {
        display: none;
      }
    }

    .wallet__thirdLayoutDetails {
      cursor: pointer;
      padding: 0px 3px;
      display: none;

      .wallet__thirdLayoutDetailsIconBody {
        display: flex;
        align-items: center;
        justify-content: center;

        .wallet__thirdLayoutDetailsIcon {
          font-size: 24px;
          font-weight: 900;
          color: #101828;
        }
      }
    }

    .wallet__secondLayoutArrayBodyButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 30%;
      padding: 0px 24px;

      .wallet__secondLayoutArrayBodyButtonOne {
        width: 48%;
        button {
          width: 100%;
          box-shadow: 0px 1.12568px 2.25135px rgba(16, 24, 40, 0.05);
          border-radius: 6px;
          background-color: #fff;
          border: 1.12568px solid #d0d5dd;
          font-family: "Inter";
          font-style: normal;
          font-size: 14px;
          line-height: 10px;
          color: #344054;
          padding: 0px 30.0108px;
          outline: none;
          height: 40px;
          cursor: pointer;

          @media screen and (max-width: 920px) {
            padding: 0px;
          }
        }
      }

      .wallet__secondLayoutArrayBodyButtonTwo {
        width: 48%;
        // margin: 0px 10px;
        button {
          width: 100%;
          background: #53389e;
          border: none;
          box-shadow: 0px 1.12568px 2.25135px rgba(16, 24, 40, 0.05);
          border-radius: 6px;
          padding: 0px 30.0108px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 10px;
          height: 40px;
          cursor: pointer;
          color: #ffffff;
          outline: none;

          &:hover {
            background: #6644c5;
          }

          @media screen and (max-width: 920px) {
            padding: 0px;
          }
        }
      }

      @media screen and (max-width: 920px) {
        width: 32%;
      }

      @media screen and (max-width: 760px) {
        width: 16%;
      }
    }
  }
}

@media screen and (max-width: 970px) {
  .wallet__secondLayout {
    width: 100%;
  }
}

@media screen and (max-width: 760px) {
  .wallet__secondLayout {
    width: 100%;
    padding: 15px 0px;

    .wallet__secondLayoutArrayBody {
      .wallet__thirdLayoutDetails {
        display: block;
        width: 16%;
      }

      .wallet__secondLayoutArrayBodyButtons {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 620px) {
  .wallet__secondLayout {
    background-color: transparent;
    box-shadow: unset;
    padding: 0px;
    width: 100%;
    margin: 35px 0px;

    .wallet__secondLayoutArrayBody {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 20px 5px;
      margin: 10px 0px;
      background: #ffffff;
      box-shadow: 0px 0px 7px #efeeee;
      border-radius: 9.00542px;

      .wallet__secondLayoutArrayBodyHeader {
        width: 40%;

        .wallet__secondLayoutArrayBodyHeaderDetails {
          padding: 0px 10px;

          h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }

      .wallet__secondLayoutArrayBodyContent {
        width: 30%;
        display: flex;
        justify-content: flex-end;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;

          // span {
          //   display: none;
          // }
        }
      }

      .Similar {
        display: none;
      }

      .wallet__thirdLayoutDetails {
        display: block;
      }

      .wallet__secondLayoutArrayBodyButtons {
        display: none;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .wallet__secondLayout {
    background-color: transparent;
    box-shadow: unset;
    padding: 0px;
    width: 100%;
    margin: 35px 0px;

    .wallet__secondLayoutArrayBody {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 10px 5px;
      margin: 10px 0px;
      background: #ffffff;
      box-shadow: 0px 0px 7px #efeeee;
      border-radius: 9.00542px;

      .wallet__secondLayoutArrayBodyHeader {
        width: 40%;

        .wallet__secondLayoutArrayBodyHeaderDetails {
          padding: 0px 10px;

          h3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 22px;
          }

          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 22px;
          }
        }
      }

      .wallet__secondLayoutArrayBodyContent {
        width: 35%;
        display: flex;
        justify-content: flex-end;

        p {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 22px;

          // span {
          //   display: none;
          // }
        }
      }

      .Similar {
        display: none;
      }
    }
  }
}
