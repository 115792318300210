.base-button {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.34347rem;
  line-height: 19px;
  color: #ffffff;
  border: 0.959618px solid #7f56d9;
  box-shadow: 0px 0.959618px 1.91924px rgba(16, 24, 40, 0.05);
  // background-color: #5d3cb2;
  background: #7f56d9;
  // background: #53389e;

  &:hover {
    background-color: #5d3cb2;
  }

  &:disabled {
    // border: 1px solid rgba(93, 60, 178, 0.4);
    // background-color: rgba(196, 189, 215, 0.4);

    border: 1px solid rgba(93, 60, 178, 0.4);
    background-color: rgba(93, 60, 178, 0.4);
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.baseButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  border: none;
}

.primaryButton {
  height: 48px;
  background-color: #7f56d9;
  &:hover {
    background-color: hsla(259, 63%, 59%, 0.79);
  }
}

.primaryButtonLarge {
  height: 51px;
  background-color: #7f56d9;

  width: 15%;
  &:hover {
    background-color: hsla(259, 63%, 59%, 0.79);
  }

  &:disabled {
    border: 1px solid #7f56d9;
    background-color: hsla(259, 63%, 59%, 0.351);
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    // background-color: red;
    width: 30%;
  }
}
// width: "20%",
//   border: "none",
//   borderRadius: "8px",
//   padding: "10px",
//   height: "51px",

.ternaryButton {
  height: 48px;
  background-color: #7f56d9;
}

.ternaryButtonLarge {
  height: 51px;
  background-color: #7f56d9;
}
.styleSecurityButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 500;
  font-size: 1.34347rem;
  line-height: 19px;
  color: #ffffff;
  border: 0.959618px solid #7f56d9;
  box-shadow: 0px 0.959618px 1.91924px rgba(16, 24, 40, 0.05);
  // background-color: #5d3cb2;
  background: #7f56d9;
  border: none;
  box-shadow: none;
  width: 75px;
  border-radius: 8px;

  &:hover {
    background-color: #5d3cb2;
  }

  &:disabled {
    // border: 1px solid rgba(93, 60, 178, 0.4);
    // background-color: rgba(196, 189, 215, 0.4);
    border: 1px solid rgba(93, 60, 178, 0.4);
    background-color: rgba(93, 60, 178, 0.4);
    color: white;
    cursor: not-allowed;
    opacity: 0.5;
  }

  @media (max-width: 600px) {
    margin-top: 2rem;
    width: 80px;
    height: 40px;
  }
}
