.table_Wrapper_Main {
  border-top: 1px solid #eaecf0;
  border-bottom: 1px solid #eaecf0;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: space-between;

  .table_Wrapper {
    padding: 12px 24px;
    background: #ffffff;
    width: 20%;

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }

  .Coin {
    width: 22%;

    @media screen and (max-width: 920px) {
      width: 16%;
    }

    @media screen and (max-width: 760px) {
      width: 18%;
    }
  }

  .Similar {
    width: 15%;

    @media screen and (max-width: 920px) {
      width: 16%;
    }

    @media screen and (max-width: 760px) {
      width: 20%;
    }
  }

  .Fiat {
    width: 18%;

    @media screen and (max-width: 920px) {
      width: 20%;
    }

    @media screen and (max-width: 760px) {
      width: 26%;
    }
  }

  .Action {
    width: 30%;

    @media screen and (max-width: 920px) {
      width: 32%;
    }

    @media screen and (max-width: 760px) {
      width: 16%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }

  @media screen and (max-width: 920px) {
    display: none;
  }
}
