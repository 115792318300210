.__support__wrapper {
  width: 100%;
  margin: 5vh auto 10vh auto;
  padding: 0 12px;

  .__hero {
    background-color: #fafbff;
    height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin-bottom: 10vh;
    .gfg {
      width: 80%;
      margin: 0 auto;
      @media screen and (max-width: 900px) {
        width: 90%;
      }
      @media screen and (max-width: 700px) {
        width: 100%;
      }

      .__titleWraper {
        margin-bottom: 10vh;
        .__oyola {
          font-size: 15px;
          font-weight: 500;
          margin-bottom: 15px;
        }
        .__howcan {
          font-size: 45px;
          font-weight: 900;
        }
        .__howcan__desc {
          font-weight: 400;
          font-size: 16px;
          color: #667085;
          width: 70%;
          margin-top: 10px;
          line-height: 1.3;
        }
      }

      .__searchWrapper {
        display: flex;
        @media screen and (max-width: 900px) {
          width: 90%;
        }
        @media screen and (max-width: 700px) {
          width: 100%;
          flex-direction: column;
        }

        .__searhBorder {
          display: flex;
          align-items: center;
          border: 1px solid #e4e7ec;
          border-radius: 5px;
          padding: 14px 2px 14px 7px;
          margin-right: 25px;
          width: 50%;
          background-color: #fff;

          @media screen and (max-width: 700px) {
            width: 100%;

            margin-bottom: 20px;
          }

          .__icon {
            font-size: 13px;
            color: #667085;
            margin-right: 10px;
          }

          .__input {
            border: none;
            outline: none;
            &::placeholder {
              color: #667085;
              font-weight: 300;
              font-size: 13px;
            }
          }
        }

        .__searchbutton {
          background-color: #7f56d9;
          justify-content: center;
          align-items: center;
          display: flex;
          height: 42px;
          padding: 0 70px;
          border-radius: 5px;
          outline: none;
          border: none;
          color: white;
          font-size: 12px;
          font-weight: 300;
        }
      }
    }
  }

  .__bb {
    width: 80%;
    margin: 0 auto;
    @media screen and (max-width: 900px) {
      width: 90%;
    }
    @media screen and (max-width: 700px) {
      width: 100%;
    }

    .__faqcontainer {
      margin-bottom: 5vh;
      h4 {
        font-weight: bold;
        font-size: 18px;
        margin-bottom: 15px;
      }

      .__faqdesc {
        color: #667085;
        font-size: 13px;
      }
    }

    .__theFaqs {
      width: 77%;
      border-bottom: 1px solid #dee0e4;
      padding-bottom: 25px;

      margin-bottom: 15px;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      .__faqTops {
        width: 100%;
        display: flex;
        justify-content: space-between;
        p {
          font-weight: bold;
          font-size: 16px;
        }
        .__toggle {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 20px;
          height: 20px;
          border-radius: 50%;
          border: 1px solid #7f56d9;
          cursor: pointer;
          svg {
            color: #7f56d9;
          }
        }
      }

      .__content {
        padding-top: 10px;
        overflow-y: hidden;
        transition: height 3s;
        .__cc {
          font-size: 14px;
          color: #667085;
          line-height: 20px;
        }

        &.__close {
          height: 0;
        }
        &.__open {
          height: auto;
        }
      }
    }

    .__contact__wrapper {
      display: flex;
      width: 100%;

      @media screen and (max-width: 700px) {
        flex-wrap: wrap;
        padding-right: 0;
      }
      .__medium {
        margin-bottom: 25px;
        margin-right: 10%;
        svg {
          margin-bottom: 10px;
        }
        .__type {
          font-weight: 600;
          font-size: 14px;
          margin-bottom: 10px;
        }

        .__desc {
          color: #667085;
          font-size: 13px;
          margin-bottom: 10px;
        }
        .__link {
          color: #7f56d9;
          font-size: 13px;
          margin-bottom: 10px;
          font-weight: 500;
        }
      }
    }

    .__social__wrapper {
      display: flex;
      justify-content: space-between;
      padding-right: 10%;
      margin-top: 13vh;
      @media screen and (max-width: 900px) {
        margin-top: 10vh;
        padding-right: 5%;
      }

      @media screen and (max-width: 700px) {
        margin-top: 10vh;
        padding-right: 0;
      }
      .__item {
        width: 100px;

        @media screen and (max-width: 900px) {
          width: auto;
        }

        svg {
          color: #7f56d9;
          margin-bottom: 10px;
        }
        p {
          font-size: 12px;
          font-weight: 600;
          margin-bottom: 10px;
          color: black;
        }
      }
    }
  }
}
