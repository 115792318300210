.youtubeWrapper {
  width: 80%;
  height: 80%;

  .youtubeWrapperContainer {
    width: 100%;
    height: 100%;

    .youtubeWrapperContainerVideo {
      width: 100%;
      height: 100%;
    }
  }

  @media screen and (max-width: 920px) {
    width: 95%;
    height: 70%;
  }
}
