.walletDropdownWrapper {
  display: none;
  box-shadow: 0px 12px 16px -4px rgba(16, 24, 40, 0.08), 0px 4px 6px -2px rgba(16, 24, 40, 0.03);
  background: #ffffff;
  border: 1px solid #f2f4f7;
  border-radius: 8px 8px 0px 0px;
  max-height: 100vh;
  width: 100%;
  -webkit-animation: Anibottom 2s;
  animation: Anibottom;
  animation-duration: 0.4s;

  .dropDown__wrapper {
    padding: 10px 10px 20px 10px;

    .dropDown__wrapperSectionOne {
      width: 100%;
      padding: 10px 0px;

      .dropDown__wrapperSectionOneBody {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .dropDown__wrapperSectionOneBodyLayoutOne {
          p {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 20px;
            color: #101828;
          }
        }

        .dropDown__wrapperSectionOneLayoutTwo {
          display: flex;
          align-items: center;
          justify-content: center;
          padding: 5px;

          .dropDown__wrapperSectionOneLayoutTwoIcon {
            font-size: 20px;
            color: #101828;
          }
        }
      }
    }

    .dropDown__wrapperSectionTwo {
      width: 100%;
      padding: 2px 0px;
      display: flex;
      align-items: center;
      justify-content: center;

      button {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 20px;
        color: #101828;
        border: none;
        outline: none;
        background-color: #fff;
        width: 100%;
        text-align: left;
        padding: 8px 0px;
      }
    }
  }

  @-webkit-keyframes Anibottom {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }

  @keyframes Anibottom {
    from {
      bottom: -300px;
      opacity: 0;
    }
    to {
      bottom: 0;
      opacity: 1;
    }
  }
}

@media screen and (max-width: 760px) {
  .walletDropdownWrapper {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #fff;

    .dropDown__wrapper {
      width: 100%;
    }
  }
}
