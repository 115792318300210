.oopImageSection {
  //   width: 30%;

  p {
    font-family: "Inter";
    font-style: normal;
    font-weight: 700;
    font-size: 20rem;
    line-height: 20rem;
    text-align: center;
    color: #667085;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
