.baseButton {
  font-family: "Inter";
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  text-transform: capitalize;
  color: #ffffff;
  text-align: center;
  border-radius: 8px;
  border: none;
}

.primaryButton {
  height: 48px;
  background-color: #7f56d9;
  &:hover {
    background-color: hsla(259, 63%, 59%, 0.79);
  }
}

.primaryButtonLarge {
  height: 51px;
  background-color: #7f56d9;

  width: 15%;
  &:hover {
    background-color: hsla(259, 63%, 59%, 0.79);
  }

  &:disabled {
    border: 1px solid #7f56d9;
    background-color: hsla(259, 63%, 59%, 0.351);
    cursor: not-allowed;
  }

  @media (max-width: 500px) {
    // background-color: red;
    width: 30%;
  }
}

.primaryButtonLargeNew {
  height: 51px;
  background-color: #7f56d9;

  width: 213px;
  &:hover {
    background-color: hsla(259, 63%, 59%, 0.79);
  }

  &:disabled {
    border: 1px solid #7f56d9;
    background-color: hsla(259, 63%, 59%, 0.351);
    cursor: not-allowed;
  }

  // @media (max-width: 500px) {
  // background-color: red;
  // width: 30%;
  // }
}
// width: "20%",
//   border: "none",
//   borderRadius: "8px",
//   padding: "10px",
//   height: "51px",

.ternaryButton {
  height: 48px;
  background-color: #7f56d9;
}

.ternaryButtonLarge {
  height: 51px;
  background-color: #7f56d9;
}
