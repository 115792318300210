.NotificationWrapper {
  main {
    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 24.2011px;
      line-height: 31px;
      color: #101828;
    }

    p {
      font-size: 16px;
      font-weight: 400;
      line-height: 27px;
      color: #667085;
      font-family: "Inter";
      font-style: normal;
    }
  }

  .notification-container {
    width: 100%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tabs-container {
      width: 40%;
      background: #ffffff;
      border-radius: 10.0679px 10.0679px 10.0679px 10.0679px;
      margin-top: 20px;
      max-width: 100%;

      .button {
        width: 30%;
        height: 40px;
        text-transform: capitalize;
        background: #fff;
        box-shadow: none;
        border: 0.167798px solid #d8d8d8;
        border-radius: 0px;
        color: #344054;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 20px;

        &:hover {
          background: #fff;
        }
      }

      .selected {
        width: 30%;
        height: 40px;
        background: #53389e;
        order: none;
        outline: none;
        color: #fff;

        &:hover {
          background: #53389e;
        }
      }

      .all {
        border-radius: 5px 0px 0px 5px;
      }

      .message {
        width: 20%;
        border: none;
        border-top: 0.167798px solid #d8d8d8;
        border-bottom: 0.167798px solid #d8d8d8;
      }

      .messageSelected {
        width: 23%;
      }

      .trade {
        width: 20%;
        border: none;
        border-top: 0.167798px solid #d8d8d8;
        border-left: 0.167798px solid #d8d8d8;
        border-bottom: 0.167798px solid #d8d8d8;
      }

      .tradeSelected {
        width: 23%;
      }

      .transfer {
        width: 20%;
        border-radius: 0px 5px 5px 0px;
      }

      .transferSelected {
        width: 23%;
      }

      @media (max-width: 1280px) {
        width: 50%;
      }

      @media (max-width: 1050px) {
        width: 60%;
      }

      @media (max-width: 940px) {
        width: 55%;
      }

      @media (max-width: 840px) {
        width: 70%;
      }
      @media (max-width: 700px) {
        width: 96%;
      }

      @media (max-width: 400px) {
        width: 95%;
      }
    }

    .main-content {
      width: 100%;
      // margin-top: 20px;
      overflow-y: hidden;
      // background: #ffffff;
      // border: 0.967794px solid #eaecf0;
      // box-shadow: 0px 3.87118px 7.74235px -1.93559px rgba(16, 24, 40, 0.1),
      //   0px 1.93559px 3.87118px -1.93559px rgba(16, 24, 40, 0.06);
      // border-radius: 7.74235px;
      // margin-bottom: 40px;
    }
  }
}

.ReadUnReadWrapper {
  margin-top: 10px;
  // background: #f9fafb;
  border-radius: 5.10469px;
  display: flex;
  align-items: center;
  gap: 10px;

  .titleWrapper {
    background: #f9fafb;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    color: #344054;
    padding: 3px 0px;
  }

  .lengthWrapper {
    background: #f2f4f7;
    border-radius: 13.6125px;
    padding: 2px 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    color: #344054;
  }
}

.messageWrapper {
  width: 100%;
  margin-top: 20px;
  overflow-y: hidden;
  background: #ffffff;
  border: 0.967794px solid #eaecf0;
  box-shadow: 0px 3.87118px 7.74235px -1.93559px rgba(16, 24, 40, 0.1),
    0px 1.93559px 3.87118px -1.93559px rgba(16, 24, 40, 0.06);
  border-radius: 7.74235px;
  margin-bottom: 40px;
}
