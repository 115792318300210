.__dd__wrapper {
  width: 100%;
  display: flex;
  align-items: center;
  font-size: 1.4rem;
  background-color: white;
  position: relative;
  border: 1px solid #eff0f6;
  border-radius: 20px;
  margin-bottom: 15px;
  margin-top: 10px;
  margin-right: 0;
}

.staticDropDown__wrapper {
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 10px 10px;
  font-size: 14px;
  justify-content: space-between;
  cursor: pointer;
  .staticDropDown__icon {
    margin-left: 10px;
    margin-right: 10px;
  }
  span.__ff {
    display: flex;
    align-items: center;
    span.__tokenfileterstyle {
      display: flex;
      align-items: center;
      margin-left: 10px;

      img {
        width: 20px !important;
        height: 20px !important;
      }
    }
  }

  span.__thelabelitem {
    // width: 70%;
    margin-left: "10px";
    color: black;
    font-size: "14px";
    overflow: hidden;

    span {
      align-items: center;
      img {
        width: 20px;
        height: 20px;
      }
    }
  }
}

.staticDropDown__content__wrapper {
  position: absolute;
  z-index: 3;
  top: 120%;
  width: 100%;
  background-color: white;
  border-radius: 5px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  box-shadow: 0px 4px 8px 1px rgba(0, 0, 0, 0.157);
  max-height: 182px;
  overflow-y: scroll;
  &::-webkit-scrollbar {
    width: 0px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 0px;
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 0px;
    /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
    background-color: #98a2b3;
  }

  .__tokenfileterstyle {
    display: flex;
    align-items: center;

    img {
      margin-right: 10px;
    }
  }
  .staticDropDown__contentOptions {
    padding: 10px 12px;
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
    &:hover {
      font-weight: 500;
      background-color: #f9fafb;
    }
  }

  .staticDropDown__clearDiv__wrapper {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    padding: 10px 12px;
    .staticDropDown__clearDiv__text {
      color: #7f56d9;
      font-size: 13px;
      font-weight: 500;
      cursor: pointer;
    }
  }
}
