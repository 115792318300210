.faqWrapper {
  width: 100%;
  // text-align: center;

  .faqWrapperHeader {
    background: #fafbff;

    .faqWrapperHeaderWrapper {
      padding: 96px 0px 30px 0px;
      width: 80%;
      margin: 0 auto;
      border-bottom: 1px solid #eaecf0;

      .faqWrapperHeaderTitle {
        color: #101828;
        margin-bottom: 12px;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 4.4rem;
        line-height: 5.2rem;

        @media screen and (max-width: 500px) {
          font-size: 3rem;
          line-height: 4.2rem;
        }
      }

      .faqWrapperHeaderText {
        width: 49%;
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #667085;

        @media screen and (max-width: 950px) {
          width: 90%;
        }

        @media screen and (max-width: 500px) {
          width: 95%;
          font-size: 1.4rem;
        }
      }

      .faqWrapperHeaderSearch {
        margin-top: 67px;

        .faqWrapperHeaderSearchInput {
          font-family: "Inter";
          font-style: normal;
          font-weight: 300;
          font-size: 14px;
          line-height: 17px;
          color: #000000;
          background: #ffffff;
          border: 1px solid #aaa7a7;
          border-radius: 7.5px;
          padding: 15px 15px;
          outline: none;
          margin-right: 15px;
          width: 55%;

          @media screen and (max-width: 720px) {
            width: 50%;
          }

          @media screen and (max-width: 500px) {
            width: 95%;
          }
        }

        .faqWrapperHeaderButton {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff;
          background: #7f56d9;
          box-shadow: 0px 1px 2px rgba(0, 0, 0, 0.05);
          border-radius: 7.5px;
          padding: 15px 78.0156px;
          border: none;
          outline: none;

          @media screen and (max-width: 720px) {
            padding: 15px 50.0156px;
          }
        }

        @media screen and (max-width: 500px) {
          display: flex;
          align-items: flex-start;
          flex-direction: column;
        }
      }

      @media screen and (max-width: 1002px) {
        width: 95%;
      }

      @media screen and (max-width: 500px) {
        padding: 50px 0px 30px 0px;
      }
    }
  }

  .faqWrapperVideos {
    width: 80%;
    margin: 50px auto;

    .faqWrapperVideosWrapper {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .faqWrapperVideosWrapperNOtfound {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        .faqWrapperVideosWrapperHeaderContent {
          color: #101828;
          font-family: Inter, sans-serif;
          font-size: 30px;
          font-weight: 600;
          letter-spacing: -0.02em;
          line-height: 20px;
          margin-bottom: 20px;
          margin-top: 5px;
        }

        .faqWrapperVideosWrapperImage {
        }

        .faqWrapperVideosWrapperTextContent {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 24px;
          color: #667085;
        }
      }

      .faqWrapperVideosWrapperItem {
        width: 30%;
        margin: 40px 0px;

        @media screen and (max-width: 702px) {
          width: 45%;
        }

        @media screen and (max-width: 500px) {
          width: 90%;
        }

        @media screen and (max-width: 414px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 500px) {
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (max-width: 920px) {
      width: 95%;
    }
  }

  .faqWrapperFaq {
    width: 80%;
    margin: 76px auto;

    .faqWrapperFaqHeader {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 1.6rem;
      line-height: 24px;
      text-align: center;
      color: #7f56d9;
    }

    .faqWrapperFaqHeaderDetails {
      margin: 12px 0px;
    }

    .faqWrapperFaqSearch {
      width: 100%;
      margin: 40px 0px;

      .faqWrapperFaqSearchInput {
        width: 30%;
        margin: 0px auto;
        background: #ffffff;
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
        border-radius: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 0px 10px;

        .faqWrapperFaqSearchInputIconBody {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 10%;

          .faqWrapperFaqSearchInputIcon {
            font-size: 2.4rem;
            color: #667085;
          }
        }

        .faqWrapperFaqSearchInputField {
          width: 85%;
          padding: 12px 16px;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 24px;
          color: #667085;
          border: none;
          outline: none;
        }

        @media screen and (max-width: 720px) {
          width: 50%;
        }

        @media screen and (max-width: 500px) {
          width: 60%;
        }
      }
    }

    .faqWrapperFaqList {
      width: 100%;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;

      .faqWrapperFaqListWrapper {
        width: 31%;
        margin: 42px 0px;

        @media screen and (max-width: 800px) {
          width: 45%;
        }

        @media screen and (max-width: 500px) {
          width: 90%;
        }

        @media screen and (max-width: 414px) {
          width: 100%;
        }
      }

      @media screen and (max-width: 800px) {
        justify-content: center;
        align-items: center;
      }
    }

    @media screen and (max-width: 920px) {
      width: 95%;
    }

    @media screen and (max-width: 500px) {
      margin: 30px auto;
    }
  }

  .faqWrapperWrapper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

    .faqWrapperWrapperIconBody {
      position: absolute;
      top: 0;
      right: 0;
      margin: 30px;
      cursor: pointer;

      .faqWrapperWrapperIcon {
        font-size: 4.2rem;
        color: #fff;
      }
    }
  }
}
