.__oyolafeeswrapper {
  width: 80%;
  margin: 5vh auto 10vh auto;

  @media screen and (max-width: 900px) {
    padding: 0 12px;
    width: 95%;
  }
  @media screen and (max-width: 700px) {
    width: 100%;
  }

  .__titleWraper {
    margin-bottom: 10vh;
    .__oyola {
      margin-bottom: 15px;
      font-size: 22px;
      font-weight: bold;
    }
    .__howcan {
      font-weight: 500;
      font-size: 15px;
    }
  }

  .__faqcontainer {
    margin-bottom: 10vh;
    width: 80%;

    @media screen and (max-width: 700px) {
      width: 100%;
    }
    h4 {
      font-weight: bold;
      font-size: 18px;
      margin-bottom: 15px;
    }

    .__faqdesc {
      color: #667085;
      font-size: 13px;
      line-height: 22px;
    }
  }

  .__thebuttons {
    margin-bottom: 10vh;
    .__butButtonWrapper {
      display: flex;
      width: 60%;
      margin-bottom: 20px;
      justify-content: space-between;
      @media screen and (max-width: 700px) {
        width: 100%;
      }
      .__buy {
        background-color: #f9f5ff;
        padding: 14px 13px;
        border: 1px solid #d6bbfb;
        width: 45%;
        color: #53389e;
        font-size: 14px;
        font-weight: 500;
        border-radius: 5px;

        &.__nofees {
          border: 1px solid #eaecf0;
          background-color: transparent;
          color: #667085;
          font-weight: 400;
        }
      }
    }
  }

  .__theFeatures {
    display: grid;
    column-gap: 10px;
    row-gap: 10px;
    grid-template-columns: repeat(auto-fit, minmax(160px, 1fr));
    flex-wrap: wrap;
    .__butButtonHeader {
      .__item {
        background-color: #f9f5ff;
        padding: 14px 13px;
        border: 1px solid #d6bbfb;
        color: #53389e;
        font-size: 12px;
        border-radius: 5px;
        margin-bottom: 20px;
      }

      .__details {
        min-height: 130px;
        border-radius: 7px;
        padding: 10px 13px;
        font-size: 13px;
        line-height: 20px;
        border: 1px solid #eaecf0;
        color: #667085;
      }
    }
  }
}
