.OverviewVerifyAccount {
  background: #ffffff;
  border: 1px solid #f4f4f5;
  box-shadow: 0px 2px 4px -2px rgba(249, 251, 255, 0.06);
  border-radius: 8px;
  padding: 12px 25px;
}

@media screen and (max-width: 500px) {
  .OverviewVerifyAccount {
    padding: 0px;
    box-shadow: unset;
    border-top: 1px solid #f4f4f5;
    border-top: 1px solid #f4f4f5;
  }
}
