@import "colors";

.homepageContainer {
  display: flex;

  .innerContainerZeroFees {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    padding: 8rem 0;

    @media (max-width: 500px) {
      width: 95%;
      padding: 1rem 0;
    }

    .zeroFeesStyle {
      width: 100%;
      background-color: #f6fffc;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center;

      .containerLeft {
        max-width: 100%;
        .imageContainer {
          width: 445.54px;
          height: 530px;

          @media (max-width: 500px) {
            height: auto;
            width: 250px;
            // width: 100%;
          }
        }
      }

      .containerRight {
        width: 491px;
        margin: 2rem auto 0rem auto;

        .title {
          font-weight: 700;
          font-size: 4rem;
          line-height: 49px;
          text-align: left;
          letter-spacing: -0.08rem;
          color: #101828;
          margin: 0 auto 3rem;
          @media (max-width: 500px) {
            font-size: 2.4rem;
            width: 95%;
            margin-bottom: 0rem;
          }
        }

        .tag {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 35px;
          text-align: left;
          color: #667085;
          width: 100%;
          margin: auto;

          @media (max-width: 500px) {
            width: 95%;
            font-size: 1.4rem;
            line-height: 25px;
          }
        }

        .signUpContainer {
          margin: 2rem auto 4rem;
          text-align: left;

          @media (max-width: 800px) {
            margin: 2rem auto 4rem;
            @media (max-width: 500px) {
              margin-left: 1rem;
            }
          }

          .buttonAdditionalStyle {
            width: 133px;
            border: none;
            border-radius: 8px;
            padding: 10px;
            height: 51px;
          }
        }
      }
    }
  }
}
