.textOopSection {
  width: 30%;

  .title {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.6rem;
    line-height: 24px;
    color: #101828;
  }

  .details {
    font-family: "Inter";
    font-style: normal;
    font-weight: 400;
    font-size: 1.4rem;
    line-height: 20px;
    text-align: center;
    color: #667085;
    padding: 4px 0px;
  }

  @media screen and (max-width: 950px) {
    width: 50%;
  }

  @media screen and (max-width: 720px) {
    width: 70%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
