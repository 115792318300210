.__marketPlaceTitleWrapper {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;

  .__marketPlace__name {
    font-size: 23px;
    font-weight: 500;
    line-height: 43px;

    @media screen and (max-width: 650px) {
      font-size: 28px;
    }
  }

  .__marketPlace__paragraph {
    font-size: 17px;
    font-weight: 400;
    line-height: 27px;
    color: #667085;
    @media screen and (max-width: 650px) {
      font-size: 15px;
      line-height: 22px;
    }
  }
}
.__marketPlaceSwitchWrapper {
  width: 100%;
  display: flex;
  justify-content: space-between;
  margin: 10px 0;
  @media screen and (max-width: 650px) {
    padding: 0 10px;
  }

  .__theSwitch {
    // width: 150px;
    display: flex;
    align-items: center;
    background-color: #f0f2f5;
    height: auto;
    border-radius: 10px;
    padding: 3px 0;
    overflow: hidden;

    @media screen and (max-width: 700px) {
      border-radius: none;
      border: none;
      justify-content: space-between;
      overflow: auto;
      background-color: none;
    }

    .__marketBuy {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      font-size: 14px;
      border-radius: 10px;
      color: #000;
      cursor: pointer;

      &.buy {
        color: white;
        background-color: #53389e;
      }
      @media screen and (max-width: 700px) {
        border-radius: 10px;
      }
    }

    .__marketSell {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      font-size: 14px;
      border-radius: 10px;
      color: #000;
      cursor: pointer;

      &.sell {
        color: #fff;
        background-color: #53389e;
      }

      @media screen and (max-width: 700px) {
        border-radius: 10px;
        width: 70;
      }
    }
  }

  .__mobile__filterSpace {
    display: flex;
    align-items: center;
    padding-right: 10px;
    .__refresh {
      margin-right: 15px;
    }

    .__filter {
    }
  }
}

.__filterAreaWrapperStatus {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  // background-color: #f9fafb;
  margin: 20px 0;
  padding: 20px 15px 20px 15px;
  border-radius: 8px;

  .__updateButtonWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;

    .__marketFilterUpdateButton {
      height: 30px;
      width: 100px;

      color: #53389e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
      border-radius: 20px;
      cursor: pointer;
      border: 1px solid #53389e;
      svg {
        color: #53389e;
      }
      span {
        margin-left: 10px;
      }
    }
  }
}

.__filterAreaWrapper {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #f9fafb;
  margin: 20px 0;
  padding: 20px 15px 20px 15px;
  border-radius: 8px;

  .__selectcoinwrapper {
    width: 160px;
    margin-right: 15px;
    .__label {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
  }
  .__selectableWrapper {
    margin-right: 15px;
    .__label {
      font-size: 1.2rem;
      margin-bottom: 5px;
    }
    .___theamountandcurrency {
      display: flex;
      height: 40px;
      width: auto;
      border-radius: 20px;
      background-color: white;
      border: 1px solid #eff0f6;
      padding: 0px 20px;
      .__selectable__input {
        width: 115px;
        outline: none;
        border: none;
        display: flex;
        font-size: 14px;
        background-color: transparent;
      }
    }
  }

  .__theCoinWrapper {
    margin-right: 20px;
    width: 35%;
    border-radius: 20px;

    @media screen and (max-width: 1103px) {
      margin-bottom: 20px;
    }
    @media screen and (max-width: 850px) {
      width: 38%;
    }
  }
  .__marketFilterRight {
    margin-right: 20px;
    width: 43%;

    @media screen and (max-width: 1100px) {
      width: 46%;
    }
    @media screen and (max-width: 950px) {
      width: 50%;
    }
    @media screen and (max-width: 850px) {
      width: 55%;
    }
    .__selectableWrapper {
      border-radius: 6px;
      width: 90%;

      background-color: white;
      display: flex;
      flex-basis: fit-content;
      margin-bottom: 25px;

      .__selectableTitle {
        height: 100%;
        width: 40%;
        border-top-left-radius: 5px;
        border-bottom-left-radius: 5px;
        border-left: 1px solid #d0d5dd;
        padding-left: 12px;
        font-size: 14px;
        @media screen and (max-width: 950px) {
          font-size: 13px;
        }

        flex-basis: 2;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        color: white;
        background-color: #7b61ff;
      }
    }
    @media screen and (max-width: 734px) {
      margin-bottom: 20px;
    }
  }
  .__updateButtonWrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin: 15px 0px 0px 0px;
    // border: 1px solid red;

    .__marketFilterUpdateButton {
      height: 40px;
      width: 110px;

      color: #53389e;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 14px;
      border-radius: 20px;
      cursor: pointer;
      border: 1px solid #53389e;
      svg {
        color: #53389e;
      }
      span {
        margin-left: 10px;
      }
    }
  }

  .__theSwitch {
    // width: 150px;
    display: flex;
    align-items: center;
    background-color: #f0f2f5;
    height: auto;
    border-radius: 10px;
    padding: 3px 0;
    overflow: hidden;

    @media screen and (max-width: 700px) {
      border-radius: none;
      border: none;
      justify-content: space-between;
      overflow: auto;
      background-color: none;
    }

    .__marketBuy {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      font-size: 14px;
      border-radius: 10px;
      color: #000;
      cursor: pointer;

      &.buy {
        color: white;
        background-color: #53389e;
      }
      @media screen and (max-width: 700px) {
        border-radius: 10px;
      }
    }

    .__marketSell {
      width: 100px;
      display: flex;
      justify-content: center;
      align-items: center;
      height: 35px;
      font-size: 14px;
      border-radius: 10px;
      color: #000;
      cursor: pointer;

      &.sell {
        color: #fff;
        background-color: #53389e;
      }

      @media screen and (max-width: 700px) {
        border-radius: 10px;
        width: 70;
      }
    }
  }
}
.__button__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  width: 100%;
  // margin-top: 20vh;
  .__buy__buttonn {
    outline: none;
    border: none;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 42%;
    font-size: 16px;
    padding: 13px 0;
    background-color: #7f56d9;
    border-radius: 9px;
    color: white;
    cursor: pointer;

    &:disabled {
      cursor: not-allowed;
      background-color: #7f56d996;
    }
  }
  .__cancel__button {
    outline: none;
    border: none;
    display: flex;
    background-color: white;
    width: 42%;
    font-size: 16px;
    padding: 13px 0;
    border-radius: 9px;
    justify-content: center;
    align-items: center;
    border: 1px solid #d0d5dd;
  }
}
