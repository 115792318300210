.overview-container {
  width: 100%;
  height: auto;
  display: flex;
  flex-direction: column;
  align-items: center;

  .tabs-container {
    width: 28%;
    background: #ffffff;
    border-radius: 10.0679px 10.0679px 10.0679px 10.0679px;
    margin-top: 20px;
  }

  .main-content {
    width: 100%;
    min-height: 80vh;

    margin-top: 20px;
    overflow-y: hidden;
  }
}

@media (max-width: 900px) {
  .overview-container {
    .tabs-container {
      width: 50%;
    }
  }
}

@media (max-width: 400px) {
  .overview-container {
    .tabs-container {
      width: 80%;
    }
  }
}
