@import "colors";

.homepageContainerBegin {
  display: flex;
  background-color: #202c3d;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    padding: 6rem 0;

    @media (max-width: 500px) {
      width: 95%;
    }

    .beginStyle {
      width: 100%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      color: #fff;

      .containerLeft {
        max-width: 100%;
        @media (max-width: 400px) {
          margin: auto 0 0 2rem;
        }
        .imageContainer {
          // width: 480.54px;
          width: 445.54px;
          height: 100%;
          @media (max-width: 400px) {
            height: auto;
            width: 250px;
          }
          @media (max-width: 500px) {
            height: auto;
            width: 250px;
            // width: 100%;
          }
        }
      }

      .containerRight {
        width: 491px;
        // margin: 2rem auto 0rem auto;

        // @media (max-width: 1169px) {
        //   margin: 2rem auto 0rem;
        // }

        .title {
          font-weight: 700;
          font-size: 4rem;
          line-height: 49px;
          text-align: left;
          letter-spacing: -0.08rem;
          margin: 0 auto 3rem;
          @media (max-width: 500px) {
            font-size: 3.5rem;
            width: 100%;
            margin-bottom: 1rem;
            padding-left: 2rem;
            line-height: 40px;
          }
        }

        .tag {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 35px;
          text-align: left;
          width: 100%;
          margin: auto;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            padding-left: 2rem;
            line-height: 25px;
          }
        }

        .signUpContainer {
          margin: 2rem auto 4rem;
          text-align: left;

          @media (max-width: 800px) {
            margin: 2rem auto 4rem;
          }
          @media (max-width: 500px) {
            padding-left: 2rem;
          }

          .buttonAdditionalStyle {
            width: 133px;
            border: none;
            border-radius: 8px;
            padding: 10px;
            height: 51px;
            background-color: #34d07f;

            &:hover {
              // background-color: hsla(149, 62%, 51%, 0.732);
              background-color: transparent;
              border: 1px solid hsla(149, 62%, 51%, 0.732);
              transition: border 0.5s;
              // color: #7f56d9;
            }
          }
        }
      }
    }
  }
}
