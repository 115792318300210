.__chat__area__wrapper {
  box-shadow: 0px 1px 4px #f0eeeedc;
  border-radius: 10px;
  width: 35%;
  height: 90vh;
  @media screen and (max-width: 990px) {
    width: 44%;
  }

  @media screen and (max-width: 700px) {
    width: 90%;
    box-shadow: none;
    border: 1px solid #dbdbdb;
    height: 100vh;
  }

  .__chat__area__top {
    background-color: #fafafa;
    padding: 20px 20px;
    position: relative;
    display: flex;
    height: 15%;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0px 1px 0px rgba(85, 89, 97, 0.1);
    @media screen and (max-width: 700px) {
      height: 120px;
    }

    .__mobile__chat__icon {
      position: absolute;
      top: 10px;
      right: 10px;
      @media screen and (min-width: 700px) {
        display: none;
      }
    }
    .__chat__user__details {
      display: flex;
      align-items: center;

      .__chat__user__icon {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: #f9f5ff;
        margin-right: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;

        .__chat__user__image {
          object-fit: cover;
          width: 55px;
          height: 55px;
          border-radius: 50%;
        }

        .__chat__user__symbol {
          position: absolute;
          bottom: 0;
          right: 4px;

          .icon {
            color: #dbdbdb;
            font-size: 14px;
          }
        }
      }
      .__chat__user__details {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        .__chat__user__name {
          font-size: 15px;
          font-weight: 500;
          color: #7f56d9;
          margin-bottom: 3px;
        }
        .__chat__user__trades__completion {
          display: flex;
          align-items: flex-end;
          .__chat__user__trades {
            margin-right: 20px;
            display: flex;
            align-items: flex-end;
            .__chat__user__trades_title {
              color: #667085;
              margin-right: 5px;
              font-size: 14px;
            }
            .__chat__user__trades_value {
              color: black;
              font-size: 14px;
            }
          }
        }
      }
    }
    .the__v__and__m {
      height: 100%;
      .__chat__user__verified {
        display: flex;
        flex-direction: column;
        gap: 15px;
        padding-top: 5px;

        .__chat__user__verified_stated {
          .__verified__text {
            font-size: 12px;
            margin-right: 10px;
            color: #667085;
          }
          .__verified__image {
            width: 13px;
            height: 13px;
          }
        }

        .__chat__user__verified_stated_call {
          .__chat__user__verified_stated_call_container {
            display: flex;
            align-items: center;
            justify-content: center;
            gap: 5px;
            background-color: #34c759;
            backdrop-filter: blur(10px);
            border-radius: 48px;
            padding: 5px 0px;

            .__chat__user__verified_stated_call_container_icon_body {
              display: flex;
              align-items: center;
              justify-content: center;

              .__chat__user__verified_stated_call_container_icon {
                font-size: 12px;
                color: #fff;
              }
            }

            .__chat__user__verified_stated_call_container_text {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 12px;
              line-height: 120%;
              color: #ffffff;
            }
          }
        }
      }

      .moderatedWrapper {
        align-items: center;
        display: flex;
        margin-top: 10px;
        .circle {
          width: 10px;
          height: 10px;
          border-radius: 50%;
          background-color: #667085;

          &.__green {
            background-color: rgb(18, 129, 18);
          }
        }
        span {
          margin-right: 5px;
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
  }

  .__chat__area__bottom {
    .__messageChat__wrapper {
      height: calc(100vh - 270px);
      // height: 100%;
      display: flex;
      flex-direction: column;
      background-color: #fff;
      .__from__message {
        display: flex;
        margin-bottom: 40px;
        &:last-child {
          margin-bottom: 10px;
        }
        .__from__message__detailWrapper {
          width: 70%;
          margin-left: 10px;
          position: relative;

          .__from__message__namedate {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            padding: 0 8px 0 0px;
            margin-bottom: 10px;
            .__from__message__name {
              font-size: 14px;
              font-weight: 500;
              direction: ltr;
            }
            .__from__message__date {
              color: #667085;
              font-size: 12px;
            }
          }
          .__theImageWrapper {
            width: 100%;
            height: 180px;
            border-radius: 5px;
            overflow: hidden;
            .__theImage {
              // width: 100%;
              object-fit: contain;
            }
            &.__receiverImage {
              direction: ltr;
            }
            &.__senderImage {
              direction: rtl;
            }
          }

          .__from__message__mes {
            background-color: #f2f4f7;
            padding: 14px;
            font-size: 14px;
            line-height: 21px;
            border-radius: 0px 7px 7px 7px;

            &.__paid {
              background-color: #d4ebf6;
            }
          }
        }

        &.__sender {
          justify-content: flex-end;
          .__from__message__detailWrapper {
            width: 72%;
            direction: ltr;
          }
          .__from__message__mes {
            background-color: #7f56d9;
            color: white;

            &.__paid {
              background-color: #d4ebf6;
            }
          }

          .__theImage {
            float: right;
          }
        }
      }

      .__dispute__message {
        display: flex;
        justify-content: center;
        width: 100%;
        margin-bottom: 30px;
        .__dispute__wrapper {
          width: 85%;
          .__dispute__top {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 8px;
            .__dispute__name {
              font-size: 14px;
            }
            .__dispute__time {
              font-size: 12px;
              color: #667085;
            }
          }
          .__dispute__bottom {
            background-color: #fef6d8;
            padding: 10px 15px;
            border-radius: 10px;

            &.__release {
              background-color: #e1f6e2;
            }

            &.__cancelled {
              background-color: #f6dcdc;
              // background-color: #fff;
              border: 1px solid #eaecef;
            }
            &.__paid {
              background-color: #d4ebf6;
            }

            .__dispute_message {
              font-size: 14px;
              line-height: 21px;
              // background-color: #000;
              word-wrap: break-word;
            }
          }
        }
      }

      .__sender__message {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 40px;
        .__sender__messageWrapper {
          width: 50%;
          .__sender__message__namedate {
            display: flex;
            justify-content: space-between;
            padding: 0 8px;
            width: 100%;
            margin-bottom: 10px;
            .__sender__message__name {
              font-size: 14px;
              font-weight: 500;
            }
            .__sender__message__date {
              font-size: 14px;
              color: #667085;
            }
          }

          .__sender__message__mes {
            background-color: #7f56d9;
            padding: 15px;
            border-radius: 7px 0px 7px 7px;
            font-size: 14px;
            color: white;
            line-height: 21px;
          }
        }
      }
    }
    .__scrollView {
      padding: 20px 20px 0 20px;
      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 0px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 0px;
        background-color: #f5f5f5;
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 0px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #98a2b3;
      }
    }
  }
  .__send__input__wrapper {
    border-top: 1px solid #e1e4ebc0;
    padding: 20px 20px 40px 20px;
    height: 130px;
    // @media screen and (max-width: 700px) {
    //   height: 17%;
    // }

    .__sendInput__wrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .__sendInput__inwrapper {
        display: flex;
        justify-content: space-between;
        border: 1px solid #e1e4ebc0;
        border-radius: 6px;
        width: 80%;
        padding: 3px 10px;
        .__send__input {
          border: none;
          outline: none;
          font-size: 14px;
          width: 80%;

          &::placeholder {
            font-size: 13px;
          }
        }
        .__upload__wrapper {
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;
          width: 35px;
          height: 35px;
          cursor: pointer;
          .__file__input {
            position: absolute;
            width: 100%;
            height: 100%;
            top: 0;
            right: 0;
            left: 0;
            bottom: 0;
            margin: 0;
            z-index: 3;
            background-color: transparent;
            cursor: pointer;
            opacity: 0;
            filter: alpha(opacity=0);

            &:disabled {
              cursor: not-allowed;
            }
          }
          .__attach__icon {
            cursor: pointer;
            // color: #798496;
            z-index: 0;
          }
        }
      }
      .__sendmessage__button {
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #7f56d9;
        border-radius: 4px;
        width: 15%;
        cursor: pointer;

        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
}
