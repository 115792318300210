.aboutWrapper {
  width: 100%;
  text-align: center;
  overflow: hidden;

  .rowOneContainer {
    background: #fafbff;
    // padding: 96px 0px;
    padding: 96px 0px 0px;

    @media (max-width: 1000px) {
      padding: 10px 0px;
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .textContainer {
        width: 600px;
        height: auto;
        margin-bottom: 2rem;

        .titleAboutUs {
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 46px;
          line-height: 58px;
          text-align: left;
          color: rgba(16, 24, 40, 0.99);
          margin-bottom: 2rem;
        }

        .tagAboutUs {
          font-family: "Inter";
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
        }

        @media (max-width: 1000px) {
          padding: 10px 0px;
          .titleAboutUs {
            font-size: 26px;
            margin-bottom: 0em;
          }
          .tagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }

      .ImageContainer {
        width: 550px;
        height: auto;
        .imageElement {
          max-width: 100%;
          background-size: contain;
        }
      }

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
        .textContainer {
          .titleAboutUs {
            font-size: 24px;
            margin: 0rem;
            padding: 0;
            // line-height: 20px;
          }
          .tagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }
    }
  }

  .rowTwoContainer {
    padding: 10px 0px 80px;
    display: flex;
    flex-direction: column;
    background: white;
    // margin-bottom: 8rem;

    background-image: url("../../assets//images/background-aboutus.png");
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain;
    @media (max-width: 1000px) {
      padding: 10px 0px;
      margin-bottom: 12rem;
    }

    @media (max-width: 755px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .rowTwoContainerTitle {
      padding: 50px 0 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: rgba(16, 24, 40, 0.99);
      margin-bottom: 2rem;
      @media (max-width: 400px) {
        // padding: 10px 0;
        font-size: 20px;
        margin-bottom: 0;
      }
    }
    .rowTwoContainerTag {
      padding: 0px 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #34495e;
      margin-bottom: 2rem;
      width: 80%;
      margin: auto;
      @media (max-width: 400px) {
        font-size: 14px;
        margin-bottom: 0;
        width: 80%;
        margin: auto;
      }
    }
    .rowTwoContainerTitleTwo {
      padding: 50px 0 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: rgba(16, 24, 40, 0.99);
      margin-bottom: 2rem;
      @media (max-width: 400px) {
        // padding: 10px 0;
        font-size: 20px;
        margin-bottom: 0;
      }
    }
    .rowTwoContainerTagTwo {
      padding: 0px 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #34495e;
      margin-bottom: 2rem;
      @media (max-width: 400px) {
        font-size: 14px;
        margin-bottom: 0;
        width: 80%;
        margin: auto;
      }
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 2rem;
      text-align: left;
      height: auto;

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
      }

      @media (max-width: 700px) {
        // margin: 0;
        width: 90%;
        margin-bottom: 4rem;
      } // padding: 30px 0;

      .textContainer {
        margin: 2rem 0;
        width: 300px;
        height: 180px;

        // :last-child {
        //   margin-bottom: 20rem;
        //   background-color: red;
        // }

        @media (max-width: 700px) {
          margin: 0;
          width: 100%;
        }

        @media (max-width: 400px) {
          // padding: 10px 0;
          margin-top: 4rem;
        }

        .textContainerOneTitleAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
          padding: 10px 0;
        }
        .tagAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
        }
      }
    }
  }

  .rowThreeContainer {
    padding: 10px 0px 80px;
    display: flex;
    flex-direction: column;
    background: white;

    @media (max-width: 1000px) {
      padding: 10px 0px;
      margin-bottom: 12rem;
    }

    @media (max-width: 755px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 2rem;
      text-align: left;
      height: auto;
      margin-bottom: 4rem;

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
      }

      @media (max-width: 700px) {
        width: 90%;
        margin-bottom: 2rem;
      }

      .rowThreetextContainer {
        margin: 2rem 0;
        width: 300px;
        height: 250px;

        @media (max-width: 900px) {
          height: 290px;
        }
        @media (max-width: 700px) {
          width: 100%;
          height: 290px;
        }

        @media (max-width: 500px) {
          height: 100%;
          margin-bottom: 1rem;
        }

        .rowThretextContainerOneTitleAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
          padding: 10px 0;
        }
        .rowThreetagAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
          @media (max-width: 500px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .rowFourContainer {
    padding: 0px 0px 40px;
    display: flex;
    flex-direction: column;
    background: #f6f9fc;

    @media (max-width: 1000px) {
      padding: 10px 0px;
    }

    .rowFourContainerTitle {
      padding: 50px 0 10px;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: rgba(16, 24, 40, 0.99);

      @media (max-width: 500px) {
        font-size: 24px;
        padding: 0 0 10px;
      }
    }
    .rowFourContainerTag {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #34495e;
      width: 60%;
      margin: auto auto 3.9rem;

      @media (max-width: 900px) {
        width: 80%;
        font-size: 14px;
      }
      @media (max-width: 500px) {
        width: 90%;
        font-size: 14px;
      }
    }

    .rowFourSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      @media (max-width: 900px) {
        flex-wrap: wrap;
      }

      .ImageContainer {
        width: 550px;
        height: 400px;
        .imageElement {
          max-width: 100%;
        }

        @media (max-width: 400px) {
          height: auto;
        }
      }

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
        .textContainer {
          .titleAboutUs {
            font-size: 24px;
            margin: 0rem;
            padding: 0;
          }
          .tagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }
    }
  }

  .rowFiveContainer {
    padding: 10px 0px 80px;
    display: flex;
    flex-direction: column;
    background: white;

    @media (max-width: 1000px) {
      padding: 10px 0px;
      margin-bottom: 12rem;
    }

    @media (max-width: 755px) {
      flex-wrap: wrap;
      margin-bottom: 0;
    }

    .rowSixTitleAboutUs {
      padding: 50px 0 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: rgba(16, 24, 40, 0.99);
      margin-bottom: 2rem;
      @media (max-width: 400px) {
        font-size: 20px;
        margin-bottom: 0;
        padding: 10px 0 0;
      }
    }
    .rowSixTagAboutUs {
      padding: 0px 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: #34495e;
      margin-bottom: 2rem;
      @media (max-width: 400px) {
        font-size: 14px;
        margin-bottom: 0;
        width: 80%;
        margin: auto;
      }
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;
      gap: 2rem;
      text-align: left;
      height: auto;

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
      }

      @media (max-width: 700px) {
        // margin: 0;
        width: 90%;
        margin-bottom: 4rem;
      } // padding: 30px 0;

      .textContainer {
        margin: 2rem 0;
        width: 300px;
        height: 180px;

        // :last-child {
        //   margin-bottom: 20rem;
        //   background-color: red;
        // }

        @media (max-width: 700px) {
          margin: 0;
          width: 100%;
        }

        @media (max-width: 400px) {
          // padding: 10px 0;
          margin-top: 4rem;
        }

        .textContainerOneTitleAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
          padding: 10px 0;
        }
        .tagAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
        }
      }
    }
  }

  .rowSixContainer {
    padding: 0px 0px 40px;
    display: flex;
    flex-direction: column;
    background: #f6f9fc;

    @media (max-width: 1000px) {
      padding: 10px 0px;
    }

    .rowSixtitleAboutUs {
      padding: 50px 0;
      text-align: center;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      color: rgba(16, 24, 40, 0.99);
      margin-bottom: 2rem;

      @media (max-width: 500px) {
        // font-size: 20px;
        margin-bottom: 0;
        padding: 10px 0 0;
      }
      @media (max-width: 400px) {
        font-size: 20px;
        margin-bottom: 0;
        padding: 10px 0 0;
      }
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      position: relative;

      .textContainer {
        width: 470px;
        height: auto;
        margin-bottom: 2rem;
        .rowSixtagAboutUs {
          font-family: "Inter";
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          text-align: left;
          color: #667085;
          @media (max-width: 400px) {
            font-size: 14px;
            margin-bottom: 0;
            font-weight: 400;
          }
        }

        @media (max-width: 1000px) {
          padding: 10px 0px;
          .rowSixtitleAboutUs {
            font-size: 26px;
            margin-bottom: 0em;
          }
          .rowSixtagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }

      .ImageContainer {
        width: 550px;
        height: auto;
        .imageElement {
          max-width: 100%;
          background-size: contain;
        }

        @media (max-width: 400px) {
          margin: 2rem auto 3rem;
        }
      }

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
        .textContainer {
          .rowSixtitleAboutUs {
            font-size: 24px;
            margin: 0rem;
            padding: 0;
            // line-height: 20px;
          }
          .rowSixtagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }
    }
  }

  .rowSevenContainer {
    padding: 60px 0px 60px;
    display: flex;
    background: #f9f5ff;

    @media (max-width: 1000px) {
      padding: 10px 0px;
    }

    .heroSection {
      width: 80%;
      margin: auto;
      display: flex;
      justify-content: space-between;

      .textContainer {
        width: 800px;
        height: auto;

        .tagLastRowTitle {
          font-family: "Inter";
          font-weight: 600;
          font-size: 30px;
          line-height: 38px;
          text-align: left;
          color: #42307d;
          margin-bottom: 1rem;

          @media (max-width: 400px) {
            font-size: 20px;
            line-height: normal;
          }
        }
        .tagLastRowTag {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 20px;
          line-height: 30px;
          color: #34495e;
          text-align: left;

          @media (max-width: 400px) {
            font-size: 16px;
            line-height: normal;
          }
        }

        @media (max-width: 1000px) {
          padding: 10px 0px;
          .titleAboutUs {
            font-size: 26px;
            margin-bottom: 0em;
          }
          .tagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }

      .btnToTheLastContainer {
        width: 250px;
        .signupButton {
          cursor: pointer;
          width: 121px;
          height: 38px;
          background: #7f56d9;
          border: 1px solid #7f56d9;
          box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
          border-radius: 8px;
          background-size: contain;
          display: flex;
          justify-content: center;
          align-items: center;
          font-size: 16px;
          color: white;
          @media (max-width: 400px) {
            height: 35px;
            width: 115px;
            margin-bottom: 2rem;
          }
        }
      }

      @media (max-width: 755px) {
        flex-wrap: wrap;
        width: 90%;
        .textContainer {
          .titleAboutUs {
            font-size: 24px;
            margin: 0rem;
            padding: 0;
          }
          .tagAboutUs {
            font-family: "Inter";
            font-weight: 500;
            font-size: 16px;
            text-align: left;
            color: #667085;
          }
        }
      }
    }
  }
}
