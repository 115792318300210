.navContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: sticky;
  top: 0%;
  margin: auto;
  z-index: 99;
  background-color: #fafafa;
  height: 70px;

  @media (max-width: 1000px) {
    .innerContainer {
      .innerContainerRight {
        display: none;
      }
    }
  }

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    @media (max-width: 1000px) {
      width: 100%;
    }

    .innerContainerLeft {
      display: flex;
      align-items: center;

      .mobileNav {
        display: none;
      }
      @media (max-width: 1000px) {
        width: 100%;
        justify-content: space-between;

        .logoLink {
          display: none;
        }
        .mobileNav {
          display: flex;
          margin-left: 2rem;
        }
      }

      .mobileMenu {
        display: none;
      }
      @media (max-width: 1000px) {
        .mobileMenu {
          display: flex;
          border: none;
          background-color: 2px solid #f4ebff;
          font-size: 3rem;
          background-color: transparent;
          margin-right: 2rem;
        }
      }
    }

    .innerContainerRight {
      display: flex;
      align-items: center;
      // list menu
      .navListMenuRight {
        display: flex;
        align-items: center;
        gap: 2rem;
        .link {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 19px;
          text-transform: capitalize;
          color: #1e2329;
          border: none;
          background-color: transparent;
          cursor: pointer;

          &:hover {
            color: rgba(30, 35, 41, 0.79);
          }
        }

        .linkIcon {
          display: flex;
          align-items: center;
          gap: 1rem;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.4rem;
          line-height: 19px;
          text-transform: capitalize;
          color: #1e2329;
          border: none;
          background-color: transparent;
          cursor: pointer;

          &:hover {
            color: rgba(30, 35, 41, 0.79);
          }
        }
      }

      // list menu button
      .navListLinkRight {
        display: flex;
        gap: 1.5rem;
        align-items: center;
      }

      // mobile menu
      @media (max-width: 1000px) {
        display: none;
        // .navListMenuRight,
        // .navListLinkRight {
        //   display: none;
        // }
      }
    }
  }
}
