.BigClaimContainer {
  display: flex;
  align-items: center;
  margin-right: 50px;
  @media (max-width: 1280px) {
    // width: 80px;
    // background-color: red;
    margin-right: 0px;
  }
  .ClaimContainer {
    width: 200px;
    height: 40px;
    font-size: 12px;
    background-color: #eff2f5;
    margin-left: 10px;
    color: #000;
    box-shadow: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-right: 5px;

    .circle-Image {
      width: 24px;
      height: 24px;
    }

    .titleStyle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #101828;
    }

    .buttonToClaim {
      background: #3861fb;
      border-radius: 16px;
      border: none;
      color: white;
      width: 90px;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      height: 30px;
    }
    .buttonDefault {
      background-color: #97acf8;
      background-color: #3861fb;
    }

    .buttonClaimed {
      background-color: #13c39c;
    }
    .buttonError {
      background-color: #f04438 !important;
      border: 1px solid #f04438;
    }
    .info-claim {
      width: 24px;
      height: 24px;
    }
    .not-allowed-claim {
      cursor: not-allowed;
      background-color: #3861fb;
    }
  }
}
.BigClaimContainerMobileExtender {
  margin: auto;
  width: 300px;
  height: auto;
  display: flex;
  flex-direction: column;

  .mobileTitle {
    font-family: "Inter";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 28px;
    text-align: center;
    color: #101828;
    margin-top: 2rem;
  }

  .mobileInfoCdntainer {
    display: flex;
    width: 80%;
    align-items: center;
    margin: 1rem auto 0rem;
    gap: 1rem;
  }

  .ClaimContainerMobile {
    width: 200px;
    height: 40px;
    font-size: 12px;
    background-color: #eff2f5;
    color: #000;
    box-shadow: none;
    border-radius: 15px;
    display: flex;
    align-items: center;
    padding-left: 1rem;

    .circle-Image {
      width: 24px;
      height: 24px;
    }

    .titleStyle {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 18px;
      color: #101828;
      margin-left: 0.5rem;
    }
  }

  .buttonToClaim {
    margin: 2rem auto 1.5rem;
    background: #3861fb;
    border-radius: 10px;
    border: none;
    color: white;
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 0.4rem;
    height: 35px;
  }
  .buttonDefault {
    background-color: #97acf8;
    background-color: #3861fb;
  }

  .buttonClaimed {
    background-color: #13c39c;
  }
  .buttonError {
    background-color: #f04438 !important;
    border: 1px solid #f04438;
  }
  .info-claim {
    width: 24px;
    height: 24px;
  }
  .not-allowed-claim {
    cursor: not-allowed;
    background-color: #3861fb;
  }
}

.MobileClaimContainer {
  display: flex;
  align-items: center;
  width: 70px;
  background: #ffffff;
  border-radius: 8px;
  height: 28px;
  justify-content: center;
  gap: 0.3rem;
  .text {
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 18px;
    text-align: center;
    color: #000000;
  }
}
