.__ourmodal2Wraper {
  position: absolute;
  top: 0;
  z-index: 2000000;
  left: 0;
  bottom: 0;
  width: 100%;
  height: auto;
  background: rgba(52, 64, 84, 0.6);
  backdrop-filter: blur(0.1px);
  // &::-webkit-scrollbar {
  //   width: 5px;
  // }
  // &::-webkit-scrollbar-track {
  //   border-radius: 5px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   border-radius: 10px;
  //   /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  //   background-color: #98a2b3;
  // }

  padding: 10px 0px;
  &.__whitebg {
    background-color: #f9fafb;
  }

  &.__center {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .__innnermodal {
    overflow-y: auto;
    display: flex;
    //width: 100vw;
    height: auto;
    justify-content: center;
    // padding: 40px 0;
    border-radius: 10px;
    margin: 0 auto;
  }
}

.__ourModalWraper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: max-content;
  background-color: "white";
  border-radius: 5px;

  @media screen and (max-width: 700px) {
    border-radius: 10px;
  }
}
