@import "colors";

.featuresContainer {
  display: flex;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;
    flex-wrap: wrap;

    .titleContainer {
      margin: 8rem auto 4rem;
      text-align: left;
      width: 100%;

      .title {
        font-weight: 500;
        font-size: 3.35208rem;
        line-height: 42px;
        color: #101828;
        text-align: center;
      }

      .subtitle {
        font-size: 16px;
        line-height: 24px;
        color: #667085;
        color: $fontSecondary;
        margin: 4rem auto 0;
        width: 55%;
        text-align: center;
        @media (max-width: 1000px) {
          width: 100%;
        }
      }

      .cardContainer {
        text-align: left;
        width: 50%;
        margin: auto;
        margin-top: 6rem;
        @media (max-width: 1000px) {
          width: 100%;
        }

        .title {
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;
          color: #667085;
        }

        .listContainer {
          text-align: left;
          width: 100%;
          margin: auto;
          margin-top: 6rem;

          .bigTitle {
            font-weight: 600;
            font-size: 16px;
            line-height: 38px;
            color: #101828;
            // margin-bottom: 1rem;
            margin: 0;
          }
          .subtitle {
            width: 100%;
            font-weight: 400;
            font-size: 14px;
            line-height: 28px;
            color: #667085;
            text-align: left;
            margin: 1rem 0 0;
          }
          .subtitleBig {
            width: 100%;
            font-weight: 600;
            font-size: 14px;
            line-height: 28px;
            color: #667085;
            text-align: left;
            margin: 1rem 0;
          }

          .ulList {
            .list {
              font-weight: 400;
              font-size: 14px;
              line-height: 24px;
              color: #667085;
              margin: 1rem 0 1rem 2rem;
            }
          }
        }
      }
    }
  }
}
.wideInner {
  background: #f9fafb;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  height: 300px;
  width: 100%;
  margin-top: 10rem;

  .titleContainerSignup {
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
    text-align: center;
    letter-spacing: -0.02em;
    color: #101828;
    padding: 3rem auto;
  }

  .buttonContainer {
    display: flex;
    width: 100%;
    justify-content: center;
    gap: 2rem;
    margin-top: 3rem;

    .btnLearn {
      width: 127px;
      height: 48px;
    }
    .btnMore {
      background: #ffffff;
      border: 1px solid #d0d5dd;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 8px;
      color: #344054;
    }
  }
}
