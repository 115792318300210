.buttonOopSection {
  width: 30%;

  button {
    width: 100%;
    height: 40px;
    background: #7f56d9;
    border: 1px solid #7f56d9;
    box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
    border-radius: 8px;
    font-family: "Inter";
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 20px;
    color: #ffffff;
    cursor: pointer;
  }

  @media screen and (max-width: 950px) {
    width: 50%;
  }

  @media screen and (max-width: 720px) {
    width: 70%;
  }

  @media screen and (max-width: 500px) {
    width: 90%;
  }
}
