// .image__body {
//   width: "250px";
//   height: "250px";
//   display: flex;
//   align-items: center;
//   justify-content: center;
//   flex-grow: 5;
//   border: 1px solid red;

.images {
  width: 25px;
  height: 25px;
  object-fit: cover;
  border-radius: 50%;
  box-shadow: 0px 0.855949px 1.7119px rgba(16, 24, 40, 0.05);
}
// }
