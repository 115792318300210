.nav-bar-container{
    width: 100%;
    height: 60px;
    background: #fff;
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: fixed;

    .inner-container{
        width: 90%;
        height: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }
}

@media (min-width: 1200px){
    .mobile-bar{
        display: none;
    }
}

@media (max-width: 1100px){
    .nav-bar-container{
        display: none;
    }
}