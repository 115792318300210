.__createtrade__successModal {
  width: 410px;
  padding: 20px 24px;
  @media screen and (max-width: 730px) {
    width: 90vw;
    min-height: 62vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }

  .__info__holder {
    width: 100%;
    text-align: center;
    margin-top: 20px;
    .__info__image {
      width: 70px;
      height: auto;

      @media screen and (max-width: 730px) {
        width: 100px;
      }
    }
  }

  .__info__amount {
    margin-top: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    span.__amount {
      font-size: 32px;
      font-weight: bold;
    }
    span.__success__text {
      font-size: 13px;
      font-weight: 400;
      margin: 10px 0;
    }

    .__done__button {
      background-color: #6941c6;
      margin: 10px 0;
      padding: 10px 20px;
      width: 45%;
      color: white;
      border-radius: 5px;
      text-align: center;
      outline: none;
      border: none;
    }
    .__check__wallet {
      color: #34d07f;
      font-size: 15px;
      margin: 10px 0;
    }

    .__how__review {
      font-size: 13px;
      color: #667085;
      margin: 10px 0;
    }

    .__reviewRes {
      font-size: 12px;
      margin-bottom: 10px;
      &.__cor {
        color: green;
      }

      &.__err {
        color: red;
      }
    }
    .__the__button__wrapper {
      display: flex;
      justify-content: space-around;
      width: 100%;
      margin-top: 16px;
      margin-bottom: 10px;
      .__review__ {
        outline: none;
        border: 1px solid #d0d5dd;
        padding: 10px 15px;
        width: 41%;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 15px;
        background-color: white;
        svg {
          color: #959eb3;
        }
        &.__pos {
          background-color: #ecfdf3;
          color: #079b73;
          svg {
            color: #079b73;
          }
        }

        &.__neg {
          background-color: #fef3f2;
          color: #b42318;
          svg {
            color: #b42318;
          }
        }
      }
    }
  }
}
