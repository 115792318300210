.ParentInputButtonContainer {
  display: flex;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #7f56d9;
  border: 1px solid #d8d8d8;
  cursor: pointer;
  align-items: center;

  padding: 0 20px;
  .TextAndInputContainerStyle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    .TextAndInputTitleStyle {
      color: #3d3d3d;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      margin-bottom: 5px;
    }
    .TextAndInputStyle {
      width: 100%;
      height: 44px;
      border: 0;
      color: black;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;

      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ede9e9;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ede9e9;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ede9e9;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ede9e9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ede9e9;
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: #ede9e9;
      }
    }
    .TextAndInputStylePlaceholder {
      width: 100%;
      height: 44px;
      border: 0;
      color: #ede9e9;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;

      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ede9e9;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ede9e9;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ede9e9;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ede9e9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ede9e9;
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: #ede9e9;
      }
    }

    .TextAndInputStyleActive {
      width: 100%;
      height: 44px;
      border: 0;
      color: black;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;
      border: 1px solid #7f56d9;

      // &:placeholder-shown {
      //   font-weight: normal;
      //   color: #bcbdc6;
      // }
      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ede9e9;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ede9e9;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ede9e9;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ede9e9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ede9e9;
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: #ede9e9;
      }
    }
  }
  .ButtonContainerButtonStyle {
    display: flex;
    min-width: 90px;
    min-height: 56px;
    padding: 16px 21.13px 16px 21.11px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #f7f7f7;
    gap: 10px;

    width: 170px;

    .ButtonContainerButtonStyleIcon {
      margin-left: 4px;
      width: 34px;
      height: 34px;
    }
    .ButtonContainerButtonStyleTitle {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
    }
    .ButtonContainerButtonStyleArrowIcon {
      height: 6px;
      width: 12px;
      // margin: 0 4px;
      padding-right: 4px;
      margin-right: 5px;
    }
  }
}

.ParentInputButtonContainerError {
  display: flex;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #cf304a;
  // border: 1px solid #d8d8d8;
  cursor: pointer;
  align-items: center;

  padding: 0 20px;
  .TextAndInputContainerStyle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    .TextAndInputTitleStyle {
      color: #3d3d3d;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      margin-bottom: 5px;
    }
    .TextAndInputStyle {
      width: 100%;
      height: 44px;
      border: 0;
      color: black;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;

      &:placeholder-shown {
        font-weight: normal;
        color: #bcbdc6;
      }
    }
  }
  .ButtonContainerButtonStyle {
    display: flex;
    min-width: 90px;
    min-height: 56px;
    padding: 16px 21.13px 16px 21.11px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #f7f7f7;
    gap: 10px;

    width: 170px;
    .ButtonContainerButtonStyleIcon {
      width: 34px;
      height: 34px;
    }
    .ButtonContainerButtonStyleTitle {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
    }
    .ButtonContainerButtonStyleArrowIcon {
      height: 6px;
      width: 12px;
    }
  }
}

.ParentInputButtonContainerActive {
  display: flex;
  border-radius: 12px;
  width: 100%;
  border: 1px solid #7f56d9;
  cursor: pointer;
  align-items: center;

  padding: 0 20px;
  .TextAndInputContainerStyle {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;

    .TextAndInputTitleStyle {
      color: #3d3d3d;
      font-family: Inter;
      font-size: 13px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 123.077% */
      margin-bottom: 5px;
    }
    .TextAndInputStyle {
      width: 100%;
      height: 44px;
      border: 0;
      color: black;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;

      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ede9e9;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ede9e9;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ede9e9;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ede9e9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ede9e9;
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: #ede9e9;
      }
    }

    .TextAndInputStyleActive {
      width: 100%;
      height: 44px;
      border: 0;
      color: black;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      outline: none;
      border: 1px solid #7f56d9;

      &::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: #ede9e9;
      }
      &:-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: #ede9e9;
        opacity: 1;
      }
      &::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: #ede9e9;
        opacity: 1;
      }
      &:-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: #ede9e9;
      }
      &::-ms-input-placeholder {
        /* Microsoft Edge */
        color: #ede9e9;
      }

      &::placeholder {
        /* Most modern browsers support this now. */
        color: #ede9e9;
      }
    }
  }
  .ButtonContainerButtonStyle {
    display: flex;
    min-width: 90px;
    min-height: 56px;
    padding: 16px 21.13px 16px 21.11px;
    justify-content: center;
    align-items: center;
    border-radius: 30px;
    background: #f7f7f7;
    gap: 10px;
    width: 170px;
    .ButtonContainerButtonStyleIcon {
      width: 34px;
      height: 34px;
    }
    .ButtonContainerButtonStyleTitle {
      color: #000;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 24px; /* 133.333% */
    }
    .ButtonContainerButtonStyleArrowIcon {
      height: 6px;
      width: 12px;
    }
  }
}
