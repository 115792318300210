.ConvertParent {
  height: 80vh;
  width: 100%;
  margin-top: -20px;
  .topComponent {
    height: 30px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.05);
    @media screen and (max-width: 700px) {
      margin-top: 10px;
    }
    .titleStyle {
      color: #101828;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 700;
      line-height: 20px;
      @media screen and (max-width: 700px) {
        font-size: 16px;
      }
    }
  }

  //   section

  .convertMainComponent {
    margin-top: 40px;
    flex-wrap: wrap;
    display: flex;
    // height: 50vh;
    justify-content: center;

    gap: 20px;

    @media screen and (max-width: 700px) {
      flex-direction: column;
      //   background-color: red;
      //   margin-top: 20px;
    }
    .leftSide {
      width: 40%;
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      margin-top: 40px;
      @media screen and (max-width: 700px) {
        width: 100%;
        margin-bottom: 20px;
      }
      .convertLeftSideTitle {
        color: #101828;
        font-family: "inter";
        font-size: 60px;
        font-style: normal;
        font-weight: 700;
        line-height: 82px; /* 132.258% */
        margin-top: 20px;
        @media screen and (max-width: 700px) {
          font-size: 42px;
          text-align: center;
          line-height: normal;
          margin: 20px 0 0;
        }
      }
      .convertLeftSideSubtitle {
        color: #344054;
        font-family: "inter";
        font-size: 23px;
        font-style: normal;
        font-weight: 400;
        line-height: 33px; /* 143.478% */
        margin-top: 17px;
        @media screen and (max-width: 700px) {
          text-align: center;
          line-height: normal;
          margin-top: 10px;
          font-size: 18px;
        }
      }

      .convertLeftSideFooterTitle {
        color: #667085;
        font-family: "inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 14px; /* 107.692% */
        @media screen and (max-width: 700px) {
          display: none;
        }
      }
      .convertFooterComponent {
        margin-top: 15px;
        display: flex;
        gap: 5px;
        @media screen and (max-width: 700px) {
          display: none;
          margin-bottom: 0;
        }
        margin-bottom: 60px;
      }
      .convertLeftSideFooterSubtitle {
        color: #344054;
        font-family: "inter";
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 16px; /* 123.077% */
        margin-top: 3px;
      }
    }

    .rightSide {
      width: 40%;
      @media screen and (max-width: 700px) {
        width: 100%;
        padding: 0 20px;
      }
    }
  }
}
