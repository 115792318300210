@import "colors";

.homepageContainer {
  display: flex;

  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: auto;

    @media (max-width: 500px) {
      width: 95%;
    }

    .titleContainer {
      margin: 0rem auto 8rem;
      text-align: center;

      @media (max-width: 500px) {
        margin: 2rem auto;
      }

      .title {
        color: $fontPrimary;
        font-family: "Inter";
        font-style: normal;
        font-weight: 700;
        font-size: 7rem;
        line-height: 72px;
        text-align: center;
        letter-spacing: -0.02em;
      }

      .subtitle {
        font-family: "Inter";
        font-style: normal;
        font-weight: 300;
        font-size: 2rem;
        line-height: 32px;
        color: $fontSecondary;
        width: 65%;
        margin: 3rem auto 0;
      }

      @media (max-width: 500px) {
        .title {
          font-size: 3.5rem;
          line-height: 45px;
        }
        .subtitle {
          font-weight: 400;
          font-size: 1.6rem;
          color: $fontSecondary;
          width: 90%;
          margin: 2rem auto 0;
        }
      }

      .signUpContainer {
        margin: 5rem auto 0;
        width: 100%;

        .joinButton {
          width: 200px;
        }
      }

      .heroImageContainer {
        margin: 5rem auto 0rem;
        width: 100%;
        .imge {
          width: 765px;
          height: 100%;
          @media (max-width: 800px) {
            width: 100%;
          }
        }
      }
    }
    //end hero

    .marketplaceContainer {
      margin: 4rem auto 8rem;
      position: relative;

      width: 80%;

      @media (max-width: 500px) {
        padding: auto;
        margin: 2rem auto;
        width: 94%;
      }

      .titleContainer {
        margin: 2rem auto 6rem;

        .tag {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #6941c6;
          margin: 0rem auto 1rem;
          text-transform: uppercase;
        }

        .title {
          font-weight: 600;
          font-size: 40px;
          line-height: 44px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #101828;
          margin: 0 auto 3rem;
        }

        .description {
          font-weight: 400;
          font-size: 24px;
          line-height: 30px;
          text-align: center;
          color: #111111;

          // width: 80%;
          margin: auto;
        }

        @media (max-width: 600px) {
          .tag {
            font-size: 1.6rem;
            line-height: 24px;
            text-align: center;
            margin: 0rem auto 1rem;
          }

          .title {
            font-size: 3rem;
            line-height: 34px;
            color: #101828;
            margin: 0 auto 1.5rem;
          }

          .description {
            font-size: 1.8rem;
            width: 90%;
          }
        }
      }
      .marketplaceCard {
        text-align: center;
        background: #fff;
        filter: drop-shadow(4px 4px 4px rgba(213, 213, 213, 0.25));
        padding: 2rem;
        border-radius: 10px;

        position: relative;
        .tradeButtonContainer {
          list-style: none;
          display: flex;
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 11.2939px;
          line-height: 16px;
          color: #1d2939;
          background-color: #f0f2f5;
          padding: 4px;
          border-radius: 1rem;
          width: 235px;
          margin: 1rem auto 2rem;

          .current {
            color: white;
            background: #34d07f;
          }
          .currentSell {
            color: white;
            background: red;
          }

          .buttonBuy {
            border: 1px solid #f0f2f5;
            box-shadow: 0px 5px 20px rgba(0, 0, 0, 0.05);
            width: 50%;
            padding: 1.5rem;
            cursor: pointer;
            border-radius: 1rem;
            font-size: 1.5rem;
          }
        }

        .optionsContainer {
          display: flex;
          background: #f9fafb;
          border-radius: 13.4083px 13.4083px 0px 0px;
          padding: 1rem 1rem 2rem;
          justify-content: center;
          margin: 4rem 0 6rem;
          position: relative;
          flex-wrap: wrap;

          @media (max-width: 1000px) {
            padding: auto;
          }

          @media (max-width: 500px) {
            padding: auto;
            margin: auto;
          }
          .overViewContainer {
            width: 33%;
            position: relative;

            @media (max-width: 1000px) {
              // width: 340px;
              margin: 2.5rem 0;
              // background-color: red;
              width: 100%;
            }

            @media (max-width: 500px) {
              margin: 2.5rem 0;
              width: 100%;
            }

            .title {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 14px;
              line-height: 27px;
              color: #667085;
            }

            .dropContainer {
              margin: auto;
              width: 80%;
              height: 50px;
              padding: 1rem 1.3rem;
              border-radius: 10px;
              background: #ffffff;
              border: 1px solid #eff0f6;
              border-radius: 20px;

              @media (max-width: 500px) {
                margin: 1rem 0;
                width: 100%;
              }

              .inputField {
                background-color: transparent;
                border: none;

                &:focus {
                  outline: none;
                }
              }

              .title {
                font-family: "Inter";
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 20px;
                color: rgba(0, 0, 0, 0.7);
                margin: 0;
              }

              display: flex;
              align-items: center;
              justify-content: space-between;
            }
          }
        }
      }
    }
    //end market place

    .ourFeatures {
      display: flex;
      justify-content: space-between;
      margin: 12rem auto 1rem;
      width: 100%;

      @media (max-width: 1000px) {
        flex-direction: column;
        align-items: center;
      }

      .leftFeaturesContainer {
        // background-color: red;
        // width: 100%;
        // max-width: 100%;
        width: 50%;
        text-align: center;
        .imageContainer {
          // height: auto;
          margin-bottom: 2rem;
        }
      }

      .rightFeaturesContainer {
        margin: 0rem auto 0;
        width: 45%;
        height: auto;
        .title {
          margin: 0 auto 3rem;
          font-weight: 600;
          font-size: 30px;
          line-height: 42px;
          letter-spacing: -0.02em;
          color: #101828;
        }
      }
    }
    // end

    .moreFeatures {
      margin: 2rem auto 1rem;

      .titleContainer {
        margin: 2rem auto 6rem;
        .tag {
          font-weight: 600;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #6941c6;
          margin: 0rem auto 1rem;
          text-transform: capitalize;
        }

        .title {
          font-weight: 600;
          font-size: 40px;
          line-height: 44px;
          text-align: center;
          letter-spacing: -0.02em;
          color: #101828;
          margin: 0 auto 2rem;
        }

        .description {
          font-weight: 400;
          font-size: 14px;
          line-height: 30px;
          text-align: center;
          color: #667085;

          width: 100%;
          margin: auto;
        }
      }

      .imageContainer {
        margin: auto;
        text-align: center;
      }

      .horizontalContainer {
        margin-top: 4rem;
        display: flex;
        gap: 1rem;

        @media (max-width: 1000px) {
          flex-direction: column;
          width: 100%;
        }
      }

      .signUpContainer {
        margin: 6rem auto 4rem;
        text-align: center;

        .buttonAdditionalStyle {
          width: 15%;
          border: none;
          border-radius: 8px;
          padding: 10px;
          height: 51px;
          background: $primary;
        }
      }
    }
  }
}
