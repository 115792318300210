.searchDropDownWrapper_read {
  width: 100%;
  position: relative;

  .settingsTitle {
    font-family: "Inter", sans-serif;
    font-style: normal;
    color: #344054;
    font-weight: 500;
    font-size: 11.9833px;
    line-height: 17px;
    padding-bottom: 20px;
  }

  .search-container {
    width: 100%;
    height: 43px;
    background: #ffffff;
    border: 0.855949px solid #d0d5dd;
    box-shadow: 0px 0.855949px 1.7119px rgba(16, 24, 40, 0.05);
    border-radius: 6.84759px;
    display: flex;
    align-items: center;
    margin-top: 5px;
    // margin-bottom: 10px;

    .searchMetoInputBody {
      width: 95%;
      display: flex;
      align-items: center;

      .searchMetoInputLogo {
        width: 9%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .searchMetoInput {
        width: 91%;
        height: 40px;
        position: relative;
        padding: 0px 4px;
        border-radius: 8px;
        outline: none;
        border: none;
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 21px;
        color: #667085;
        box-sizing: border-box;
        background-color: transparent;
      }
    }

    .searchAbleIconDecorator {
      width: 10%;
      .searchIconBody {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;

        .searchIcon {
          color: #98a2b3;
          font-size: 18px;
        }
      }
    }
  }

  .dropdownItem {
    background-color: white;
    border: 1px solid hsl(0, 3%, 87%);
    height: 200px;
    z-index: 3;
    position: absolute;
    width: 100%;
    padding: 10px 0px;
    border-radius: 5px;
    overflow-y: scroll;
    font-size: 1.6rem;
    cursor: pointer;
    margin-top: 10px;
    box-shadow: 0px 10.5414px 14.0552px -3.51381px rgba(16, 24, 40, 0.08),
      0px 3.51381px 5.27072px -1.75691px rgba(16, 24, 40, 0.03);

    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      border-radius: 0px;
      /* background-color: #f5f5f5; */
    }

    &::-webkit-scrollbar {
      width: 0px;
      /* background-color: #f5f5f5; */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 0px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: #98a2b3;
    }

    .ItemAlign {
      padding: 10px 11px;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: space-between;
      /* gap: 1rem; */
      border-bottom: 1px solid #f2f4f7;

      &:hover {
        transition: all 0.2s ease-in-out;
        opacity: 0.6;
        transform: scaleY(0.97) scaleX(0.99);
        background-color: #f8f9fa;
      }

      .dropDownIconBody {
        display: flex;
        align-items: center;
        justify-content: center;

        .dropDownIcon {
          color: #7f56d9;
          font-size: 15px;
          width: 25px;
        }
      }

      .textContainer {
        display: flex;
        align-items: center;

        .iconWrapper {
          display: flex;
          align-items: center;
          justify-content: center;
          margin-right: 5px;
        }

        .text {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 14.0552px;
          line-height: 21px;
          // padding: 0px 5px;
        }
      }
    }
  }
}
