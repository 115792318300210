.rightSideConvertComponentCard {
  margin-top: 40px;
  // min-height: 478px;
  height: 478px;
  min-width: 400px;
  border-radius: 16px;
  border: 1px solid #efefef;
  background: rgba(255, 255, 255, 0);
  box-shadow: 0px 24px 64px 0px rgba(0, 0, 0, 0.06);

  .ConvertComponentCardHeader {
    border-radius: 16px 0px 0px 0px;
    background: #f7f7f7;
    padding: 20px 0;

    .ConvertComponentCardHeaderTitleStyle {
      color: #101828;
      font-family: Inter;
      font-size: 23px;
      font-style: normal;
      font-weight: 700;
      line-height: 32.64px; /* 141.913% */
      margin-left: 40px;
    }
  }
  .ConvertComponentCardBody {
    min-height: 420px;
    padding: 20px 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    .ConvertComponentCardItem {
      margin-top: 10px;
      margin-bottom: 10px;
      // position: relative;
      // width: 100%;
    }
    .ConvertComponentCardItemTwo {
      // margin-top: 10px;
      // margin-bottom: 20px;
      // position: relative;
      // width: 100%;
    }
    .ConvertComponentCardItemError {
      margin-bottom: 20px;
      // border: 1px solid red;
      // position: relative;
      // width: 100%;
    }
    .ConvertComponentCardBodyTitle {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #667085;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
      // padding-left: 20px;
      margin-left: 20px;
    }

    .ConvertComponentCardBodyTitleError {
      margin-top: 10px;
      margin-bottom: 10px;
      color: #cf304a;
      font-family: Inter;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 16px; /* 114.286% */
    }

    .ConvertComponrntCardBodyButton {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 20px;

      padding: 16px 0;
      // padding: 12px 196.03px 12px 151px;
      border-radius: 12px;
      border: 1px solid #eaecef;
      background-color: #10d078;

      color: white;
      font-family: Inter;
      font-size: 16px;
      font-style: normal;
      font-weight: 600;
      line-height: 24px; /* 150% */
      margin-bottom: 30px;

      &:disabled {
        color: #bcbdc6;
        background-color: #eaecef;
        cursor: not-allowed;
      }
    }
  }
}
