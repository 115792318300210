.__desktop__wrapper {
  width: 45vw;
  background-color: white;
  border-radius: 10px;
  @media screen and (max-width: 960px) {
    width: 75vw;
  }
  @media screen and (max-width: 760px) {
    width: 80vw;
  }
  @media screen and (max-width: 690px) {
    width: 88vw;
  }
  .__innerWarning__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    .__the__inner {
      display: flex;
      width: 40%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      .__icon {
        margin-bottom: 20px;
        font-size: 66px;
      }
      .__the__p {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;
      }

      .__warning__button {
        outline: none;
        border: none;
        width: 100%;
        padding: 15px 0;
        background-color: #7f56d9;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }
  .__modal__container__top {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 15px 0;
    border-bottom: 1px solid #dbdbdb;
    width: 100%;

    .__top__name {
      display: flex;
      align-items: center;
      width: 50%;
      padding: 0 20px;
      @media screen and (max-width: 600px) {
        width: auto;
        margin-bottom: 10px;
      }

      .__time__name {
        margin-right: 15px;
        .__timeAgo {
          font-size: 12px;
          color: #667085;
        }
        .__the__name {
          font-size: 16px;
          margin-right: 10px;
          color: #000;

          @media screen and (max-width: 600px) {
            font-size: 15px;
            margin-right: 9px;
          }
        }
      }

      .__order__rating {
        .__top__orders {
          font-size: 12px;
          color: #667085;
          margin-bottom: 3px;
          @media screen and (max-width: 600px) {
            font-size: 12px;
            margin-left: 6px;
          }
        }
        .__ratings__like {
          background-color: #ecfdf3;
          display: flex;
          align-items: center;
          padding: 3px 10px;
          border-radius: 5px;
          margin-right: 10px;
          .__rating {
            font-size: 10px;
            color: #027a48;
            margin-right: 4px;
          }
          .__icon {
            color: #027a48;
          }
        }

        .__ratings__dislike {
          background-color: #fef3f2;
          display: flex;
          align-items: center;
          padding: 3px 10px;
          border-radius: 5px;
          .__rating {
            font-size: 10px;
            color: #b42318;
            margin-right: 4px;
          }
          .__icon {
            color: #b42318;
          }
        }
      }

      .__top__imageWrapper {
        position: relative;
        width: 55px;
        height: 55px;
        border-radius: 50%;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: 20px;
        @media screen and (max-width: 600px) {
          width: 48px;
          height: 48px;
        }
        .__top__imageNotification {
          position: absolute;
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 2px solid whitesmoke;
          bottom: 0;
          right: 0;

          @media screen and (max-width: 600px) {
            width: 12px;
            height: 12px;
          }
        }
      }
    }

    .__top__detailWrapper {
      display: flex;
      align-items: flex-end;
      width: 50%;
      padding: 0 20px;
      span {
        font-size: 14px;
        margin-bottom: 5px;
      }
    }
  }

  .__modal__container__bottom {
    display: flex;
    justify-content: space-between;
    padding: 15px 20px 15px 20px;
    width: 100%;
  }

  .__modal__bottom__left {
    width: 50%;
    padding-left: 10px;

    @media screen and (max-width: 700px) {
      padding: 0 10px;
    }
    .__modal__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
      margin-top: 0;
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 5px;
      }

      @media screen and (max-width: 600px) {
        padding: 10px 0px;
        margin-bottom: 0px;
        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .__modal__content__detail {
        display: flex;
        align-items: center;

        .__mTitle {
          font-size: 12px;
          color: #667085;
          margin-right: 10px;

          @media screen and (max-width: 600px) {
            font-size: 13px;
          }
        }
        .__munit__price {
          font-size: 14px;
          color: #00a478;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        .__title__value {
          font-size: 14px;
          color: black;

          @media screen and (max-width: 600px) {
            font-size: 14px;
            display: flex;
          }
        }
        .__value__paymentMethod {
          font-size: 14px;
          color: #6941c6;
          @media screen and (max-width: 600px) {
            font-size: 13px;
          }
        }
      }
    }
    .__tcWrapper {
      margin-bottom: 10px;
      margin-top: 20px;
      @media screen and (max-width: 600px) {
        margin-top: 10px;
      }
      span {
        font-size: 14px;
      }
    }

    .__theTandC {
      width: 90%;
      border: 1px solid rgba(233, 232, 232, 0.25);
      // box-shadow: 0px 3px 3px #e6e6e67e;
      border-radius: 8px;
      height: 90px;
      padding: 10px 10px 10px 1px;
      overflow-y: scroll;
      &::-webkit-scrollbar-track {
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        border-radius: 5px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar {
        width: 3px;
        /* background-color: #f5f5f5; */
      }

      &::-webkit-scrollbar-thumb {
        border-radius: 5px;
        /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
        background-color: #eaecf0;
      }

      .__content {
        width: 100%;
        font-size: 12px;
        line-height: 25px;
        word-wrap: break-word;
        //color: #667085;
      }
    }
  }

  .__modal__bottom__right {
    width: 50%;
    padding: 0 10px 0 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      padding: 0 10px;
    }

    @media screen and (max-width: 600px) {
      padding: 0 10px;
      width: auto;

      padding: 0;
    }

    .verify_indicator {
      display: flex;
      align-items: center;

      .verify_indicator_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        .verify_indicator_icon {
          font-size: 16px;
          color: #f3ba2f;
        }
      }

      .verify_indicator_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #344054;
      }
    }

    .__right__input__wrapper {
      margin-bottom: 10px;
      padding-top: 5px;
      &:last-of-type {
        padding-top: 10px;
        margin-bottom: 10px;
      }
      @media screen and (max-width: 600px) {
        margin-bottom: 15px;
      }

      .__txnInputWrapper {
        border: 1.5px solid rgba(215, 220, 228, 0.705);
        // box-shadow: 0px 1.7px 3.5px rgba(16, 24, 40, 0.05);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 10px 15px;
        border-radius: 5px;
        margin-top: 10px;
        position: relative;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }

        &.__redborder {
          border: 1px solid red;
        }
      }
      .__tokencheck {
        display: flex;
        justify-content: space-between;
        padding: 5px 10px;
        .__warning {
          font-size: 12px;
          color: red;
          font-weight: 400;
        }
        .__info {
          font-size: 11px;
          color: #667085;
          font-weight: 400;
        }
      }

      .__warning__text {
        font-size: 12px;
        color: red;
        font-weight: 400;
      }
      .__txnInputLabel {
        font-size: 14px;
        margin-bottom: 15px;

        @media screen and (max-width: 600px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }

      .__txnInput {
        border: none;
        outline: none;
        padding: 10;
        width: 70%;
        height: 20px;
        font-size: 14px;

        &::placeholder {
          color: #667085;
          font-size: 12px;
          font-weight: 300;
        }
      }

      .__theCryptoWrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .__SystemLater {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: rgba(52, 208, 127, 1);
          padding: 0px 8px;
          border-right: 1px solid rgba(102, 112, 133, 0.7);
          cursor: pointer;
        }

        .__theCurrency {
          font-size: 16px;
          font-weight: 400;
        }
      }
    }

    .__action__buttonWrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin: 15px 0 30px 0;

      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }

      .__buy__button {
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 58%;
        font-size: 15px;
        padding: 12px 0;
        background-color: #53389e;
        border-radius: 5px;
        color: white;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          width: 100%;
          padding: 11px 0;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #7f56d996;
        }
      }
      .__cancel__button {
        outline: none;
        border: none;
        display: flex;
        background-color: white;
        width: 38%;
        font-size: 15px;
        padding: 12px 0;
        border-radius: 5px;
        justify-content: center;
        align-items: center;
        border: 1px solid #d0d5dd;

        @media screen and (max-width: 600px) {
          width: 100%;
          padding: 11px 0;
        }
      }
    }
  }
}
.__mobile__action__modal {
  width: 80vw;
  padding: 20px;
  background-color: white;
  @media screen and (max-width: 750px) {
    width: 94vw;
    padding: 10px;
    margin: 0 auto;
  }

  .__innerWarning__wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 60vh;
    .__the__inner {
      display: flex;
      width: 40%;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: 750px) {
        width: 60%;
      }

      .__icon {
        margin-bottom: 20px;
        font-size: 66px;
      }
      .__the__p {
        font-size: 14px;
        font-weight: 500;
        text-align: center;
        text-transform: capitalize;

        @media screen and (max-width: 750px) {
          font-size: 16px;
        }
      }

      .__warning__button {
        outline: none;
        border: none;
        width: 100%;
        padding: 15px 0;
        background-color: #7f56d9;
        color: white;
        font-weight: bold;
        border-radius: 10px;
        margin-top: 30px;
        cursor: pointer;
      }
    }
  }

  .__mobile_top {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 15px;
    span {
      font-weight: bolder;
      font-size: 18px;
    }
    .__close {
      position: absolute;
      right: 0px;
      top: 0;
    }
  }
  .__top__name {
    display: flex;
    align-items: center;
    width: 50%;
    padding: 0 20px;
    @media screen and (max-width: 600px) {
      width: auto;
      margin-bottom: 10px;
    }

    .__timeAgo {
      font-size: 12px;
      color: #667085;
    }

    .__top__imageWrapper {
      position: relative;
      width: 55px;
      height: 55px;
      border-radius: 50%;
      background-repeat: no-repeat;
      background-size: cover;
      margin-right: 20px;
      @media screen and (max-width: 600px) {
        width: 48px;
        height: 48px;
      }
      .__top__imageNotification {
        position: absolute;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 2px solid whitesmoke;
        bottom: 0;
        right: 0;

        @media screen and (max-width: 600px) {
          width: 12px;
          height: 12px;
        }
      }
    }

    .__the__name {
      font-size: 24px;
      margin-right: 15px;
      @media screen and (max-width: 600px) {
        font-size: 18px;
        margin-right: 9px;
      }

      .__top__order {
        font-size: 14px;
        color: #667085;

        @media screen and (max-width: 600px) {
          font-size: 11px;
          margin-left: 6px;
        }
      }
    }

    .__order__rating {
      .__top__orders {
        font-size: 12px;
        color: #667085;
        margin-bottom: 3px;
        @media screen and (max-width: 600px) {
          font-size: 12px;
          margin-left: 6px;
        }
      }
      .__ratings__like {
        background-color: #ecfdf3;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        border-radius: 5px;
        margin-right: 10px;
        .__rating {
          font-size: 10px;
          color: #027a48;
          margin-right: 4px;
        }
        .__icon {
          color: #027a48;
        }
      }

      .__ratings__dislike {
        background-color: #fef3f2;
        display: flex;
        align-items: center;
        padding: 3px 10px;
        border-radius: 5px;
        .__rating {
          font-size: 10px;
          color: #b42318;
          margin-right: 4px;
        }
        .__icon {
          color: #b42318;
        }
      }
    }
  }

  .__modal__content {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    margin-top: 0;
    &:first-child {
      margin-top: 10px;
    }
    &:last-child {
      margin-bottom: 5px;
    }

    @media screen and (max-width: 600px) {
      padding: 10px 0px;
      margin-bottom: 0px;
      &:first-child {
        margin-top: 0px;
      }
      &:last-child {
        margin-bottom: 0px;
      }
    }
    .__modal__content__detail {
      display: flex;
      align-items: center;

      .__mTitle {
        font-size: 15px;
        color: #667085;
        margin-right: 10px;

        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
      .__munit__price {
        font-size: 15px;
        color: #00a478;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }

      .__title__value {
        font-size: 15px;
        color: black;

        @media screen and (max-width: 600px) {
          font-size: 14px;
          display: flex;
        }
      }
      .__value__paymentMethod {
        font-size: 15px;
        color: #6941c6;
        @media screen and (max-width: 600px) {
          font-size: 14px;
        }
      }
    }
  }

  .__modal__container__top {
    display: flex;
    justify-content: space-between;
    padding: 10px 0 15px 0;
    border-bottom: 1px solid #dbdbdb;
    width: 100%;

    .__top__detailWrapper {
      display: flex;
      align-items: center;
      width: 50%;
      padding: 0 20px;
      .span {
        font-size: 20px;
      }
    }
  }

  .__modal__container__bottom {
    display: flex;
    justify-content: space-between;
    padding: 20px 0 20px 0;
    width: 100%;
  }

  .__modal__bottom__left {
    width: 50%;
    padding: 0 25px;

    @media screen and (max-width: 700px) {
      padding: 0 10px;
    }
    .__modal__content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 30px;
      margin-top: 0;
      &:first-child {
        margin-top: 10px;
      }
      &:last-child {
        margin-bottom: 5px;
      }

      @media screen and (max-width: 600px) {
        padding: 10px 0px;
        margin-bottom: 0px;
        &:first-child {
          margin-top: 0px;
        }
        &:last-child {
          margin-bottom: 0px;
        }
      }
      .__modal__content__detail {
        display: flex;
        align-items: center;

        .__mTitle {
          font-size: 15px;
          color: #667085;
          margin-right: 10px;

          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }
        .__munit__price {
          font-size: 15px;
          color: #00a478;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }

        .__title__value {
          font-size: 15px;
          color: black;

          @media screen and (max-width: 600px) {
            font-size: 14px;
            display: flex;
          }
        }
        .__value__paymentMethod {
          font-size: 15px;
          color: #6941c6;
          @media screen and (max-width: 600px) {
            font-size: 14px;
          }
        }
      }
    }
  }

  .__modal__bottom__right {
    width: 50%;
    padding: 0 25px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 700px) {
      padding: 0 10px;
    }

    @media screen and (max-width: 600px) {
      padding: 0 10px;
      width: auto;

      padding: 0;
    }

    .verify_indicator {
      display: flex;
      align-items: center;

      .verify_indicator_icon_body {
        display: flex;
        align-items: center;
        justify-content: center;
        margin-right: 5px;

        .verify_indicator_icon {
          font-size: 16px;
          color: #f3ba2f;
        }
      }

      .verify_indicator_text {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        color: #344054;
      }
    }

    .__right__input__wrapper {
      padding-top: 10px;
      margin-bottom: 40px;
      @media screen and (max-width: 600px) {
        margin-bottom: 15px;
      }

      .__txnInputWrapper {
        border: 1.5px solid rgba(208, 213, 221, 0.25);
        box-shadow: 0px 1.7px 3.5px rgba(16, 24, 40, 0.05);
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 12px 20px;
        border-radius: 8px;
        margin-top: 20px;
        position: relative;

        @media screen and (max-width: 600px) {
          margin-top: 10px;
        }

        &.__redborder {
          border: 1px solid red;
        }

        .__txnInput {
          border: none;
          outline: none;
          padding: 10;
          width: 70%;
          height: 20px;
          font-size: 15px;

          &::placeholder {
            color: #667085;
          }
        }

        .__theCurrency {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .__tokencheck {
        display: flex;
        justify-content: space-between;
        padding: 0 10px;
        .__warning {
          font-size: 12px;
          color: red;
          font-weight: 400;
        }
        .__info {
          font-size: 12px;
          color: #667085;
          font-weight: 400;
        }
      }
      .__warning__text {
        font-size: 12px;
        color: red;
        font-weight: 400;
      }
      .__txnInputLabel {
        font-size: 16px;
        margin-bottom: 20px;
        @media screen and (max-width: 600px) {
          font-size: 14px;
          margin-bottom: 10px;
        }
      }
      .__txnInput {
        border: none;
        outline: none;
        padding: 10;
        width: 70%;
        height: 20px;
        font-size: 15px;

        &::placeholder {
          color: #667085;
        }
      }

      .__theCryptoWrapper {
        display: flex;
        align-items: center;
        gap: 10px;

        .__SystemLater {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 13px;
          line-height: 24px;
          color: rgba(52, 208, 127, 1);
          padding: 0px 8px;
          border-right: 1px solid rgba(102, 112, 133, 0.7);
          cursor: pointer;
        }

        .__theCurrency {
          font-size: 16px;
          font-weight: 400;
        }
      }

      .__theCurrency {
        font-size: 20px;
      }
    }

    .__action__buttonWrapper {
      display: flex;
      width: 100%;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 15px;

      @media screen and (max-width: 600px) {
        margin-top: 20px;
      }

      .__buy__button {
        outline: none;
        border: none;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 42%;
        font-size: 15px;
        padding: 20px 0;
        background-color: #53389e;
        border-radius: 9px;
        color: white;
        cursor: pointer;
        @media screen and (max-width: 600px) {
          padding: 13px 0;
        }

        &:disabled {
          cursor: not-allowed;
          background-color: #7f56d996;
        }
      }
      .__cancel__button {
        outline: none;
        border: none;
        display: flex;
        background-color: white;
        width: 42%;
        font-size: 15px;
        padding: 20px 0;
        border-radius: 9px;
        justify-content: center;
        align-items: center;
        border: 1px solid #d0d5dd;
        @media screen and (max-width: 600px) {
          padding: 13px 0;
        }
      }
    }
  }

  .__tcWrapper {
    margin-bottom: 10px;
    @media screen and (max-width: 600px) {
      margin-top: 10px;
    }
    span {
      font-size: 14px;
    }
  }

  .__theTandC {
    width: 100%;
    border: 1.78px solid rgba(219, 219, 219, 0.25);
    box-shadow: 0px 4px 4px #dbdbdb;
    border-radius: 8px;
    height: 150px;
    padding: 10px;
    // overflow-y: scroll;

    &::-webkit-scrollbar-track {
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      border-radius: 3px;
      /* background-color: #f5f5f5; */
    }

    &::-webkit-scrollbar {
      width: 2px;
      /* background-color: #f5f5f5; */
    }

    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
      background-color: #eaecf0;
    }

    .__content {
      width: 100%;
      height: 95%;
      font-size: 12px;
      line-height: 25px;
      word-wrap: break-word;
      // overflow: hidden;
    }
  }
}
