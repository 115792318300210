.marketplaceWrapperModal {
  width: 55vw;
  background-color: white;
  border-radius: 10px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  @media screen and (max-width: 1300px) {
    width: 65vw;
  }

  @media screen and (max-width: 960px) {
    width: 80vw;
  }

  @media screen and (max-width: 820px) {
    width: 85vw;
  }

  @media screen and (max-width: 760px) {
    width: 90vw;
  }
  @media screen and (max-width: 690px) {
    width: 95vw;
  }

  .marketplace_modalhalf_one {
    width: 45%;
    height: 100%;
    background-color: #7f56d9;
    padding: 20px 18px;
    display: flex;
    flex-direction: column;
    gap: 21px;

    .marketplace_modalhalf_one_container {
      display: flex;
      flex-direction: column;
      gap: 6px;

      .__modal__container__top {
        display: flex;
        align-items: center;

        .__modal__container__top__name {
          display: flex;
          align-items: center;

          @media screen and (max-width: 600px) {
            width: auto;
          }

          .__modal__container_items__end_text {
            .__modal__container_items__end_text__time__name {
              display: flex;
              flex-direction: column;
              gap: 2px;

              .__the__name_container {
                display: flex;
                align-items: center;
                gap: 6px;

                .__the__name {
                  color: white;
                  font-size: 20px;
                  font-family: "inter";
                  font-weight: 600;
                  line-height: 20px;
                }

                .__modal__container_items__end_text_icon_body {
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  background-color: #00a478;
                  width: 12px;
                  height: 12px;
                  border-radius: 50%;

                  .__modal__container_items__end_text_icon {
                    color: #fff;
                  }
                }
              }

              .__timeAgo {
                color: #aaaaaa;
                font-size: 14px;
                font-family: "inter";
                font-weight: 400;
                line-height: 20px;
              }
            }
          }

          .__top__imageWrapper {
            position: relative;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            background-repeat: no-repeat;
            background-size: cover;
            margin-right: 12px;
            @media screen and (max-width: 600px) {
              width: 48px;
              height: 48px;
            }
            .__top__imageNotification {
              position: absolute;
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 2px solid whitesmoke;
              bottom: 0;
              right: 0;

              @media screen and (max-width: 600px) {
                width: 12px;
                height: 12px;
              }
            }
          }
        }
      }

      .__modal__container__top_bottom {
        display: flex;
        flex-direction: column;
        gap: 8px;

        .__modal__container__top_bottom_tap {
          display: flex;
          align-items: center;
          justify-content: space-between;

          .__modal__container__top_bottom_tap_discription {
            color: white;
            font-size: 14px;
            font-family: "inter";
            font-weight: 400;
            line-height: 28px;
          }

          .__modal__container__top_bottom_tap_content {
            color: white;
            font-size: 16px;
            font-family: "inter";
            font-weight: 500;
            line-height: 28px;
          }
        }
      }
    }

    .marketplace_modalhalf_one_down_container {
      border-top: 1px solid rgba(214, 214, 214, 0.6);
      padding: 15px 0px;
      display: flex;
      flex-direction: column;
      gap: 8px;

      .marketplace_modalhalf_one_down_container_wrapper {
        .marketplace_modalhalf_one_down_container_wrapper_text {
          color: white;
          font-size: 14px;
          font-family: Inter;
          font-weight: 500;
          line-height: 20px;
        }
      }

      .marketplace_modalhalf_one_down_container_wrapper_conditions {
        width: 100%;
        height: 138px;
        padding: 15px 24px;

        &.seasonWrapper {
          border: 0.7px solid rgba(234, 236, 240, 0.3);
          border-radius: 8px;

          &::-webkit-scrollbar-track {
            /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
            border-radius: 0px;
            /* background-color: #f5f5f5; */
          }

          &::-webkit-scrollbar {
            width: 0px;
            /* background-color: #f5f5f5; */
          }

          &::-webkit-scrollbar-thumb {
            border-radius: 0px;
            /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
            background-color: #98a2b3;
          }
        }

        .marketplace_modalhalf_one_down_container_wrapper_conditions_content {
          color: white;
          font-size: 14px;
          font-family: "inter";
          font-weight: 400;
          line-height: 19px;
          word-wrap: break-word;
          overflow-wrap: break-word;
          hyphens: auto;

          .marketplace_modalhalf_one_down_container_wrapper_conditions_content_span {
            display: inline;
            white-space: wrap;
            word-wrap: break-word;
            overflow-wrap: break-word;
            hyphens: auto;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 2; /* Number of lines to display */
            -webkit-box-orient: vertical;
          }

          .marketplace_modalhalf_one_down_container_wrapper_conditions_content_action {
            color: #34d07f;
            font-size: 14px;
            font-family: "inter";
            font-weight: 400;
            line-height: 19px;
            cursor: pointer;
          }
        }
      }
    }
  }

  .marketplace_modalhalf_two {
    width: 55%;
    height: 100%;
    padding: 20px 18px;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    gap: 12px;
    // overflow: hidden;

    .marketplace_modalhalf_two_close {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      width: 100%;

      .marketplace_modalhalf_two_close_body_icon {
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        .marketplace_modalhalf_two_close_icon {
          font-size: 24px;
          color: rgba(52, 64, 84, 1);
        }
      }
    }

    .rightSideConvertComponentCardss {
      width: 100%;
      border-radius: 16px;
      border: 1px solid #efefef;
      background: rgba(255, 255, 255, 0);

      .ConvertComponentCardHeader {
        border-radius: 16px 16px 0px 0px;
        background: #f7f7f7;
        padding: 15px 24px;

        .ConvertComponentCardHeaderTitleStyle {
          color: #667085;
          font-family: "inter";
          font-size: 20px;
          font-style: normal;
          font-weight: 600;
          line-height: 39.84px; /* 141.913% */
        }
      }

      .ConvertComponentCardHeaderUnit {
        padding: 15px 24px 0px 24px;

        .ConvertComponentCardHeaderUnitPrice {
          color: #344054;
          font-size: 14px;
          font-family: "inter";
          font-weight: 400;
          line-height: 28px;

          .ConvertComponentCardHeaderUnitPriceDetails {
            color: #344054;
            font-size: 16px;
            font-family: "inter";
            font-weight: 700;
            line-height: 28px;
          }
        }
      }

      .ConvertComponentCardBody {
        padding: 20px 24px;
        display: flex;
        flex-direction: column;
        justify-content: space-around;
        .ConvertComponentCardItem {
          margin-top: 10px;
          margin-bottom: 10px;
          // position: relative;
          // width: 100%;
        }
        .ConvertComponentCardItemTwo {
          // margin-top: 10px;
          // margin-bottom: 20px;
          // position: relative;
          // width: 100%;
        }
        .ConvertComponentCardItemError {
          margin-bottom: 20px;
          // border: 1px solid red;
          // position: relative;
          // width: 100%;
        }
        .ConvertComponentCardBodyTitle {
          margin-top: 10px;
          margin-bottom: 10px;
          color: #667085;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
          // padding-left: 20px;
          margin-left: 20px;
        }

        .ConvertComponentCardBodyTitleError {
          margin-top: 10px;
          margin-bottom: 10px;
          color: #cf304a;
          font-family: Inter;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          line-height: 16px; /* 114.286% */
        }

        .ConvertComponrntCardBodyButton {
          display: flex;
          justify-content: center;
          align-items: center;
          margin-top: 20px;

          padding: 16px 0;
          // padding: 12px 196.03px 12px 151px;
          border-radius: 12px;
          border: 1px solid #eaecef;
          background-color: #10d078;

          color: white;
          font-family: Inter;
          font-size: 16px;
          font-style: normal;
          font-weight: 600;
          line-height: 24px; /* 150% */
          margin-bottom: 30px;

          &:disabled {
            color: #bcbdc6;
            background-color: #eaecef;
            cursor: not-allowed;
          }
        }
      }
    }
  }
}
