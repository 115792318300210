.walletWrapper {
  width: 100%;
  margin: 20px 0px;

  .walletWrapper__header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .wallet__firstLayoutWrapperDetailsHeader {
      h3 {
        font-family: "Inter";
        font-style: normal;
        font-weight: 600;
        font-size: 32.3492px;
        line-height: 40px;
        letter-spacing: -0.02em;
        color: #101828;

        @media screen and (max-width: 720px) {
          font-size: 24px;
        }
      }
    }

    .walletWrapper__headerWallet {
      width: 30%;
    }
  }

  .walletWrapper__bodies {
    display: flex;
    justify-content: space-between;
    margin-top: 40px;
    // border: 1px solid red;

    .walletWrapper__bodiesFirstSection {
      width: 100%;

      .walletWrapper__bodiesFirstSection__Section {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .walletWrapper__bodiesFirstSection__SectionTop {
          // width: 30%;
        }

        .walletWrapper__bodiesFirstSection__SectionBottom {
          width: 55%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .walletWrapper__bodiesFirstSection__SectionBottomChart {
            margin-top: 20px;
          }

          @media screen and (max-width: 800px) {
            margin-top: 25px;
            width: 100%;
          }

          @media screen and (max-width: 500px) {
            flex-direction: column;
            align-items: flex-start;
          }
        }

        @media screen and (max-width: 800px) {
          flex-direction: column;
          align-items: flex-start;
        }
      }

      .wallet__secondLayoutWrapper {
        width: 100%;
        margin: 100px 0px;
      }
    }

    .wallet__extension {
      width: 28%;
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
    }
  }
}

@media screen and (max-width: 970px) {
  .walletWrapper {
    .walletWrapper__bodies {
      flex-direction: column;

      .walletWrapper__bodiesFirstSection {
        width: 100%;
      }

      .wallet__extension {
        margin: 20px 0px 40px 0px;
        width: 100%;
        justify-content: space-between;
        flex-direction: unset;
        padding: 0px 10px;
      }
    }
  }
}

@media screen and (max-width: 770px) {
  .walletWrapper {
    .walletWrapper__header {
      align-items: flex-start;
      flex-direction: column;

      .walletWrapper__headerWallet {
        width: 80%;
        margin: 36px 0px 32px 0px;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .walletWrapper {
    margin: 0px 10px;

    .walletWrapper__header {
      .walletWrapper__headerWallet {
        width: 100%;
      }
    }

    .wallet__firstLayoutWrapperDetailsHeader {
      display: block;
    }

    .walletWrapper__bodies {
      .wallet__extension {
        width: 100%;
      }
    }
  }
}
