.verifiedWrapper {
  text-align: center;
  width: 450px;
  background-color: #fff;
  border-radius: 8px;
  padding: 20px;

  .verification__firstLayout {
    padding: 10px 0px;
    margin: 2px 0px;

    .verification__firstLayoutIconBody {
      display: flex;
      align-items: center;
      justify-content: center;

      .verification__firstLayoutIcon {
        font-size: 50px;
        color: #fbcb5c;
      }
    }
  }

  .verification__secondLayout {
    padding: 5px 0px;

    h3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 28px;
      color: #101828;
      padding: 10px 0px;
    }

    p {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 18px;
      color: #667085;
    }
  }

  .verification__thirdLayout {
    padding: 10px 0px;
    margin-top: 20px;

    button {
      background: #53389e;
      padding: 0px 18px;
      border: 1px solid #53389e;
      box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
      border-radius: 6px;
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 0px;
      color: #ffffff;
      cursor: pointer;
      width: 100%;
      height: 40px;

      &:hover {
        background: #6343bb;
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .verifiedWrapper {
    width: 90%;
  }
}
