.accordion-container {
  display: flex;
  gap: 1rem;
  flex: 1;
  justify-content: space-between;
  position: relative;
  .iconContainer {
    display: flex;
    align-items: center;
  }
  .timeContainer {
    display: flex;
    align-items: center;
  }
}

@media (max-width: 600px) {
  .accordion-container {
    flex-direction: column;

    .timeContainer {
      width: 100%;
      justify-content: space-between;

      .iconPositionContainer {
        position: absolute;
        top: 1%;
        right: 1%;
      }
    }
  }
}

.menu-style {
  width: 350px;
  border-bottom: 1px solid #f2f4f7;
  margin-top: 10px;
}

.image-style {
  width: 17px;
  height: 17px;
  margin-right: 10px;
  margin-bottom: 20px;
}
// .__balance__crypto__dropdown {
//   .top-bar-style {
//     background-color: #f5f5f5;
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     height: 50px;
//     padding: 0px 10px;
//     position: absolute;
//     top: 0%;
//     width: 100%;
//     border-top-left-radius: 7px;
//     border-top-right-radius: 7px;

//     .__viewall {
//       display: flex;
//       align-items: center;
//       font-size: 12px;
//       cursor: pointer;
//     }

//     .__noty__holder {
//       position: absolute;
//       top: 48px;
//       overflow-y: scroll;
//       height: 400px;
//       width: 100%;
//       background-color: white;
//       border-bottom-left-radius: 5px;
//       border-bottom-right-radius: 5px;
//       &::-webkit-scrollbar {
//         width: 0px;
//       }
//       &::-webkit-scrollbar-track {
//         border-radius: 0px;
//       }
//       &::-webkit-scrollbar-thumb {
//         border-radius: 0px;
//         /* -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
//         background-color: #98a2b3;
//       }

//       .__the__buycrypto__link__noty {
//         display: flex;
//         gap: 1rem;
//         cursor: pointer;
//         align-items: center;
//         border-bottom: 1px solid #f2f4f7;
//         padding: 1rem 0;

//         &:hover {
//           transition: all 0.2s ease-in-out;
//           opacity: 0.6;
//           transform: scale(0.97);
//           background-color: #f8f9fa;
//         }

//         &:last-child {
//           border-bottom: none;
//         }

//         .__dd__title {
//           font-style: normal;
//           font-weight: 400;
//           font-size: 1.4rem;
//           line-height: 20px;
//           color: #344054;
//           text-decoration: none;
//           transition: all 0.2s ease-in-out;
//         }

//         .__dd__description {
//           font-style: normal;
//           font-weight: 400;
//           font-size: 1.1rem;
//           line-height: 20px;
//           color: #667085;
//           text-decoration: none;
//           transition: all 0.2s ease-in-out;
//         }
//       }
//     }
//   }
// }
