.overView__extensionSecondLayout {
  width: 100%;
  margin: 40px 0px;
  position: relative;

  .overView__extensionSecondLayoutAds {
    width: 100%;
    border-radius: 10px;
    animation: animateleft 2s;
    -webkit-animation: animateleft 2s;
    // animation: spin 2s linear infinite;
    // display: flex;
    // align-items: center;
    // justify-content: center;

    img {
      width: 100%;
      object-fit: cover;
      border-radius: 10px;
    }
  }

  @-webkit-keyframes animateleft {
    from {
      left: -300px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  @keyframes animateleft {
    from {
      left: -300px;
      opacity: 0;
    }
    to {
      left: 0;
      opacity: 1;
    }
  }

  .overView__extensionSecondLayoutAdsIcons {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 15px;

    .overView__extensionSecondLayoutAdsIconsBox {
      background: #d9d9d9;
      border-radius: 50px;
      cursor: pointer;
      width: 8px;
      height: 8px;
      margin: 0px 10px;
    }

    .overView__extensionSecondLayoutAdsIconsBoxSuccess {
      width: 16px;
      height: 8px;
      background: #422078;
      border-radius: 19px;
      cursor: pointer;
      margin: 0px 10px;
    }

    // .overView__extensionSecondLayoutAdsIconBody {
    //   display: flex;
    //   align-items: center;
    //   justify-content: center;
    //   display: none;
    //   cursor: pointer;

    //   .overView__extensionSecondLayoutAdsIcon {
    //     font-size: 26px;
    //     color: #413e3e73;
    //     border: 2px solid transparent;
    //     outline: 2px solid #413e3e73;
    //     border-radius: 50%;
    //     opacity: 0.7;
    //   }
    // }
  }

  &:hover {
    .overView__extensionSecondLayoutAdsIcons {
      .overView__extensionSecondLayoutAdsIconBody {
        display: block;
      }
    }
  }
}

@media screen and (max-width: 810px) {
  .overView__extensionSecondLayout {
    width: 100%;
    margin: 0px 0px;
  }
}
