.careerWrapper {
  width: 100%;

  .HeroSectionWrapper {
    width: 100%;
    padding: 96px 0px;
    background: #edf0ff;

    .HeroSection {
      width: 80%;
      margin: 0 auto;

      .HeroSectionContent {
        width: 50%;

        .HeroSectionHeader {
          .HeroSectionHeaderH3 {
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 5.0625rem;
            line-height: 5.8rem;
            color: #1d1e2c;

            @media screen and (max-width: 500px) {
              font-size: 3rem;
              line-height: 4.2rem;
            }
          }
        }

        .HeroSectionBody {
          padding: 28px 0px 39px 0px;
          .HeroSectionBodyP {
            font-family: "Inter";
            font-style: normal;
            font-weight: 500;
            font-size: 1.6rem;
            line-height: 2.4rem;
            color: #575a65;

            @media screen and (max-width: 500px) {
              font-size: 1.4rem;
            }
          }
        }

        .HeroSectionButton {
          .HeroSectionButtonButton {
            background: #7f56d9;
            border: 1px solid #7f56d9;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 8px;
            font-family: "Inter";
            font-style: normal;
            font-weight: 600;
            font-size: 1.6rem;
            line-height: 2.8rem;
            color: #ffffff;
            padding: 13px 58px;
            cursor: pointer;

            &:hover {
              background-color: hsla(259, 63%, 59%, 0.79);
            }

            @media screen and (max-width: 500px) {
              padding: 10px 40px;
            }
          }
        }

        @media screen and (max-width: 1224px) {
          width: 70%;
        }

        @media screen and (max-width: 800px) {
          width: 90%;
        }

        @media screen and (max-width: 500px) {
          width: 95%;
        }
      }

      @media screen and (max-width: 1002px) {
        width: 95%;
      }
    }
  }

  .valueWrapper {
    padding: 30px 0px;
    width: 80%;
    margin: auto;

    @media screen and (max-width: 1000px) {
      width: 95%;
    }
    @media screen and (max-width: 500px) {
      width: 95%;
      padding: 10px 0 30px;
    }
    .careerTitleStyleNew {
      font-family: "Inter";
      font-style: normal;
      font-weight: 600;
      font-size: 32px;
      line-height: 38px;
      display: flex;
      align-items: center;
      text-align: center;
      color: #000000;
      @media screen and (max-width: 500px) {
        font-size: 30px;
        line-height: normal;
        margin: 2rem 0 0;
      }
    }

    .careerTagStyleNew {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #34495e;
      width: 60%;
      margin-top: 4rem;
      // margin: auto;
      @media screen and (max-width: 500px) {
        width: 100%;
        margin-top: 1rem;
      }
    }
    .careerTagStyleNew2 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #34495e;
      width: 60%;
      margin-top: 2rem;
      @media screen and (max-width: 500px) {
        width: 100%;
      }
    }
    .careerTagStyleNew3 {
      font-family: "Inter";
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      display: flex;
      align-items: center;
      color: #34495e;
      width: 60%;
      margin-top: 2rem;
      @media screen and (max-width: 500px) {
        width: 100%;
        margin-bottom: 3rem;
      }
    }
  }

  .JoinTeamWrapper {
    width: 100%;
    background: rgba(147, 129, 255, 0.05);
    padding: 107px 0px;

    .JoinTeamWrapperContainer {
      width: 80%;
      margin: 0px auto;

      .JoinTeamWrapperContainerHeader {
        width: 100%;
        // text-align: center;
        .JoinTeamWrapperContainerHeaderContent {
          // font-weight: 600;
          color: #1d1e2c;
          font-family: "Inter";
          font-style: normal;
          font-weight: 700;
          font-size: 3.2rem;
          line-height: 5.2rem;

          @media screen and (max-width: 500px) {
            font-size: 2.8rem;
          }
        }
      }

      .JoinTeamWrapperContainerSearchWrap {
        // text-align: center;
        width: 60%;
        // margin: 0px auto;
        margin-top: 50px;
        margin-bottom: 62px;

        .JoinTeamWrapperContainerSearch {
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          .JoinTeamWrapperContainerSearchInput {
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            background: #ffffff;
            border: 0.721608px solid #d0d5dd;
            box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
            border-radius: 5.77286px;
            padding: 0px 10.1025px;

            .JoinTeamWrapperContainerSearchInputIconBody {
              display: flex;
              align-items: center;
              justify-content: center;
              width: 5%;

              .JoinTeamWrapperContainerSearchInputIcon {
                font-size: 25px;
                color: #667085;
              }
            }

            .JoinTeamWrapperContainerSearchInputField {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 16.5457px;
              line-height: 17px;
              color: #667085;
              width: 93%;
              padding: 10.21608px 10.1025px;
              background: transparent;
              border: none;
              outline: none;
            }
          }

          .JoinTeamWrapperContainerSearchButton {
            width: 20%;

            .JoinTeamWrapperContainerSearchButtonItem {
              background: #7f56d9;
              border: 0.721608px solid #d0d5dd;
              box-shadow: 0px 0.721608px 1.44322px rgba(16, 24, 40, 0.05);
              border-radius: 5.77286px;
              font-family: "Inter";
              font-style: normal;
              font-weight: 600;
              font-size: 14.5457px;
              line-height: 17px;
              color: #ffffff;
              width: 100%;
              padding: 12.21608px 0px;
            }
          }
        }

        @media screen and (max-width: 1002px) {
          width: 60%;
        }

        @media screen and (max-width: 600px) {
          width: 90%;
        }

        @media screen and (max-width: 400px) {
          width: 95%;
        }
      }

      .JoinTeamWrapperContainerArray {
        width: 100%;

        .JoinTeamWrapperContainerArrayWrapper {
          width: 100%;
          // margin: 0px auto;

          .JoinTeamWrapperContainerArrayWrapperNotfound {
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            width: 100%;
            height: 20vh;
            // margin-top: 30px;

            .JoinTeamWrapperContainerArrayWrapperNotfoundText {
              color: #101828;
              font-family: Inter, sans-serif;
              font-size: 30px;
              font-weight: 600;
              letter-spacing: -0.02em;
              line-height: 20px;
              margin-bottom: 20px;
              margin-top: 5px;
            }

            .JoinTeamWrapperContainerArrayWrapperNotfoundTextContent {
              font-family: "Inter";
              font-style: normal;
              font-weight: 400;
              font-size: 1.6rem;
              line-height: 24px;
              color: #667085;
            }
          }

          .JoinTeamWrapperContainerArrayWrapperContainer {
            background: #ffffff;
            box-shadow: 0px 1px 3px rgba(0, 0, 0, 0.1), 0px 1px 2px rgba(0, 0, 0, 0.06);
            border-radius: 15px;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 35px 30px;
            margin: 15px 0px;

            .JoinTeamWrapperContainerArrayWrapperContainerDetails {
              .JoinTeamWrapperContainerArrayWrapperContainerDetailsHeader {
                .JoinTeamWrapperContainerArrayWrapperContainerDetailsHeaderItem {
                  font-weight: 600;
                  font-size: 15.875px;
                  line-height: 26px;
                  color: #374151;
                  font-family: "Inter";
                  font-style: normal;
                }
              }

              .JoinTeamWrapperContainerArrayWrapperContainerDetailsBody {
                .JoinTeamWrapperContainerArrayWrapperContainerDetailsBodyContainer {
                  font-family: "Inter";
                  font-style: normal;
                  font-weight: 400;
                  font-size: 11px;
                  line-height: 18px;
                  color: #6b7280;
                }
              }
            }

            .JoinTeamWrapperContainerArrayWrapperContainerIconWrapper {
              .JoinTeamWrapperContainerArrayWrapperContainerIconWrapperBody {
                display: flex;
                align-items: center;
                justify-content: center;

                .JoinTeamWrapperContainerArrayWrapperContainerIconWrapperDetails {
                  font-size: 24px;
                  color: #6b7280;
                }
              }
            }
          }

          @media screen and (max-width: 600px) {
            width: 90%;
          }

          @media screen and (max-width: 600px) {
            width: 95%;
          }
        }
      }

      @media screen and (max-width: 1002px) {
        width: 95%;
      }
    }

    @media screen and (max-width: 500px) {
      padding: 40px 0px;
    }
  }
}
