.faqVideosWrapper {
  width: 100%;
  margin: 0px auto;
  text-align: left;

  .faqVideosWrapperFrame {
    width: 100%;
    border-radius: 8px;
    position: relative;

    .faqVideosWrapperFrameItem {
      width: 100%;
      height: 220px;
      border-radius: 6px;
    }

    .faqVideosWrapperFrameBorder {
      position: absolute;
      top: 0;
      width: 100%;
      height: 150px;
      z-index: 5;
    }
  }

  .faqVideosWrapperBody {
    width: 100%;

    .faqVideosWrapperBodyDetails {
      width: 100%;
      margin: 24px 0px 0px 0px;
      .faqVideosWrapperBodyDetailsHeader {
        font-family: "Inter";
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: #101828;
      }

      .faqVideosWrapperBodyDetailsBody {
        font-family: "Inter";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #667085;
      }
    }

    .faqVideosWrapperBodyVideos {
      width: 100%;
      display: flex;
      align-items: center;
      margin: 12px 0px 0px 0px;
      cursor: pointer;

      .faqVideosWrapperBodyVideosIconBody {
        display: flex;
        justify-content: center;
        justify-content: center;
        margin-right: 5px;

        .faqVideosWrapperBodyVideosIcon {
          font-size: 14px;
          color: #6941c6;
        }
      }

      .faqVideosWrapperBodyVideosDetials {
        .faqVideosWrapperBodyVideosDetialsContent {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: #6941c6;
        }
      }
    }
  }

  @media screen and (max-width: 1024px) {
    width: 100%;
  }
}
