.postradeButtonWrapper {
  padding: 5px 0px;
  margin: 5px 0px;

  // .button {
  //   cursor: pointer;
  //   border: none;
  //   outline: none;
  //   background-color: #53389e;
  //   color: #fff;
  //   height: 35px;
  //   padding: 0px 30px;
  //   border-radius: 10px;
  //   font-weight: 700;
  //   box-shadow: 1px 2px 5px 3px rgba(0, 0, 0, 0.126);

  //   &:hover {
  //     background-color: hsla(259, 63%, 59%, 0.8);
  //     border: 1px solid hsla(259, 63%, 59%, 0.8);
  //   }
  // }
}
