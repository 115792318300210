.convertConfirm {
  margin-top: 0px;
  min-height: 478px;
  border-radius: 16px;
  border: 1px solid #efefef;
  border-radius: 20px;
  background: #fbfbfc;
  position: relative;
  .BackButtonConfirmContainer {
    width: 5%;
    cursor: pointer;
    display: flex;
    align-items: center;
    position: absolute;
    top: 10px;
    .BackButtonConfirmContainerIcon {
      width: 24px;
      height: 24px;
    }
    .BackButtonConfirmContainerTitle {
      color: #344054;
      text-align: center;
      font-family: Inter;
      font-size: 18px;
      font-style: normal;
      font-weight: 500;
      line-height: 32px;
    }
  }
  padding: 40px;
  .convertConfirm_firm {
    .convertConfirm_firm_title {
      color: #344054;
      font-size: 18px;
      font-family: Inter;
      font-weight: 500;
      line-height: 32px;
      text-align: center;
    }
    margin-bottom: 30px;
  }

  .convertConfirm_amount {
    .convertConfirm_amount_text {
      color: #272729;
      font-size: 26px;
      font-family: Inter;
      font-weight: 600;
      letter-spacing: 0.2px;
      text-align: center;
    }
    margin-bottom: 40px;
  }

  .convertConfirm_details {
    display: flex;
    flex-direction: column;
    gap: 18px;

    .convertConfirm_details_content {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;

      padding: 6px 0;
      .convertConfirm_details_content_one {
        color: #667085;
        font-size: 16px;
        font-family: Inter;
        font-weight: 400;
        line-height: 20px;
      }

      .convertConfirm_details_content_two {
        color: #667085;
        font-size: 16px;
        font-family: Inter;
        font-weight: 400;
        line-height: 20px;
      }
    }
  }

  .convertConfirm_button {
    margin-top: 60px;
    .convertConfirm_content {
      width: 100%;
      border: none;
      outline: none;
      color: #fff;
      font-size: 16px;
      font-family: Inter;
      font-weight: 600;
      line-height: 24px;
      padding: 12px 0px;
      background-color: #10d078;
      border-radius: 4px;

      &:disabled {
        color: #bcbdc6;
        background-color: #eaecef;
      }
    }
  }

  @media screen and (max-width: 1350px) {
    width: 80%;
  }

  @media screen and (max-width: 870px) {
    width: 100%;
  }
}
