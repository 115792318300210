.WalletModalWrapper {
  z-index: 3;
  padding-top: 100px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
  background-color: rgba(231, 233, 236, 0.06);
  backdrop-filter: blur(2px);
}
