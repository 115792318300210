.oopWrapper {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;

  .oopWrapper__ImageSection {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .oopWrapper__TextSection {
    margin: 35px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .oopWrapper__ButtonSection {
    // margin: 24px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
