.__createTradeotp-container {
  width: 100%;
  height: auto;

  .login-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 20px;
    @media (max-width: 700px) {
      padding: 8px;
    }
    .form-contents {
      width: auto;
      height: auto;

      display: flex;
      flex-direction: column;
      align-items: center;
      background-color: #fff;
      padding: 30px;
      border-radius: 8px;
      @media screen and (max-width: 700px) {
        padding: 30px 15px;
      }
      &.__padding {
        padding: 20px;
      }

      .__warninig {
        width: 70px;
      }
      .form-contentsHeader {
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        &.__center {
          align-items: center;
        }
        .title {
          font-family: "Inter";
          font-style: normal;
          font-weight: 600;
          font-size: 2.2rem;
          line-height: 14px;
          color: #101828;
          padding: 10px 0px;
          margin-bottom: 20px;
        }

        .tag {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.6rem;
          line-height: 14px;
          color: #101828;
          padding: 10px 0px;
        }

        .tagTell {
          font-family: "Inter";
          font-style: normal;
          font-weight: 500;
          font-size: 1.2rem;
          line-height: 9px;
        }
      }

      .form-input {
        width: 100%;
        margin-top: 30px;

        .label {
          font-size: 1.4rem;
          font-weight: 500;
          color: #344054;
        }
      }

      .form-contentsResendCode {
        width: 100%;
        display: flex;
        align-items: center;

        margin-top: 10px;
        &.__textcenter {
          justify-content: center;
        }

        .form-contentsResendCodeContent {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.1rem;
          line-height: 6px;
          color: #667085;
          margin-right: 5px;
        }

        .form-contentsResendCodeLink {
          font-family: "Inter";
          font-style: normal;
          font-weight: 400;
          font-size: 1.1rem;
          line-height: 6px;
          color: #667085;
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .form-contentsButtonBody {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .theInputs {
        input {
          width: 50px !important;
          height: 50px;

          font-size: 4.5rem;
          color: #5d3cb2;
          border-radius: 8px;
          outline: none;
          border: 1px solid #d0d5dd;
          text-align: center;
          padding: 1rem 1rem;

          &::placeholder {
            color: #d0d5dd;
          }
        }
        @media screen and (max-width: 700px) {
          input {
            width: 43px !important;
            height: 43px;
            font-size: 4rem;
          }
        }
      }
    }
  }
}

@media (max-width: 1000px) {
  .__login-container {
    .login-content {
      margin-top: 40px;
      align-items: flex-start;

      .form-contents {
        .title {
          font-size: 2rem;
        }

        .tag {
          font-size: 1.8rem;
          font-weight: 400;
          color: "#667085";
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .__login-container {
    .login-content {
      margin-top: 0px;
      align-items: flex-start;
      align-items: center;

      .form-contents {
        margin-top: 0;
        .title {
          font-size: 1.8rem;
        }
      }
    }
  }
}

@media (max-width: 400px) {
  .__login-container {
    .login-content {
      .form-contents {
        width: 94%;
      }
    }
  }
}
