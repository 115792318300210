.icon-button {
  display: flex;
  align-items: center;
  gap: 10px;
  background-color: #fff;
  border: 1px solid #f8f9fa;
  border-radius: 20px;
  text-align: center;
  font-style: normal;
  font-weight: 600;
  font-size: 1.23rem;
  line-height: 20px;
  display: flex;
  align-items: center;
  letter-spacing: -0.3px;
  color: #101828;
  width: 110px;
  height: 40px;
  justify-content: center;

  @media (max-width: 1280px) {
    gap: 10px;
    width: 90px;
    font-size: 1.1rem;
  }
}
