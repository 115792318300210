@import "colors";

.homepageContainerOyola {
  display: flex;

  margin-top: 8rem;
  .innerContainer {
    width: 80%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: auto;
    // padding: 8rem 0;

    @media (max-width: 500px) {
      width: 95%;
    }

    .oyolaWalletStyles {
      padding: 8rem 0;
      width: 100%;
      background-color: #fafbff;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      // align-items: flex-end;

      @media (max-width: 500px) {
        padding: 0rem 0;
      }

      .containerLeft {
        max-width: 100%;
        margin: 2rem auto 0rem auto;

        @media (max-width: 500px) {
          margin: 1.5rem auto 0rem 0rem;
          width: 100%;
        }
        .imageContainer {
          width: 245.54px;
          height: 80%;
        }
      }

      .containerRight {
        width: 491px;
        margin: 2rem auto 0rem auto;

        // @media (max-width: 1169px) {
        //   margin: 2rem auto 0rem;
        // }

        .title {
          font-weight: 700;
          font-size: 4rem;
          line-height: 49px;
          text-align: left;
          letter-spacing: -0.08rem;
          color: #101828;
          margin: 0 auto 3rem;
          @media (max-width: 500px) {
            font-size: 2.6rem;
            width: 100%;
            margin-bottom: 1rem;
          }
        }

        .tag {
          font-weight: 400;
          font-size: 1.6rem;
          line-height: 35px;
          text-align: left;
          color: #667085;
          width: 100%;
          margin: auto;

          @media (max-width: 500px) {
            width: 100%;
            font-size: 1.6rem;
            line-height: 25px;
          }
        }

        .signUpContainer {
          margin: 2rem auto 4rem;
          text-align: left;

          @media (max-width: 800px) {
            margin: 2rem auto 4rem;
          }

          .buttonAdditionalStyle {
            width: 133px;
            border: none;
            border-radius: 8px;
            padding: 10px;
            height: 51px;
          }
        }
      }
    }
  }
}
